<template>
  <div class="d-flex-inline">
    <v-hover v-slot:default="{ hover }">
      <v-card :loading="loading" class="elevation-0">
        <v-img
          :src="imgSrc || getImgFromAssets(defaultImg)"
          :height="imgHeight"
          contain
          class="grey lighten-4"
        >
          <template v-if="$attrs.value && hover && attachment">
            <v-btn-toggle dense>
              <v-btn text title="Descargar" @click="download()">
                <v-icon>mdi-download</v-icon>
              </v-btn>
            </v-btn-toggle>
          </template>
        </v-img>
        <v-file-input
          v-if="attachment"
          v-bind="$attrs"
          v-on="$listeners"
          accept="image/png, image/jpeg, image/bmp"
          prepend-inner-icon="mdi-camera"
          hide-details
          prepend-icon
          outlined
          dense
          :placeholder="$attrs.disabled ? 'Foto' : 'Haz clic aquí para agregar una imagen'"
          @click:append="download()"
          @change="onChange($event)"
        ></v-file-input>
      </v-card>
    </v-hover>
  </div>
</template>

<script>
export default {
  props: {
    imgSrc: {
      type: String,
      required: false,
    },
    imgHeight: {
      type: String,
      default: "250",
    },
    // imagen que se muestra por defecto cuando no hay imgSrc
    // la imagen debe existir dentro de la carpeta public
    defaultImg: {
      type: String,
      default: "default_img.jpg",
    },
    // si es true, permite adjuntar o cambiar de imagen.
    // si es false, solo muestra la imagen desde imgSrc.
    attachment: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async download() {
      this.loading = true;
      await new Promise(() =>
        setTimeout(() => {
          const fileURL = window.URL.createObjectURL(this.$attrs.value);
          const fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.setAttribute("download", this.$attrs.value.name);
          document.body.appendChild(fileLink);
          fileLink.click();
          this.loading = false;
        }, 1000)
      );
    },
    onChange(blob) {
      const newImgSrc = blob ? URL.createObjectURL(blob) : null;
      this.$emit("update:imgSrc", newImgSrc);
      this.$emit("input", blob);
    },
    getImgFromAssets(imgName) {
      var images = require.context("../../assets", false, /\.(jpg|png)$/);
      return images("./" + imgName);
    },
  },
  watch: {
    imgSrc(newValue, oldValue) {
      if (
        newValue &&
        oldValue === undefined &&
        this.$attrs.value === undefined
      ) {
        this.loading = true;
        fetch(newValue)
          .then((res) => res.blob())
          .then((blob) => {
            const fileName = newValue.split("/").pop();
            const image = new File([blob], fileName);
            this.$emit("input", image);
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
