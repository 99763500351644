<template>
  <div>
    <base-card v-bind="$attrs" v-on="$listeners" :disabled="$attrs.loading">
      <v-row v-if="editable && !$attrs.loading && !error">
        <v-col cols="12">
          <v-sheet class="headline pa-2 elevation-2" color="primary" dark>
            <v-chip-group show-arrows>
              <slot name="actions"></slot>
              <v-btn
                v-if="createBtnEnabled"
                text
                title="Nuevo"
                @click="$router.push('create')"
              >
                <v-icon class="mr-2">mdi-plus</v-icon>
                Nuevo
              </v-btn>
              <v-btn
                v-if="editBtnEnabled"
                text
                title="Editar"
                @click="$emit('onEdit')"
              >
                <v-icon class="mr-2">mdi-pencil-outline</v-icon>
                Editar
              </v-btn>
              <v-btn
                v-if="deleteBtnEnabled"
                text
                title="Eliminar"
                @click="deleteDialog = true"
              >
                <v-icon class="mr-2">mdi-delete</v-icon>
                Eliminar
              </v-btn>
            </v-chip-group>
          </v-sheet>
        </v-col>
      </v-row>
      <v-card-text class="text-center">
        <template v-if="error">
          <v-alert
            type="error"
            border="left"
            class="d-flex justify-center align-left"
            >Verifique su conexión e intente nuevamente.
          </v-alert>
          <v-btn color="info" @click="$emit('onRefresh')">
            Reintentar
            <v-icon right>mdi-refresh</v-icon>
          </v-btn>
        </template>
        <template v-else>
          <v-alert v-if="alert.show" text :type="alert.type" class="mt-4">
            {{ alert.message }}
          </v-alert>
          <!-- default slot -->
          <slot></slot>
        </template>
      </v-card-text>
    </base-card>
    <v-btn
      form="myForm"
      v-if="!readMode && !error && submitBtnEnabled"
      fab
      class="primary"
      fixed
      bottom
      right
      dark
      title="Guardar"
      type="submit"
    >
      <v-icon>mdi-send</v-icon>
    </v-btn>
    <base-dialog
      width="500"
      v-model="deleteDialog"
      title="¿Estás seguro?"
      text="Si estás seguro haz clic en 'eliminar' para eliminar el registro."
      actionText="Eliminar"
      icon="mdi-alert"
      @onCancel="deleteDialog = false"
      @onConfirm="confirmDelete()"
    ></base-dialog>
    <v-dialog
      v-model="downloading"
      hide-overlay
      persistent
      width="300"
      @input="$emit('update:downloading', downloading)"
    >
      <v-card color="primary" dark>
        <v-card-text class="pt-2">
          Descargando
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  name: "base-form-card",
  props: {
    editable: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    downloading: {
      type: Boolean,
      default: false,
    },
    readMode: {
      type: Boolean,
      default: false,
    },
    createBtnEnabled: {
      type: Boolean,
      default: true,
    },
    editBtnEnabled: {
      type: Boolean,
      default: true,
    },
    deleteBtnEnabled: {
      type: Boolean,
      default: true,
    },
    submitBtnEnabled: {
      type: Boolean,
      default: true,
    },
    alert: {
      type: Object,
      default() {
        return {
          show: false,
          type: null,
          message: null,
        };
      },
    },
  },
  data() {
    return {
      dialog: false,
      deleteDialog: false,
      toggle_exclusive: undefined,
    };
  },
  methods: {
    confirmDelete() {
      this.deleteDialog = false;
      this.$emit("onDelete");
    },
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly;
    },
  },
};
</script>

<style lang="scss" scoped></style>
