<template>
  <div>
    <base-form-card
      :loading="loading"
      :readMode="readMode"
      :editable="editable"
      :delete-btn-enabled="false"
      :error="error"
      :alert="alert"
      @onEdit="readMode = false"
      @onDelete="remove()"
      @onRefresh="getDataFromApi()"
    >
      <v-form
        ref="form"
        v-model="isValidForm"
        id="myForm"
        @submit.prevent="save()"
      >
        <base-section-card title="Datos del PDV" class="mb-4">
          <v-row>
            <v-col cols="12" sm="6">
              <base-autocomplete
                label="Usuario"
                item-text="username"
                item-value="id"
                v-model="model.userId"
                endpoint="users/role/4"
                :readonly="readMode"
              ></base-autocomplete>
            </v-col>
            <v-col cols="12" sm="6">
              <base-text-field
                label="Código de PDV"
                v-model="model.id"
                :readonly="true"
              ></base-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <base-text-field
                label="Nombre del local"
                v-model="model.localName"
                :rules="[rules.required]"
                :readonly="readMode"
              >
              </base-text-field>
            </v-col>

            <v-col cols="12" sm="6">
              <base-text-field
                label="Nombre del Propietario"
                v-model="model.ownerName"
                :readonly="readMode"
              >
              </base-text-field>
            </v-col>

            <v-col cols="12" sm="6">
              <base-text-field
                label="Antigüedad (años)"
                v-model="model.age"
                :required="true"
              >
              </base-text-field>
            </v-col>

            <v-col cols="12" sm="6">
              <base-text-field
                label="Teléfono"
                v-model="model.phoneNumber"
                prepend-inner-icon="mdi-cellphone"
                :readonly="readMode"
                :rules="[rules.required, rules.phoneNumber]"
                :append-icon="validWhatsappNumber ? 'mdi-whatsapp' : ''"
                @click:append="sendWhatsapp()"
              ></base-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <base-text-field
                label="Email"
                type="email"
                v-model="model.email"
                prepend-inner-icon="mdi-email"
                :rules="[rules.email]"
                :readonly="readMode"
                :error-messages="modelErrors['email']"
              >
              </base-text-field>
            </v-col>

            <v-col cols="12" sm="6">
              <base-select
                label="Tipo de local PDV"
                item-text="name"
                item-value="id"
                v-model="model.pointOfSaleTypeId"
                :readonly="readMode"
                endpoint="pointOfSaleTypes"
              ></base-select>
            </v-col>

            <v-col cols="12" sm="6">
              <base-select
                label="Tamaño de PDV"
                item-text="name"
                item-value="id"
                v-model="model.pointOfSaleSizeId"
                :readonly="readMode"
                endpoint="pointSaleSizes"
              ></base-select>
            </v-col>

            <v-col cols="12" sm="6">
              <base-select
                label="Red de cobro"
                item-text="name"
                item-value="id"
                v-model="model.paymentNetworkId"
                :readonly="readMode"
                endpoint="paymentNetworks"
              ></base-select>
            </v-col>

            <v-col cols="12" sm="6">
              <base-text-field
                label="Dirección"
                v-model="model.address"
                :readonly="readMode"
              >
              </base-text-field>
            </v-col>

            <v-col cols="12" sm="6">
              <base-text-field
                label="Referencia"
                v-model="model.addressReference"
                :readonly="readMode"
              >
              </base-text-field>
            </v-col>

            <v-col cols="12" sm="6">
              <base-autocomplete
                label="Ciudad"
                item-text="name"
                item-value="id"
                v-model="model.cityId"
                :readonly="readMode"
                endpoint="departments/city"
                :rules="[rules.required]"
              ></base-autocomplete>
            </v-col>

            <v-col cols="12" sm="6">
              <base-text-field
                label="Barrio"
                v-model="model.neighborhood"
                :readonly="readMode"
              >
              </base-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <base-text-field
                label="Cantidad de góndolas"
                v-model="model.numberOfGondolas"
                :required="true"
              >
              </base-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <base-select
                label="Día de visita"
                item-text="name"
                item-value="id"
                v-model="model.visitDayId"
                :readonly="readMode"
                :rules="[rules.required]"
                endpoint="collectionLimits/days"
              ></base-select>
            </v-col>
            <v-col cols="12" sm="6">
              <base-text-field
                label="VC"
                v-model="model.seller"
                :readonly="true"
              >
              </base-text-field>
            </v-col>
            <v-col cols="12" sm="12">
              <equipments-table
                v-model="model.equipments"
                :read-mode="readMode"
              ></equipments-table>
            </v-col>

            <v-col cols="12" sm="6">
              <v-col cols="12" sm="12">
                <v-checkbox
                  v-model="model.keepVisiting"
                  label="¿Seguir visitando?"
                  :readonly="readMode"
                  outlined
                  validate-on-blur
                  dense
                  hide-details="auto"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="12">
                <v-checkbox
                  v-model="model.hasWifi"
                  label="¿El local cuanta con wifi?"
                  :readonly="readMode"
                  outlined
                  validate-on-blur
                  dense
                  hide-details="auto"
                ></v-checkbox>
              </v-col>

              <v-col cols="12" sm="12">
                <v-checkbox
                  v-model="model.recharges"
                  label="¿Realizan recargas de saldo?"
                  :readonly="readMode"
                  outlined
                  validate-on-blur
                  dense
                  hide-details="auto"
                ></v-checkbox>
              </v-col>

              <v-col cols="12" sm="12">
                <v-checkbox
                  v-model="model.purchaseFinancedBalance"
                  label="¿Realizan compra de saldo financiado?"
                  :readonly="readMode"
                  outlined
                  validate-on-blur
                  dense
                  hide-details="auto"
                ></v-checkbox>
              </v-col>

              <v-col cols="12" sm="12">
                <v-checkbox
                  v-model="model.computer"
                  label="¿PC / Notebook / Tablet?"
                  :readonly="readMode"
                  outlined
                  validate-on-blur
                  dense
                  hide-details="auto"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="12">
                <v-checkbox
                  v-model="model.posTC"
                  label="¿El local cuenta con POS?"
                  :readonly="readMode"
                  outlined
                  validate-on-blur
                  dense
                  hide-details="auto"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="12">
                <v-checkbox
                  v-model="model.smartDevices"
                  label="¿El local cuenta con teléfono inteligente?"
                  :readonly="readMode"
                  outlined
                  validate-on-blur
                  dense
                  hide-details="auto"
                ></v-checkbox>
              </v-col>
            </v-col>
          </v-row>
        </base-section-card>
      </v-form>
      <v-row>
        <v-col cols="12" sm="12">
          <file-uploader
            v-if="model.id"
            title="Documentos"
            :endpoint="filesEndpoint"
            :read-mode="readMode"
          ></file-uploader>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" v-if="model.longitude && model.latitude">
          <iframe
            width="100%"
            height="350"
            :src="`https://www.openstreetmap.org/export/embed.html?bbox=${model.longitude}%2C${model.latitude}%2C;layer=mapnik&amp;marker=${model.latitude}%2C${model.longitude}`"
          >
          </iframe>
        </v-col>
      </v-row>
    </base-form-card>
  </div>
</template>

<script>
import formMixin from "../mixins/formMixin";
import FileUploader from "../components/app/FileUploader";
import EquipmentsTable from "../components/app/EquipmentsTable";

export default {
  name: "PdvDetailForm",
  components: { EquipmentsTable, FileUploader },
  mixins: [formMixin],
  data() {
    return {
      endpoint: "pointOfSale",
      step: 1,
    };
  },
  computed: {
    filesEndpoint() {
      if (this.model && this.model.id)
        return `pointOfSale/${this.model.id}/documents`;
      else return undefined;
    },
  },
  mounted() {},
  methods: {},
};
</script>

<style scoped>
</style>