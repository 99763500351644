import router from "../router";
import rules from "./rules";
import authMixin from "./authMixin";

export default {
  props: {
    id: {
      type: Number,
      required: false,
    },
  },
  mixins: [rules, authMixin],
  data() {
    return {
      endpoint: null,
      model: {},
      modelErrors: [],
      loading: false,
      dialog: false,
      error: false,
      failDelete: false,
      isValidForm: false,
      readMode: false,
      sendAsFormData: false,
      alert: {
        show: false,
        type: null,
        message: null,
      },
      whatsappUrl: 'https://api.whatsapp.com/send'
    };
  },
  mounted() {
    if (this.id) {
      this.readMode = true;
      this.getModel();
    }
  },
  updated() {
    //TODO
  },
  methods: {
    getDataFromApi() {
      if (this.id) this.getModel();
    },
    getModel() {
      this.loading = true;
      this.error = false;
      this.$http
        .get(`${this.endpoint}/${this.id}`)
        .then((resp) => {
          this.model = resp.data;
          this.afterGetModel();
        })
        .catch((error) => {
          window.console.error(error);
          if (!error.response) {
            this.error = true;
            return;
          }
          if (error.response.status === 404) router.push(".");
        })
        .finally(() => {
          this.loading = false;
        });
    },
    save() {
      if (this.$refs.form.validate() && this.customValidation()) {
        this.loading = true;
        this.error = false;
        this.beforeSave();
        const formModel = this.sendAsFormData ? this.getFormData() : this.model;
        const promise = this.id
          ? this.$http.put(`${this.endpoint}/${this.id}`, formModel)
          : this.$http.post(this.endpoint, formModel);

        promise
          .then((resp) => {
            this.afterSave(resp.data);
          })
          .catch((error) => {
            window.console.error(error);

            if (!error.response) {
              this.error = true;
              this.$toast.error("Por favor revisa tu conexión");
              return;
            }

            const {status} = error.response;
            if (status === 400) {
              this.modelErrors = error.response.data.modelErrors;
              this.$toast.error("El formulario contiene errores");
            }
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.$toast.error("El formulario contiene errores");
      }
    },
    remove() {
      this.loading = true;
      this.$http
        .delete(`${this.endpoint}/${this.id}`)
        .then(() => {
          this.afterDelete();
        })
        .catch((error) => {
          if (error.response && error.response.status === 400) {
            this.failDelete = true;
            this.alert.message = error.response.data || "No se puede eliminar este registro porque es utilizado por otros registros";
            this.alert.type = "error";
            this.alert.show = true;
          } else {
            this.error = true;
            window.console.error(error);
          }
        })
        .finally(() => {
          this.loading = false;
          this.dialog = false;
        });
    },
    beforeSave() {
    },
    afterSave(resp) {
      // redirije al componente padre
      router.push(this.parentPath);
      this.$toast.success(resp.id ? "Registro creado con éxito" : "Registro actualizado con éxito");
      // if (resp.id) {
      //   this.$router.replace({path: resp.id.toString()});
      // } else {
      //   this.readMode = true;
      // }
    },
    afterGetModel() {
    },
    afterDelete() {
      router.push(this.parentPath);
      this.$toast.warning("Registro eliminado");
    },
    customValidation() {
      return true;
    },
    getFormData() {
      const formData = new FormData();
      Object.keys(this.model).forEach((key) => {
        if (this.model[key]) {
          if (Array.isArray(this.model[key])) {
            this.model[key].forEach((val) => {
              formData.append(`${key}[]`, val);
            });
          } else {
            formData.append(key, this.model[key]);
          }
        }
      });
      return formData;
    },
    sendWhatsapp() {
      window.open(this.whatsappUrl);
    }
  },
  watch: {
    'model.phoneNumber'(val) {
      if (val && this.validWhatsappNumber) {
        let phone = val;
        if (val.startsWith('0')) phone = val.substr(1);
        if (val.startsWith('595')) phone = val.substr(3);
        if (val.startsWith('+595')) phone = val.substr(4);

        this.whatsappUrl += `?phone=595${phone}`;
      }
    }
  },
  computed: {
    parentPath() {
      const [currentRoute] = router.currentRoute.matched.slice(-1);
      return currentRoute.parent.path;
    },
    editable() {
      return !!this.id;
    },
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly;
    },
    isAdmin() {
      return this.userHasRole('Administrador');
    },
    validWhatsappNumber() {
      if (this.model && this.model.phoneNumber) {
        if (this.model.phoneNumber.startsWith('0')) return this.model.phoneNumber.length == 10;
        if (this.model.phoneNumber.startsWith('595')) return this.model.phoneNumber.length == 12;
        if (this.model.phoneNumber.startsWith('+595')) return this.model.phoneNumber.length == 13;

        return this.model.phoneNumber.length == 9;
      }
      return false;
    },
  },
};
