<template>
  <v-card outlined :loading="loading" :disabled="loading" max-width="900" class="mx-auto">
    <div class="pa-4">
      <v-card-title>¿Olvidaste tu contraseña?</v-card-title>
      <v-card-subtitle class="mt-2">
        No te preocupes, ingresa la dirección de correo con el que te has
        registrado y te enviaremos las instrucciones para reestablecerla.
      </v-card-subtitle>
      <v-container>
        <v-alert v-if="alert.show" text :type="alert.type" class="mt-4">
          {{ alert.message }}
        </v-alert>
        <v-form
            @submit.prevent="submit"
            id="form"
            ref="form"
            v-model="validForm"
        >
          <v-text-field
              outlined
              type="email"
              label="Correo electrónico"
              prepend-inner-icon="mdi-email"
              hint="La dirección de correo con el que te registraste"
              v-model="email"
              :rules="[rules.required, rules.email]"
              validate-on-blur
          >
          </v-text-field>
        </v-form>
      </v-container>
      <v-container>
        <v-btn class="primary" type="submit" form="form" block>
          <v-icon class="mr-2">mdi-send</v-icon>
          Enviar correo
        </v-btn>
      </v-container>
    </div>
  </v-card>
</template>

<script>
import rules from "../mixins/rules";

export default {
  mixins: [rules],
  data() {
    return {
      email: null,
      validForm: false,
      loading: false,
      alert: {
        show: false,
        type: null,
        message: null,
      },
    };
  },
  methods: {
    submit() {
      if (!this.$refs.form.validate()) return;
      this.loading = true;
      this.alert.show = false;
      this.$http
          .get(`account/${this.email}/password-reset-token`)
          .then(() => {
            this.$toast.success(`Correo enviado a ${this.email}`);
            this.alert.message =
                "Revisa tu bandeja de entrada. Si no encuentras el correo, puede que se encuentre en la carpeta de spam.";
            this.alert.type = "success";
            this.alert.show = true;
          })
          .catch((error) => {
            window.console.error(error);
            if (error.response) {
              const status = error.response.status;
              if (status === 404) {
                const invalidEmail = this.email;
                this.$toast.error("No hemos encontrado tu cuenta");
                this.alert.message = `No hemos encontrado ninguna cuenta asociada al correo: ${invalidEmail}`;
                this.alert.type = "error";
                this.alert.show = true;
              } else {
                this.$toast.error("Ocurrió un error inesperado");
                this.alert.message = `No hemos podido enviar el correo, por favor intenta de nuevo en un par de minutos`;
                this.alert.type = "error";
                this.alert.show = true;
              }
            } else {
              this.$toast.error("Verifique su conexión a internet");
            }
          })
          .finally(() => (this.loading = false));
    },
  },
};
</script>

<style>
</style>