<template>
  <div>
    <base-form-card
        :loading="loading"
        :readMode="readMode"
        :editable="editable"
        :error="error"
        :alert="alert"
        @onEdit="readMode = false"
        @onDelete="remove()"
        @onRefresh="getDataFromApi()"
    >
      <v-form
          ref="form"
          v-model="isValidForm"
          id="myForm"
          @submit.prevent="save()"
      >
        <base-section-card title="Datos del Proveedor" class="mb-4">
          <v-row>
            <v-col cols="12" sm="6">
              <base-text-field
                  label="Razón Social"
                  v-model="model.name"
                  :rules="[rules.required]"
                  :readonly="readMode"
                  :error-messages="modelErrors['Name']"
              >
              </base-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <base-text-field
                  label="Nombre Comercial"
                  v-model="model.fantasyName"
                  :readonly="readMode"
              >
              </base-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <base-text-field
                  label="RUC"
                  v-model="model.ruc"
                  :readonly="readMode"
              >
              </base-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <base-text-field
                  label="Email"
                  type="email"
                  v-model="model.email"
                  prepend-inner-icon="mdi-email"
                  :rules="[rules.email]"
                  :readonly="readMode"
                  :error-messages="modelErrors['email']"
              >
              </base-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <base-text-field
                  label="Teléfono"
                  v-model="model.phoneNumber"
                  prepend-inner-icon="mdi-cellphone"
                  :readonly="readMode"
                  :append-icon="validWhatsappNumber ? 'mdi-whatsapp' : ''"
                  @click:append="sendWhatsapp()"
              ></base-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <base-text-field
                  label="Dirección"
                  v-model="model.address"
                  :readonly="readMode"
              >
              </base-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-checkbox
                  v-model="model.isFinancialEntity"
                  label="Entidad Financiera"
                  :readonly="readMode"
                  outlined
                  validate-on-blur
                  dense
                  hide-details="auto"
              ></v-checkbox>
            </v-col>
          </v-row>
        </base-section-card>
        <base-section-card title="Asignaciones" class="mb-4" v-if="model.isFinancialEntity">
          <v-row>
            <v-col cols="12" sm="6">
              <base-autocomplete
                  label="Ciudad"
                  item-text="name"
                  item-value="id"
                  v-model="model.cityId"
                  :readonly="readMode"
                  :error-messages="modelErrors['cityId']"
                  :rules="[rules.required]"
                  endpoint="departments/city"
              ></base-autocomplete>
            </v-col>
            <v-col cols="12" sm="6">
              <base-autocomplete
                  label="Usuario"
                  item-text="username"
                  item-value="id"
                  v-model="model.userId"
                  endpoint="users/role/3"
                  :readonly="readMode"
              ></base-autocomplete>
            </v-col>
          </v-row>
        </base-section-card>
         <bank-accounts-table :read-mode="readMode" v-model="model.bankAccounts"></bank-accounts-table>
      </v-form>
      <file-uploader v-if="model.id" :endpoint="filesEndpoint" title="Catálogos" :read-mode="readMode"></file-uploader>
    </base-form-card>
  </div>
</template>

<script>
import formMixin from "../mixins/formMixin";
import FileUploader from "../components/app/FileUploader";
import BankAccountsTable from "../components/app/BankAccountsTable";

export default {
  name: "ProviderDetailForm",
  components: {BankAccountsTable, FileUploader},
  mixins: [formMixin],
  data() {
    return {
      endpoint: "providers",
      cityIsAvailable: true,
    };
  },
  computed: {
    filesEndpoint() {
      if (this.id) return `providers/${this.id}/catalogs`;
      else return undefined;
    }
  },
  methods: {
    customValidation() {
      return this.cityIsAvailable;
    }
  },
  watch: {
    'model.isFinancialEntity'(val) {
      if (!val) {
        this.model.cityId = null;
        this.model.userId = null;
      }
    },
    'model.cityId'(val) {
      if (val) {  // validamos via API si la ciudad esta disponible
        const providerId = this.id || 0;
        this.$http.get(`${this.endpoint}/${providerId}/city-availability/${this.model.cityId}`)
            .then(() => {
              this.modelErrors = [];
              this.cityIsAvailable = true;
            })
            .catch(error => {
              if (error.response && error.response.status === 400) {
                this.modelErrors = error.response.data;
                this.cityIsAvailable = false;
              }
            })
      }
    }
  },
}
</script>

<style scoped>

</style>