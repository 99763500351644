<template>
  <div>
    <base-card>
      <v-card-text class="mx-center">
        <v-row align="center" dense v-if="!disableFilter">
          <v-col cols="12" sm="6">
            <v-menu
              ref="menu"
              v-model="menu"
              offset-y
              bottom
              :close-on-content-click="false"
            >
              <template v-slot:activator="{}">
                <v-text-field
                  v-model="filterValue"
                  label="Buscar"
                  flat
                  dense
                  filled
                  single-line
                  hide-details
                  prepend-inner-icon="mdi-magnify"
                  append-icon="mdi-filter-menu"
                  autocomplete="off"
                  @keyup="$emit('update:filter', filterValue)"
                  @click:clear="$emit('clear')"
                  @click:append="menu = true"
                ></v-text-field>
              </template>
              <v-card>
                <slot name="filterForm"></slot>
                <v-card-actions>
                  <v-btn text outlined color="primary" @click="onFilter()"
                    >Filtrar
                  </v-btn>
                  <v-btn text outlined @click="onClear()">Limpiar</v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>
          </v-col>

          <template v-if="!error && !$attrs.loading">
            <v-btn
              v-if="!isMobile"
              icon
              color="secondary"
              @click="$emit('refresh')"
              class="mr-1"
              title="Actualizar"
            >
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
            <v-chip
              v-if="filterCount > 0"
              class="mr-2"
              close
              @click:close="$emit('clear')"
              >Filtros: {{ filterCount }}</v-chip
            >

<!--            <v-menu offset-y v-if="exportable">-->
<!--              <template v-slot:activator="{ on }">-->
<!--                <v-btn-->
<!--                  v-if="$attrs.items.length > 0"-->
<!--                  title="Exportar"-->
<!--                  outlined-->
<!--                  text-->
<!--                  color="secondary"-->
<!--                  v-on="on"-->
<!--                  :block="isMobile"-->
<!--                >-->
<!--                  <v-icon class="mr-1">mdi-download</v-icon>-->
<!--                  Exportar-->
<!--                </v-btn>-->
<!--              </template>-->
<!--              <v-list>-->
<!--                <v-list-item-->
<!--                  v-for="(formato, index) in formatos"-->
<!--                  :key="index"-->
<!--                  @click="$emit('export', formato.value)"-->
<!--                  dense-->
<!--                >-->
<!--                  <v-list-item-action v-if="formato.icon">-->
<!--                    <v-icon>{{ formato.icon }}</v-icon>-->
<!--                  </v-list-item-action>-->
<!--                  <v-list-item-title>{{ formato.text }}</v-list-item-title>-->
<!--                </v-list-item>-->
<!--              </v-list>-->
<!--            </v-menu>-->

            <v-btn
                v-if="exportable && $attrs.items.length > 0"
                title="Exportar"
                outlined
                text
                color="secondary"
                :block="isMobile"
                @click="$emit('export', 'xlsx')"
            >
              <v-icon class="mr-1">mdi-download</v-icon>
              Exportar
            </v-btn>

            <slot name="actions"></slot>
          </template>
        </v-row>

        <v-data-table
          multi-sort
          loading-text="Cargando..."
          v-bind="$attrs"
          v-on="$listeners"
          @click:row="$emit('rowClick', $event)"
        >
          <!-- Pass on all named slots -->
          <slot v-for="slot in Object.keys($slots)" :name="slot" :slot="slot" />

          <!-- Pass on all scoped slots -->
          <template
            v-for="slot in Object.keys($scopedSlots)"
            :slot="slot"
            slot-scope="scope"
            ><slot :name="slot" v-bind="scope"
          /></template>

          <template v-slot:no-data>
            <v-container>
              <template v-if="error">
                <v-alert type="error">
                  Verifique su conexión e intente nuevamente.
                </v-alert>
                <v-btn color="info" @click="$emit('refresh')">
                  Reintentar
                  <v-icon right>mdi-refresh</v-icon>
                </v-btn>
              </template>
              <template v-else>No se encontraron datos</template>
            </v-container>
          </template>
        </v-data-table>
      </v-card-text>
    </base-card>

    <v-overlay :value="downloading">
      <v-dialog v-model="downloading" hide-overlay persistent width="300">
        <v-card color="primary" dark>
          <v-card-text class="pt-2">
            Descargando
            <v-progress-linear indeterminate color="white"></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-overlay>
  </div>
</template>

<script>
export default {
  props: {
    filter: {
      type: String,
      required: false,
    },
    filterCount: {
      type: Number,
      required: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    downloading: {
      type: Boolean,
      default: false,
    },
    exportable: {
      type: Boolean,
      default: false,
    },
    disableFilter: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      menu: false,
      filterValue: null,
      formatos: [
        { text: "PDF", value: "pdf", icon: "mdi-file-pdf-outline" },
        { text: "Excel", value: "xlsx", icon: "mdi-file-table-outline" },
      ],
    };
  },
  methods: {
    onfilter() {
      this.menu = false;
      this.$emit("refresh");
    },
    onClear() {
      this.menu = false;
      this.$emit("clear");
    },
  },
  watch: {
    filter(val) {
      this.filterValue = val;
    },
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly;
    },
  },
};
</script>

<style lang="scss" scoped></style>
