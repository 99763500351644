<template>
  <div>
    <base-form-card
        :loading="loading"
        :readMode="readMode"
        :editable="editable"
        :error="error"
        :alert="alert"
        :delete-btn-enabled="false"
        @onEdit="readMode = false"
        @onDelete="remove()"
        @onRefresh="getDataFromApi()"
    >
      <v-form
          ref="form"
          v-model="isValidForm"
          id="myForm"
          @submit.prevent="beforeSendOtp()"
      >
        <base-section-card title="Datos Básicos" class="mb-4">
          <v-row>
            <v-col cols="12" sm="6">
              <base-autocomplete
                  label="Nombre del local"
                  item-text="localName"
                  item-value="id"
                  v-model="pointOfSale"
                  return-object
                  endpoint="pointOfSale"
                  :rules="[rules.required]"
                  :readonly="readMode"
              ></base-autocomplete>
            </v-col>
            <v-col cols="12" sm="6">
              <base-text-field
                  label="Nombre del Propietario"
                  v-model="pointOfSale.ownerName"
                  :readonly="true"
              >
              </base-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <base-text-field
                  label="Teléfono"
                  v-model="pointOfSale.phoneNumber"
                  prepend-inner-icon="mdi-cellphone"
                  :rules="[rules.required, rules.phoneNumber]"
                  :append-icon="validWhatsappNumber ? 'mdi-whatsapp' : ''"
                  @click:append="sendWhatsapp()"
                  :readonly="true"
              ></base-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <base-text-field
                  label="Email"
                  type="email"
                  v-model="pointOfSale.email"
                  prepend-inner-icon="mdi-email"
                  :readonly="true"
              >
              </base-text-field>
            </v-col>
          </v-row>
        </base-section-card>
        <base-section-card title="Detalles del Crédito" class="mb-4">
          <v-row>
            <v-col cols="12" sm="6">
              <base-autocomplete
                  label="Crédito ID"
                  item-text="id"
                  item-value="id"
                  v-model="creditRequest"
                  :readonly="readMode"
                  :endpoint="creditRequestEndpoint"
                  return-object
                  :rules="[rules.required]"
              ></base-autocomplete>
            </v-col>
            <template v-if="creditRequest.creditTypeId && creditRequest.creditTypeId == 2">
              <v-col cols="12" sm="4">
                <base-autocomplete
                    label="Articulo"
                    item-text="name"
                    item-value="id"
                    v-model="creditRequest.productId"
                    :readonly="readMode"
                    endpoint="products"
                ></base-autocomplete>
              </v-col>
              <v-col cols="12" sm="2">
                <base-text-field
                    label="Código"
                    v-model="creditRequest.productCode"
                    :readonly="true"
                    type="number"
                ></base-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <base-autocomplete
                    label="Proveedor"
                    item-text="name"
                    item-value="id"
                    v-model="creditRequest.providerId"
                    :readonly="true"
                    endpoint="providers/common-providers"
                ></base-autocomplete>
              </v-col>
            </template>
            <v-col cols="12" sm="6">
              <base-currency-input
                  label="Monto del Crédito (Gs.)"
                  v-model="creditRequest.creditAmount"
                  :readonly="true"
                  :greaterThanZero="true">
              </base-currency-input>
            </v-col>
            <v-col cols="12" sm="6">
              <base-currency-input
                  :greaterThanZero="true"
                  label="Monto de cuota (Gs.)"
                  v-model="creditRequest.feeAmount"
                  :readonly="true">
              </base-currency-input>
            </v-col>
            <v-col cols="12" sm="6">
              <base-text-field
                  label="Nro. de Cuotas"
                  v-model="creditRequest.numberOfQuotas"
                  :readonly="true"
                  type="number"
              >
              </base-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <base-autocomplete
                  label="Frecuencia de Pago"
                  item-text="name"
                  item-value="id"
                  v-model="creditRequest.paymentFrequencyId"
                  :readonly="true"
                  endpoint="paymentFrecuencies"
              ></base-autocomplete>
            </v-col>
            <v-col cols="12" sm="6">
              <base-date-field
                  label="Vencimiento de la primera cuota"
                  v-model="creditRequest.collectionDateStart"
                  hint="dd/mm/aaaa"
                  :readonly="true"
              ></base-date-field>
            </v-col>
          </v-row>
        </base-section-card>
      </v-form>
      <file-uploader v-if="model.id" :endpoint="filesEndpoint"></file-uploader>
    </base-form-card>
    <v-dialog
        v-model="showOtpDialog"
        persistent
        width="450"
    >
      <base-card :loading="sendingOtp" :disabled="sendingOtp">
        <v-card-title>
          Confirmar Solicitud
        </v-card-title>
        <v-form
            ref="form2"
            v-model="isValidForm"
            id="myForm2"
            @submit.prevent="validateOtpCode()"
        >
          <v-card-text>
            Resumen de la entrega:
            <ul>
              <li>Nombre del local: <b>{{ pointOfSale.localName }}</b></li>
              <li>Monto del Crédito: <b>Gs. {{ currencyFormatter(creditRequest.creditAmount) }}</b></li>
            </ul>
            <br>
            Ingresa el PIN que hemos enviado a {{ this.pointOfSale.phoneNumber }}
            <v-row dense>
              <v-col cols="6">
                <base-text-field
                    type="number"
                    v-model="otpCode"
                    label="PIN"
                    :rules="[rules.required]"
                ></base-text-field>
              </v-col>
              <v-col cols="6">
                <v-btn color="primary" @click="validateOtpCode">Confirmar</v-btn>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions>
            <v-btn outlined @click="reSendOtp">Reenviar PIN</v-btn>
            <v-btn outlined @click="showOtpDialog = false">Cancelar</v-btn>
          </v-card-actions>
        </v-form>
      </base-card>
    </v-dialog>
  </div>
</template>

<script>
import formMixin from "../mixins/formMixin";
import authMixin from "../mixins/authMixin";
import FileUploader from "../components/app/FileUploader";
import router from "../router";

export default {
  name: "DeliveryDetailForm",
  mixins: [formMixin, authMixin],
  components: {FileUploader},
  data() {
    return {
      endpoint: 'deliveries',
      step: 1,
      pointOfSale: {},
      creditRequest: {},
      showOtpDialog: false,
      sendingOtp: false,
      otpCode: null,
      otpId: null,
    }
  },
  methods: {
    afterGetModel() {
      this.getPointOfSale();
      this.getCreditRequest();
    },
    getPointOfSale() {
      this.loading = true;
      this.error = false;
      this.$http
          .get(`pointOfSale/${this.model.pointOfSaleId}`)
          .then((resp) => {
            this.pointOfSale = resp.data;
          })
          .catch((error) => {
            window.console.error(error);
            if (!error.response) {
              this.error = true;
              return;
            }
            if (error.response.status === 404) router.push(".");
          })
          .finally(() => {
            this.loading = false;
          });
    },
    getCreditRequest() {
      this.loading = true;
      this.error = false;
      this.$http
          .get(`creditRequest/${this.model.creditId}`)
          .then((resp) => {
            this.creditRequest = resp.data;
          })
          .catch((error) => {
            window.console.error(error);
            if (!error.response) {
              this.error = true;
              return;
            }
          })
          .finally(() => {
            this.loading = false;
          });
    },
    beforeSave() {
      // this.model.deliveredBy = this.getUserData().nameid;
      this.model.deliveryDate = new Date();
    },
    afterSave() {
      router.push(this.parentPath);
      this.$toast.success("Registro actualizado con éxito");
    },
    beforeSendOtp() {
      if (this.$refs.form.validate()) {
        this.sendOtp();
      }
    },
    sendOtp() {
      this.loading = true
      let otpModel = {};
      otpModel.creditId = this.creditRequest.id;
      otpModel.phone = this.pointOfSale.phoneNumber;
      this.$http.post('DeliveryOTP', otpModel)
          .then((resp) => {
            this.$toast.success("Hemos enviado el PIN con éxito");
            this.showOtpDialog = true;
            this.otpId = resp.data.id;
          })
          .catch((error) => {
            window.console.error(error)
            if (!error.response) {
              this.error = true;
              this.$toast.error("Por favor revisa tu conexión");
            } else {
              this.$toast.error("No hemos podido enviar el PIN");
            }
          })
          .finally(() => this.loading = false);
    },
    reSendOtp() {
      this.sendingOtp = true
      let otpModel = {};
      otpModel.creditId = this.creditRequest.id;
      otpModel.phone = this.pointOfSale.phoneNumber;
      this.$http.put(`DeliveryOTP/${this.otpId}`, otpModel)
          .then((resp) => {
            this.$toast.success("Hemos re enviado el PIN con éxito");
            this.showOtpDialog = true;
            this.otpId = resp.data.id;
          })
          .catch((error) => {
            window.console.error(error)
            if (!error.response) {
              this.error = true;
              this.$toast.error("Por favor revisa tu conexión");
            } else {
              this.$toast.error("No hemos podido enviar el PIN");
            }
          })
          .finally(() => this.sendingOtp = false);
    },
    validateOtpCode() {
      if (this.$refs.form2.validate()) {
        this.sendingOtp = true
        this.$http.get(`DeliveryOTP/${this.creditRequest.id}/otp/${this.otpCode}`)
            .then(() => {
              this.showOtpDialog = false;
              this.save();
            })
            .catch((error) => {
              window.console.error(error)
              if (!error.response) {
                this.error = true;
                this.$toast.error("Por favor revisa tu conexión");
              } else {
                this.$toast.error("El PIN ingresado no es válido o ha expirado");
              }
            })
            .finally(() => this.sendingOtp = false);
      }
    },
    currencyFormatter(val, n, x) {
      if (!val) return null
      const re = "(\\d)(?=(\\d{" + (x || 3) + "})+" + (n > 0 ? "\\." : "$") + ")";
      return val.toFixed(Math.max(0, ~~n)).replace(new RegExp(re, "g"), "$1.");
    }
  },
  watch: {
    pointOfSale() {
      if (this.pointOfSale && this.pointOfSale.id) {
        this.model.pointOfSaleId = this.pointOfSale.id;
      } else if (this.pointOfSale == null) {
        this.model.pointOfSaleId = null;
        this.pointOfSale = {};
      }
      this.creditRequest = null;
    },
    creditRequest() {
      if (this.creditRequest && this.creditRequest.id) {
        this.model.creditId = this.creditRequest.id;
      } else if (this.creditRequest == null) {
        this.model.creditId = null;
        this.creditRequest = {};
      }
    }
  },
  computed: {
    creditRequestEndpoint() {
      if (this.pointOfSale && this.pointOfSale.id)
        return `creditRequest/pointofsale/${this.pointOfSale.id}`;
      else return null;
    },
    filesEndpoint() {
      if (this.model && this.model.id) return `deliveries/${this.model.id}/documents`;
      else return undefined;
    }
  },
}
</script>

<style scoped>

</style>