<template>
  <div>
    <base-form-card
        :loading="loading"
        :readMode="readMode"
        :editable="editable"
        :error="error"
        :alert="alert"
        @onEdit="readMode = false"
        @onDelete="remove()"
        @onRefresh="getDataFromApi()"
    >
      <v-form
          ref="form"
          v-model="isValidForm"
          id="myForm"
          @submit.prevent="save()"
      >
        <base-section-card title="Datos del Producto" class="mb-4">
          <v-row>
            <v-col cols="12">
              <base-text-field
                  label="Nombre"
                  v-model="model.name"
                  :rules="[rules.required]"
                  :readonly="readMode"
                  :error-messages="modelErrors['Name']"
              ></base-text-field>
            </v-col>
            <v-col cols="12">
              <base-text-field
                  label="Código"
                  v-model="model.productCode"
                  :readonly="readMode"
              ></base-text-field>
            </v-col>
            <v-col cols="12">
              <base-autocomplete
                  label="Marca"
                  endpoint="brands"
                  item-text="name"
                  item-value="id"
                  v-model="model.brandId"
                  :readonly="readMode"
                  :error-messages="modelErrors['brandId']"
              ></base-autocomplete>
            </v-col>
            <v-col cols="12">
              <base-text-field
                  label="Modelo"
                  v-model="model.model"
                  :readonly="readMode"
              ></base-text-field>
            </v-col>
            <v-col cols="12">
              <base-autocomplete
                  label="Proveedor"
                  endpoint="providers/common-providers"
                  item-text="name"
                  item-value="id"
                  v-model="model.providerId"
                  :error-messages="modelErrors['providerId']"
                  :readonly="readMode"
              ></base-autocomplete>
            </v-col>
          </v-row>
        </base-section-card>
      </v-form>
    </base-form-card>
  </div>
</template>

<script>
import formMixin from "../mixins/formMixin";

export default {
  name: "ProductForm",
  mixins: [formMixin],
  data() {
    return {
      endpoint: "products",
    }
  }
}
</script>

<style scoped>

</style>