<template>
  <div v-if="user">
    <calendar v-if="ViewCalendar"></calendar>
  </div>
</template>

<script>
import authMixin from "../mixins/authMixin";
import Calendar from "../components/app/Calendar";
export default {
  name: "Home",
  components: {Calendar},
  mixins: [authMixin],
  data() {
    return {
      user: null,
    };
  },
  created() {
    this.user = this.getUserData();
  },
  computed: {
    ViewCalendar() {
        var seeCalendar = this.userHasRole("Administrador") || this.userHasRole("Gestor_Administrativo") || this.userHasRole("VC");
        console.log("calendario " + seeCalendar);
        return seeCalendar;
    },
  },
};
</script>
