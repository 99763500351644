<template>
  <div>
    <v-navigation-drawer
      v-model="drawer"
      :clipped="$vuetify.breakpoint.lgAndUp"
      app
    >
      <v-list dense nav>
        <v-list-item>
          <v-list-item-avatar>
            <v-avatar>
              <img v-if="user && user.fotoUrl" :src="user.fotoUrl" />
              <v-icon v-else large>mdi-account-circle</v-icon>
            </v-avatar>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="subtitle-1">
              <span>{{ `${user.unique_name}` }}</span>
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ user.roles.toString() }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <!-- <v-divider></v-divider> -->
        <template v-for="item in items">
          <v-list-group
            v-if="item.children && (!item.roles || userHasAnyRole(item.roles))"
            :key="item.text"
            v-model="item.model"
            :prepend-icon="item.prependIcon"
            :append-icon="item.model ? item.icon : item['icon-alt']"
            color="primary"
          >
            <template v-slot:activator>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{ item.text }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <v-list-item
              v-for="(child, i) in filterItemsByRol(item.children)"
              :key="i"
              link
              :to="child.path"
            >
              <v-list-item-action v-if="child.icon">
                <v-icon>{{ child.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ child.text }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <v-list-item
            v-else-if="!item.roles || userHasAnyRole(item.roles)"
            :key="item.text"
            link
            :to="item.path"
            color="primary"
          >
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar
      :clipped-left="$vuetify.breakpoint.lgAndUp"
      app
      color="primary"
      dark
    >
      <v-toolbar-title style="width: 300px" class="ml-0 pl-4">
        <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
      </v-toolbar-title>
      <v-spacer></v-spacer>

      <v-menu offset-y>
        <template v-slot:activator="{ on }">
          <v-btn icon large v-on="on">
            <v-avatar size="32px" item>
              <v-icon>mdi-account-circle</v-icon>
            </v-avatar>
          </v-btn>
        </template>
        <v-list>
          <v-list-item to="/password-change">
            <v-list-item-action>
              <v-icon>mdi-lock</v-icon>
            </v-list-item-action>
            <v-list-item-title>Cambiar contraseña</v-list-item-title>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item @click="dialog = true">
            <v-list-item-action>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-action>
            <v-list-item-title>Cerrar sesión</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <base-dialog
      width="500"
      v-model="dialog"
      title="Cerrar sesión"
      text="Para confirmar, haga clic en cerrar sesión"
      actionText="Cerrar sesión"
      @onCancel="dialog = false"
      @onConfirm="logout()"
    ></base-dialog>
  </div>
</template>

<script>
import authMixin from "../../mixins/authMixin";
export default {
  name: "NavBar",
  mixins: [authMixin],
  data() {
    return {
      drawer: null,
      dialog: false,
      user: null,
      items: [
        {
          icon: "mdi-home-city",
          text: "Página principal",
          path: "/",
          class: "font-weight-black h1",
        },
        // {
        //   icon: 'mdi-chevron-up',
        //   'icon-alt': 'mdi-chevron-down',
        //   prependIcon: 'mdi-view-dashboard',
        //   text: 'Administración',
        //   roles: ['Administrador'],
        //   model: false,
        //   children: [
        //     { text: 'Usuarios', path: '/usuarios', roles: ['Administrador'] }
        //   ]
        // },
        {
          text: "Usuarios",
          icon: "mdi-account-multiple",
          path: "/users",
          roles: ["Administrador"],
        },
        {
          text: "Tamaños de PDVs",
          icon: "mdi-view-list",
          path: "/pointsalesize",
          roles: ["Administrador"],
        },
        {
          text: "Marcas",
          icon: "mdi-view-list",
          path: "/brands",
          roles: ["Administrador"],
        },
        {
          text: "Marcas de bebidas",
          icon: "mdi-view-list",
          path: "/drinkbrands",
          roles: ["Administrador"],
        },
        {
          text: "Proveedores",
          icon: "mdi-view-list",
          path: "/providers",
          roles: ["Administrador", "Gestor_Administrativo"],
        },
        {
          text: "Redes de pago",
          icon: "mdi-view-list",
          path: "/paymentnetworks",
          roles: ["Administrador"],
        },
        {
          text: "Tipos de Local PDV",
          icon: "mdi-view-list",
          path: "/pointofsaletypes",
          roles: ["Administrador"],
        },
        {
          text: "Electromésticos",
          icon: "mdi-view-list",
          path: "/products",
          roles: ["Administrador", "Gestor_Administrativo"],
        },
        {
          text: "Límites de cobranzas",
          icon: "mdi-view-list",
          path: "/collectionlimits",
          roles: ["Administrador", "Gestor_Administrativo"],
        },
        {
          text: "Catálogos",
          icon: "mdi-view-list",
          path: "/catalogs",
          roles: ["Administrador", "VC", "PDV"],
        },
        {
          text: "Puntos de Ventas",
          icon: "mdi-view-list",
          path: "/pointOfSales",
          roles: ["Administrador", "Gestor_Administrativo", "VC"],
        },
                {
          text: "PDVs sin venta",
          icon: "mdi-view-list",
          path: "/unsoldPdvs",
          roles: ["Administrador", "Gestor_Administrativo", "VC"],
        },
        {
          text: "Visitas",
          icon: "mdi-view-list",
          path: "/visits",
          roles: ["Administrador", "VC"],
        },
        {
          text: "Solicitudes de Créditos",
          icon: "mdi-view-list",
          path: "/creditRequest",
          roles: [
            "Administrador",
            "VC",
            "Institucion_Financiera",
            "Gestor_Administrativo",
            "PDV",
          ],
        },
        {
          text: "Entregas",
          icon: "mdi-view-list",
          path: "/deliveries",
          roles: ["Administrador", "VC", "Gestor_Administrativo"],
        },
        {
          text: "Cobranzas Realizadas",
          icon: "mdi-view-list",
          path: "/quotaPayments",
          roles: ["Administrador", "VC", "Gestor_Administrativo"],
        },
        {
          text: "Cobranzas Agendadas",
          icon: "mdi-view-list",
          path: "/scheduledVisits",
          roles: ["Administrador", "Gestor_Administrativo"],
        },
        {
          text: "Control de Moras",
          icon: "mdi-view-list",
          path: "/debtUpdate",
          roles: ["Administrador", "Gestor_Administrativo"],
        },
        {
          text: "Depósitos",
          icon: "mdi-view-list",
          path: "/deposits",
          roles: [
            "Administrador",
            "VC",
            "Gestor_Administrativo",
            "Institucion_Financiera",
          ],
        },
        {
          text: "Mi billetera",
          icon: "mdi-view-list",
          path: "/wallet",
          roles: ["Administrador", "VC"],
        },
        {
          text: "Rendiciones",
          icon: "mdi-view-list",
          path: "/closedWallet",
          roles: ["Administrador", "VC", "Gestor_Administrativo"],
        },
        {
          text: "Acerca de",
          icon: "mdi-information",
          path: "/about",
          roles: ["Administrador"],
        },
      ],
    };
  },
  created() {
    this.user = this.getUserData();
  },
  methods: {
    logout() {
      this.dialog = false;
      setTimeout(() => {
        this.$store.dispatch("logout");
      }, 500);
    },
    filterItemsByRol(items) {
      return items.filter((i) => !i.roles || this.userHasAnyRole(i.roles));
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
