import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import About from '../views/About.vue'
import Login from '../views/Login.vue'
import UserList from '../views/UserList.vue'
import UserRegisterForm from '../views/UserRegisterForm.vue'
import UserDetailForm from '../views/UserDetailForm.vue'
import authService from "../mixins/authMixin"
import ForgotPassword from '../views/ForgotPassword.vue'
import PasswordReset from '../views/PasswordResetForm.vue'
import PasswordChange from "../views/PasswordChangeForm";
import ListPointOfSaleSizes from '../views/ListPointOfSaleSizes.vue'
import PointSizeForm from '../views/PointSizeForm.vue'
import BrandList from '../views/BrandList.vue'
import BrandForm from '../views/BrandForm.vue'
import ProviderList from "../views/ProviderList";
import ProviderDetailForm from "../views/ProviderDetailForm.vue";
import ProviderRegisterForm from "../views/ProviderRegisterForm.vue";
import ListNetworks from '../views/ListPaymentNetworks.vue'
import NetworkForm from '../views/PaymentNetworkForm.vue'
import ListTypes from '../views/ListPointSaleTypes.vue'
import TypeForm from '../views/PointOfSaleTypeForm.vue'
import ProductList from "../views/ProductList.vue";
import ProductForm from "../views/ProductForm.vue";
import CollectionLimitsList from "../views/ListCollectionLimitsUsers.vue";
import CollectionLimitsForm from "../views/CollectionLimitsForm.vue";
import CatalogList from "../views/CatalogList.vue";
import VisitList from "../views/VisitList.vue";
import VisitRegisterForm from "../views/VisitRegisterForm.vue";
import VisitDetailForm from "../views/VisitDetailForm";
import PdvList from "../views/PdvList";
import PdvRegisterForm from "../views/PdvRegisterForm";
import PdvDetailForm from "../views/PdvDetailForm";
import CreditRequestList from "../views/CreditRequestList";
import CreditRequestForm from "../views/CreditRequestForm";
import DeliveryList from "../views/DeliveryList";
import DeliveryRegisterForm from "../views/DeliveryRegisterForm";
import DeliveryDetailForm from "../views/DeliveryDetailForm";
import QuotaPaymentList from "../views/QuotaPaymentList";
import QuotaPaymentRegisterForm from "../views/QuotaPaymentRegisterForm";
import QuotaPaymentDetailForm from "../views/QuotaPaymentDetailForm";
import ScheduledVisitList from "../views/ScheduledVisitList";
import ScheduledVisitForm from "../views/ScheduledVisitForm";
import DebtUpdateForm from "../views/DebtUpdateForm";
import DepositList from "../views/DepositList";
import DepositForm from "../views/DepositRegistrationForm";
import DepositDetailForm from "../views/DepositForm";
import WalletSummary from "../views/WalletSummary";
import ClosedWalletList from "../views/ClosedWalletList";
import DrinkBrandList from '../views/DrinkBrandList.vue'
import DrinkBrandForm from '../views/DrinkBrandForm.vue'
import UnsoldPdvList from "../views/UnsoldPdvs";

Vue.use(VueRouter)

const isLoggedIn = authService.methods.isLoggedIn;
const userHasRole = authService.methods.userHasRole;


const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {public: true},
  },
  {
    path: "/about",
    name: "about",
    component: About,
    meta: {roles: ["Administrador"]},
  },
  {
    path: "/forgot-password",
    name: "forgotPassword",
    component: ForgotPassword,
    meta: {public: true},
  },
  {
    path: "/password-reset",
    name: "passwordReset",
    component: PasswordReset,
    meta: {public: true},
  },
  {
    path: "/password-change",
    name: "passwordChange",
    component: PasswordChange,
  },
  {
    path: "/catalogs",
    name: "catalog'list",
    component: CatalogList,
    meta: {
      ignoreBreadcrumb: true,
      title: "Catálogos",
    },
  },
  {
    path: "/users",
    component: {
      render(c) {
        return c("router-view");
      },
    },
    children: [
      {
        path: "",
        name: "user-list",
        component: UserList,
        meta: {
          roles: ["Administrador"],
          ignoreBreadcrumb: true,
          title: "Usuarios",
        },
      },
      {
        path: "create",
        name: "create-user",
        component: UserRegisterForm,
        meta: {title: "Registrar Usuario"},
      },
      {
        path: ":id",
        name: "user-detail",
        component: UserDetailForm,
        meta: {title: "Detalle Usuario"},
        props: (route) => ({id: +route.params.id}),
      },
    ],

  },
  {
    path: "/pointsalesize",
    component: {
      render(c) {
        return c("router-view");
      },
    },
    children: [
      {
        path: "",
        name: "point-sale-sizes-list",
        component: ListPointOfSaleSizes,
        meta: {
          roles: ["Administrador","VC"],
          ignoreBreadcrumb: true,
          title: "Tamaños de PDVs",
        },
      },
      {
        path: "create",
        name: "create-point-size",
        component: PointSizeForm,
        meta: {title: "Registrar Tamaño"},
      },
      {
        path: ":id",
        name: "size-detail",
        component: PointSizeForm,
        meta: {title: "Detalle de tamaño"},
        props: (route) => ({id: +route.params.id}),
      },
    ],
  },
  {
    path: "/brands",
    component: {
      render(c) {
        return c("router-view");
      },
    },
    children: [
      {
        path: "",
        name: "brand-list",
        component: BrandList,
        meta: {
          roles: ["Administrador"],
          ignoreBreadcrumb: true,
          title: "Marcas",
        },
      },
      {
        path: "create",
        name: "create-brand",
        component: BrandForm,
        meta: {title: "Registrar Marca"},
      },
      {
        path: ":id",
        name: "brand-detail",
        component: BrandForm,
        meta: {title: "Detalle de marca"},
        props: (route) => ({id: +route.params.id}),
      },
    ],
  },
  {
    path: "/drinkbrands",
    component: {
      render(c) {
        return c("router-view");
      },
    },
    children: [
      {
        path: "",
        name: "drinkbrand-list",
        component: DrinkBrandList,
        meta: {
          roles: ["Administrador"],
          ignoreBreadcrumb: true,
          title: "Marcas de bebidas",
        },
      },
      {
        path: "create",
        name: "create-drinkbrand",
        component: DrinkBrandForm,
        meta: {title: "Registrar Marca de bebida"},
      },
      {
        path: ":id",
        name: "drinkbrand-detail",
        component: DrinkBrandForm,
        meta: {title: "Detalle de marca de bebida"},
        props: (route) => ({id: +route.params.id}),
      },
    ],
  },
  {
    path: "/providers",
    component: {
      render(c) {
        return c("router-view");
      },
    },
    children: [
      {
        path: "",
        name: "provider-list",
        component: ProviderList,
        meta: {
          roles: ["Administrador","Gestor_Administrativo"],
          ignoreBreadcrumb: true,
          title: "Proveedores",
        },
      },
      {
        path: "create",
        name: "create-provider",
        component: ProviderRegisterForm,
        meta: {title: "Registrar Proveedor"},
      },
      {
        path: ":id",
        name: "provider-detail",
        component: ProviderDetailForm,
        meta: {title: "Detalle de Proveedor"},
        props: (route) => ({id: +route.params.id}),
      },
    ],
  },
  {
    path: "/paymentnetworks",
    component: {
      render(c) {
        return c("router-view");
      },
    },
    children: [
      {
        path: "",
        name: "payment-networks-list",
        component: ListNetworks,
        meta: {
          roles: ["Administrador"],
          ignoreBreadcrumb: true,
          title: "Redes de pago",
        },
      },
      {
        path: "create",
        name: "create-network",
        component: NetworkForm,
        meta: {title: "Registrar Red"},
      },
      {
        path: ":id",
        name: "network-detail",
        component: NetworkForm,
        meta: {title: "Detalle de Red"},
        props: (route) => ({id: +route.params.id}),
      },
    ],
  },
  {
    path: "/pointofsaletypes",
    component: {
      render(c) {
        return c("router-view");
      },
    },
    children: [
      {
        path: "",
        name: "point-of-sale-types-list",
        component: ListTypes,
        meta: {
          roles: ["Administrador"],
          ignoreBreadcrumb: true,
          title: "Tipos de local de PDV",
        },
      },
      {
        path: "create",
        name: "create-type",
        component: TypeForm,
        meta: {title: "Registrar Tipo de local PDV"},
      },
      {
        path: ":id",
        name: "type-detail",
        component: TypeForm,
        meta: {title: "Detalle de Tipo de local PDV"},
        props: (route) => ({id: +route.params.id}),
      },
    ],
  },
  {
    path: "/products",
    component: {
      render(c) {
        return c("router-view");
      },
    },
    children: [
      {
        path: "",
        name: "product-list",
        component: ProductList,
        meta: {
          roles: ["Administrador","Gestor_Administrativo"],
          ignoreBreadcrumb: true,
          title: "Electrodomésticos",
        },
      },
      {
        path: "create",
        name: "create-product",
        component: ProductForm,
        meta: {title: "Registrar Electrodoméstico"},
      },
      {
        path: ":id",
        name: "product-detail",
        component: ProductForm,
        meta: {title: "Detalle de Electrodoméstico"},
        props: (route) => ({id: +route.params.id}),
      },
    ],
  },
  {
    path: "/collectionlimits",
    component: {
      render(c) {
        return c("router-view");
      },
    },
    children: [
      {
        path: "",
        name: "collection-limits-list",
        component: CollectionLimitsList,
        meta: {
          roles: ["Administrador","Gestor_Administrativo"],
          ignoreBreadcrumb: true,
          title: "Límites de cobranzas",
        },
      },
      {
        path: "create",
        name: "create-limit",
        component: CollectionLimitsForm,
        meta: {title: "Registrar límite"},
      },
      {
        path: ":id",
        name: "limit-detail",
        component: CollectionLimitsForm,
        meta: {title: "Detalle de límite"},
        props: (route) => ({id: +route.params.id}),
      },
    ],
  },
  {
    path: "/visits",
    component: {
      render(c) {
        return c("router-view");
      },
    },
    children: [
      {
        path: "",
        name: "visit-list",
        component: VisitList,
        meta: {
          roles: ["Administrador","VC"],
          ignoreBreadcrumb: true,
          title: "Visitas",
        },
      },
      {
        path: "create",
        name: "create-visit",
        component: VisitRegisterForm,
        meta: {title: "Registrar Visita"},
      },
      {
        path: ":id",
        name: "visit-detail",
        component: VisitDetailForm,
        meta: {title: "Detalle de Visita"},
        props: (route) => ({id: +route.params.id}),
      },
    ],
  },
  {
    path: "/pointOfSales",
    component: {
      render(c) {
        return c("router-view");
      },
    },
    children: [
      {
        path: "",
        name: "pdv-list",
        component: PdvList,
        meta: {
          roles: ["Administrador", "Gestor_Administrativo", "VC"],
          ignoreBreadcrumb: true,
          title: "Puntos de Ventas",
        },
      },
      {
        path: "create",
        name: "create-pdv",
        component: PdvRegisterForm,
        meta: {title: "Registrar PDV"},
      },
      {
        path: ":id",
        name: "pdv-detail",
        component: PdvDetailForm,
        meta: {title: "Detalle de PDV"},
        props: (route) => ({id: +route.params.id}),
      },
    ],
  },
  {
    path: "/unsoldPdvs",
    component: {
      render(c) {
        return c("router-view");
      },
    },
    children: [
      {
        path: "",
        name: "unsoldpdv-list",
        component: UnsoldPdvList,
        meta: {
          roles: ["Administrador", "Gestor_Administrativo", "VC"],
          ignoreBreadcrumb: true,
          title: "PDVs sin venta",
        },
      },
      {
        path: ":id",
        name: "pdv-detail",
        component: PdvDetailForm,
        meta: {title: "Detalle de PDV"},
        props: (route) => ({id: +route.params.id}),
      },
    ],
  },
  {
    path: "/creditRequest",
    component: {
      render(c) {
        return c("router-view");
      },
    },
    children: [
      {
        path: "",
        name: "creditRequest-list",
        component: CreditRequestList,
        meta: {
          roles: ["Administrador","VC","Institucion_Financiera","Gestor_Administrativo","PDV"],
          ignoreBreadcrumb: true,
          title: "Solicitudes de Créditos",
        },
      },
      {
        path: ":id",
        name: "creditRequest-detail",
        component: CreditRequestForm,
        meta: {title: "Detalle de la solicitud"},
        props: (route) => ({id: +route.params.id}),
      },
    ],
  },
  {
    path: "/deliveries",
    component: {
      render(c) {
        return c("router-view");
      },
    },
    children: [
      {
        path: "",
        name: "delivery-list",
        component: DeliveryList,
        meta: {
          roles: ["Administrador","VC","Gestor_Administrativo"],
          ignoreBreadcrumb: true,
          title: "Entregas",
        },
      },
      {
        path: "create",
        name: "create-delivery",
        component: DeliveryRegisterForm,
        meta: {title: "Registrar Entrega"},
      },
      {
        path: ":id",
        name: "delivery-detail",
        component: DeliveryDetailForm,
        meta: {title: "Detalle de Entrega"},
        props: (route) => ({id: +route.params.id}),
      },
    ],
  },
  {
    path: "/quotaPayments",
    component: {
      render(c) {
        return c("router-view");
      },
    },
    children: [
      {
        path: "",
        name: "quotaPayment-list",
        component: QuotaPaymentList,
        meta: {
          roles: ["Administrador","VC","Gestor_Administrativo"],
          ignoreBreadcrumb: true,
          title: "Cobranzas Realizadas",
        },
      },
      {
        path: "create/:scheduleVisitId",
        name: "create-quotaPayment",
        component: QuotaPaymentRegisterForm,
        meta: {title: "Registrar Cobranza"},
        props: (route) => ({scheduleVisitId: +route.params.scheduleVisitId}),
      },
      {
        path: ":id",
        name: "quotaPayment-detail",
        component: QuotaPaymentDetailForm,
        meta: {title: "Detalle de Cobranza"},
        props: (route) => ({id: +route.params.id}),
      },
    ],
  },
  {
    path: "/scheduledVisits",
    component: {
      render(c) {
        return c("router-view");
      },
    },
    children: [
      {
        path: "",
        name: "scheduled-visit-list",
        component: ScheduledVisitList,
        meta: {
          roles: ["Administrador","Gestor_Administrativo"],
          ignoreBreadcrumb: true,
          title: "Cobranzas Agendadas",
        },
      },
      {
        path: "create",
        name: "create-scheduled-visit",
        component: ScheduledVisitForm,
        meta: {title: "Registrar Red"},
      },
      {
        path: ":id",
        name: "scheduled-visit-detail",
        component: ScheduledVisitForm,
        meta: {title: "Detalle de Visita Agendada"},
        props: (route) => ({id: +route.params.id}),
      },
    ],
  },
  {
    path: "/debtUpdate",
    name: "debt-update",
    component: DebtUpdateForm,
    meta: {roles: ["Administrador", "Gestor_Administrativo"], title: "Actualización de Moras"},
  },
  {
    path: "/deposits",
    component: {
      render(c) {
        return c("router-view");
      },
    },
    children: [
      {
        path: "",
        name: "deposits-list",
        component: DepositList,
        meta: {
          roles: ["Administrador","Gestor_Administrativo", "VC", "Institucion_Financiera"],
          ignoreBreadcrumb: true,
          title: "Depósitos",
        },
      },
      {
        path: "create",
        name: "create-deposit",
        component: DepositForm,
        meta: {title: "Registrar depósito"},
      },
      {
        path: ":id",
        name: "deposit-detail",
        component: DepositDetailForm,
        meta: {title: "Detalle de depósito"},
        props: (route) => ({id: +route.params.id}),
      },
    ],
  },
  {
    path: "/wallet",
    name: "wallet",
    component: WalletSummary,
    meta: {roles: ["Administrador", "VC"], title: "Mi Billetera"},
  },
  {
    path: "/closedWallet",
    name: "closed-wallet",
    component: ClosedWalletList,
    meta: {roles: ["Administrador", "VC","Gestor_Administrativo"], title: "Rendiciones"},
  },
]

const router = new VueRouter({
  routes
})

// guards
router.beforeEach((to, from, next) => {
  if (to.meta.public) {
    next();
  } else if (isLoggedIn()) {
    if (canNavigateTo(to)) {
      next();
    } else {
      window.console.info("router.js: access denied.");
      next({name: "home"});
    }
  } else {
    window.console.info("router.js: user is not logged in.");
    next({name: "login", query: {redirect: to.fullPath}});
  }
});

const canNavigateTo = (route) => {
  const roles = route.meta.roles;
  if (!roles) return true;
  return roles.some((r) => userHasRole(r));
};

export default router;

