<template>
  <div>
    <base-section-card :title="title ? title : 'Adjuntos'">
      <template v-slot:actions v-if="!readMode">
        <v-file-input
            truncate-length="20"
            hide-input
            validate-on-blur
            hide-details="auto"
            v-model="file"
            @change="uploadFile()"
            prepend-icon="mdi-cloud-upload"
            accept=".pdf, image/png, image/jpeg, image/bmp"
        ></v-file-input>

      </template>
      <v-row>
        <v-col cols="12">
          <v-data-table
              disable-sort
              disable-pagination
              hide-default-footer
              :headers="headers"
              :items="files"
              :loading="loading"
          >
            <template v-slot:item.actions="{ item }">
              <v-btn @click=downloadFile(item.id) text outlined class="ma-2" :disabled="loading" icon>
                <v-icon>mdi-download</v-icon>
              </v-btn>
              <v-btn @click=showDeleteDialog(item.id) text outlined class="ma-2" :disabled="loading" icon
                     v-if="!readMode">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </base-section-card>
    <base-dialog
        width="500"
        v-model="dialog"
        title="Eliminar archivo"
        text="Estás seguro que deseas eliminar el arhivo?"
        actionText="Eliminar"
        @onCancel="cancelDelete()"
        @onConfirm="deleteFile()"
    ></base-dialog>
  </div>
</template>

<script>
export default {
  props: ['endpoint', 'title', 'readMode'],
  data() {
    return {
      file: null,
      loading: false,
      files: [],
      dialog: false,
      fileToDelete: null,
      headers: [
        {text: "Nombre del archivo", value: "fileName"},
        {text: "Tipo", value: "fileType"},
        {text: "Tamaño", value: "fileSize"},
        {text: "", value: "actions", align: 'center'},
      ],
    }
  },
  created() {
    this.loading = true;
    this.getFilesData().then(resp => {
      this.files = resp.data;
    }).finally(() => this.loading = false);
  },
  methods: {
    uploadFile() {
      this.loading = true;
      const formData = new FormData();
      formData.append("file", this.file);
      this.$http.post(this.endpoint, formData)
          .then(() => {
            this.getFilesData().then(resp => {
              this.files = resp.data;
              this.$toast.success('Carga exitosa');
            });
          })
          .finally(() => {
            this.loading = false;
          })
    },
    async downloadFile(fileId) {
      this.loading = true;
      this.$http
          .get(`${this.endpoint}/${fileId}`, {
            responseType: "blob",
          })
          .then((response) => {
            const blob = new Blob([response.data]);
            const fileURL = window.URL.createObjectURL(blob);
            const fileLink = document.createElement("a");
            let fileName = response.headers["content-disposition"]
                .split("filename=")[1]
                .split(";")[0]
                .split('"')
                .join("");

            fileLink.href = fileURL;
            fileLink.setAttribute("download", fileName);
            document.body.appendChild(fileLink);
            fileLink.click();
            this.$toast.success('Descarga exitosa');
          })
          .catch((error) => {
            window.console.error(error);
            this.$toast.error("No se pudo descargar el archivo");
          })
          .finally(() => {
            this.loading = false;
          });
    },
    getFilesData() {
      return this.$http.get(this.endpoint)
    },
    deleteFile() {
      this.loading = true;
      this.$http.delete(`${this.endpoint}/${this.fileToDelete}`)
          .then(() => {
            this.getFilesData().then(resp => {
              this.files = resp.data;
              this.$toast.success('Archivo elmiminado');
            });
          })
          .finally(() => {
            this.loading = false;
            this.dialog = false;
          })
    },
    showDeleteDialog(fileId) {
      this.dialog = true;
      this.fileToDelete = fileId;
    },
    cancelDelete() {
      this.dialog = false;
      this.fileToDelete = null;
    }
  },
  watch: {
    endpoint(newValue) {
      if (newValue) {
        this.loading = true;
        this.getFilesData().then(resp => {
          this.files = resp.data;
        }).finally(() => this.loading = false);
      }
    },
    files() {
      if (this.files && Array.isArray(this.files)) {
        this.$emit('filesCounter', this.files.length);
      } else
        this.$emit('filesCounter', 0);
    }
  }
}
</script>

<style scoped>

</style>