<template>
  <div>
    <base-form-card
        :loading="loading"
        :readMode="readMode"
        :editable="editable"
        :error="error"
        :alert="alert"
        :create-btn-enabled="false"
        @onEdit="readMode = false"
        @onDelete="remove()"
        @onRefresh="getDataFromApi()"
    >
      <v-form
          ref="form"
          v-model="isValidForm"
          id="myForm"
          @submit.prevent="save()"
      >
        <base-section-card title="Datos de la Visita" class="mb-4">
          <v-row>
            <v-col cols="12" sm="6">
              <base-date-field
                  label="Fecha de la Visita"
                  v-model="model.scheduledDate"
                  :rules="[rules.required]"
                  :readonly="readMode"
              >
              </base-date-field>
            </v-col>
            <v-col cols="12" sm="6">
              <base-autocomplete
                  label="VC Asignado"
                  item-text="username"
                  item-value="id"
                  v-model="model.collectorId"
                  endpoint="users"
                  :readonly="readMode"
              ></base-autocomplete>
            </v-col>
            <v-col cols="12" sm="6">
              <base-autocomplete
                  label="Crédito ID"
                  item-text="id"
                  item-value="id"
                  v-model="model.creditRequestId"
                  :readonly="true"
                  endpoint="CreditRequest"
                  :rules="[rules.required]"
              ></base-autocomplete>
            </v-col>
            <v-col cols="12" sm="6">
              <base-autocomplete
                  label="Tipo de Crédito"
                  item-text="name"
                  item-value="id"
                  v-model="creditRequest.creditTypeId"
                  :readonly="true"
                  endpoint="creditRequest/TypesCredits"
              ></base-autocomplete>
            </v-col>
            <template v-if="creditRequest.creditTypeId && creditRequest.creditTypeId == 2">
              <v-col cols="12" sm="4">
                <base-autocomplete
                    label="Articulo"
                    item-text="name"
                    item-value="id"
                    v-model="creditRequest.productId"
                    :readonly="true"
                    endpoint="products"
                ></base-autocomplete>
              </v-col>
              <v-col cols="12" sm="2">
                <base-text-field
                    label="Código"
                    v-model="creditRequest.productCode"
                    :readonly="true"
                    type="number"
                ></base-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <base-autocomplete
                    label="Proveedor"
                    item-text="name"
                    item-value="id"
                    v-model="creditRequest.providerId"
                    :readonly="true"
                    endpoint="providers/common-providers"
                ></base-autocomplete>
              </v-col>
            </template>
            <v-col cols="12" sm="6">
              <base-currency-input
                  label="Monto del Crédito (Gs.)"
                  v-model="creditRequest.creditAmount"
                  :readonly="true"
                  :greaterThanZero="true">
              </base-currency-input>
            </v-col>
            <v-col cols="12" sm="6">
              <base-currency-input
                  :greaterThanZero="true"
                  label="Monto de cuota (Gs.)"
                  v-model="creditRequest.feeAmount"
                  :readonly="true">
              </base-currency-input>
            </v-col>
          </v-row>
        </base-section-card>
      </v-form>
    </base-form-card>
  </div>
</template>

<script>
import formMixin from "../mixins/formMixin";

export default {
  name: 'ScheduledVisitForm',
  mixins: [formMixin],
  data() {
    return {
      endpoint: "ScheduledVisit",
      creditRequest: {},
    };
  },
  methods: {
    afterGetModel() {
      this.getCreditRequest();
    },
    getCreditRequest() {
      this.loading = true;
      this.error = false;
      this.$http
          .get(`creditRequest/${this.model.creditRequestId}`)
          .then((resp) => {
            this.creditRequest = resp.data;
          })
          .catch((error) => {
            window.console.error(error);
            if (!error.response) {
              this.error = true;
              return;
            }
          })
          .finally(() => {
            this.loading = false;
          });
    },
  },
  watch: {
    'model.creditRequestId'() {
      if (this.model.creditRequestId) {
        this.$http
            .get(`creditRequest/${this.model.creditRequestId}`)
            .then((resp) => {
              this.creditRequest = resp.data;
            })
            .catch((error) => {
              window.console.error(error);
              if (!error.response) {
                this.error = true;
              }
            });
      } else {
        this.creditRequest = {};
      }
    }
  },
  computed: {},
};
</script>

<style>
</style>