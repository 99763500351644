<template>
  <v-row align="center">
    <v-col md="8" v-if="this.$vuetify.breakpoint.lgAndUp">
      <v-img
          :src="require('../assets/login.svg')"
          width="80%"
          class="mx-auto"
      />
    </v-col>
    <v-col xl="4" xs="12">
      <base-card :loading="loading" :disabled="loading" outlined class="mx-auto">
        <v-card-title>
          <v-toolbar-title class="mx-auto">
            Bienvenido 👋
          </v-toolbar-title>
        </v-card-title>
        <v-card-subtitle class="mt-2 text-center">
          Ingrese sus credenciales:
        </v-card-subtitle>
        <v-container>
          <v-form
              @submit.prevent="login"
              ref="form"
              id="form"
              v-model="validForm"
          >
            <v-row>
              <v-col cols="12">
                <base-text-field
                    v-model="model.username"
                    label="nombre de usuario"
                    name="login"
                    prepend-inner-icon="mdi-account"
                    type="text"
                    :rules="[rules.required]"
                />
              </v-col>
              <v-col cols="12">
                <base-text-field
                    label="contraseña"
                    name="password"
                    :type="showPass ? 'text' : 'password'"
                    prepend-inner-icon="mdi-lock"
                    :append-icon="showPass ? 'mdi-eye-off' : 'mdi-eye'"
                    @click:append="showPass = !showPass"
                    v-model="model.password"
                    :rules="[rules.required]"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-container>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-btn color="primary" type="submit" form="form" block>
                Iniciar sesión
                <v-icon class="ml-2">mdi-login</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="12" align="center">
              <p class="mt-4 font-weight-light">¿Olvidaste tu contraseña?</p>
              <v-btn text @click="$router.push({ name: 'forgotPassword' })">
                Recuperar contraseña
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
        <p class="text-center font-weight-thin mt-2">Desarrollado por ByTech</p>
      </base-card>
    </v-col>
  </v-row>
</template>

<script>
import authMixin from "../mixins/authMixin";
import rules from "../mixins/rules";
import axios from "axios";

export default {
  mixins: [authMixin, rules],
  data() {
    return {
      model: {
        username: null,
        password: null,
      },
      loading: false,
      validForm: false,
      showPass: false,
    };
  },
  created() {
    if (this.isLoggedIn()) {
      this.$router.push({name: "home"});
    }
  },
  methods: {
    login() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        axios
            .create()
            .post("auth/login", this.model)
            .then((resp) => {
              this.$store.dispatch("login", resp.data);
              if (this.$route.query && this.$route.query.redirect)
                this.$router.replace({path: this.$route.query.redirect.toString()});
              else
                this.$router.replace({name: "home"});
            })
            .catch((error) => {
              window.console.error(error);
              if (error.response) {
                let status = error.response.status;
                if (status === 400)
                  this.$toast.error("Existen errores de validación");
                if (status === 401) this.$toast.error("Credenciales no válidas");
              } else {
                this.$toast.error("Verifique su conexión a internet");
              }
            })
            .finally(() => {
              this.loading = false;
            });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
