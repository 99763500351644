import { LOGIN } from '../constants';
import jwt_decode from "jwt-decode";


export default {
  methods: {
    getUserData() {
      const login = JSON.parse(localStorage.getItem(LOGIN));
      const decoded = jwt_decode(login.token);

      return decoded;
    },
    isLoggedIn() {
      return !!localStorage.getItem(LOGIN);
    },
    userHasRole(role) {
      const login = JSON.parse(localStorage.getItem(LOGIN));
      
      if (!login) return false;
      
      const user = jwt_decode(login.token);

      return user.roles.includes(role);
    },
    userHasAnyRole(roles) {
      let resp = false;

      for (let i = 0; i < roles.length; i++) {
        resp = this.userHasRole(roles[i]);
        if (resp == true) return true;
      }

      return false;
    },
  },
};
