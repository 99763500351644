<template>
  <v-textarea
      v-bind="$attrs"
      v-on="$listeners"
      validate-on-blur
      outlined
      aria-autocomplete="off"
      dense
      :clearable="!$attrs.readonly"
      autocomplete="off"
      hide-details="auto"
  ></v-textarea>
</template>

<script>
export default {
  props: {}
};
</script>

<style lang="scss" scoped>
</style>
