<template>
  <div>
    <base-form-card
      :loading="loading"
      :readMode="readMode"
      :editable="isEditable"
      :error="error"
      :alert="alert"
      :create-btn-enabled="false"
      :delete-btn-enabled="false"
      @onEdit="readMode = false"
      @onDelete="remove()"
      @onRefresh="getDataFromApi()"
    >
      <v-form
        ref="form"
        v-model="isValidForm"
        id="myForm"
        @submit.prevent="save()"
      >
        <base-section-card
          :title="`Solicitud de Crédito #${model.id}`"
          class="mb-4"
        >
          <v-row>
            <v-col cols="12" sm="6">
              <base-text-field
                label="Nombre del local"
                v-model="model.localName"
                :rules="[rules.required]"
                :readonly="true"
              >
              </base-text-field>
            </v-col>

            <v-col cols="12" sm="6">
              <base-text-field
                label="Nombre del Propietario"
                v-model="model.ownerName"
                :readonly="true"
              >
              </base-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <base-autocomplete
                label="Entidad Financiera"
                item-text="name"
                item-value="id"
                v-model="model.financialId"
                :readonly="true"
                endpoint="providers"
              ></base-autocomplete>
            </v-col>
            <v-col cols="12" sm="6">
              <base-autocomplete
                label="Estado de la Solicitud"
                item-text="name"
                item-value="id"
                v-model="model.creditStatusId"
                :readonly="readMode"
                :rules="[rules.required]"
                endpoint="creditRequest/CreditStatus"
              ></base-autocomplete>
            </v-col>

            <v-col cols="12" sm="6">
              <base-autocomplete
                label="Tipo de Solicitud"
                item-text="name"
                item-value="id"
                v-model="model.creditTypeId"
                :readonly="readMode"
                :rules="[rules.required]"
                endpoint="creditRequest/TypesCredits"
              ></base-autocomplete>
            </v-col>
            <template v-if="model.creditTypeId && model.creditTypeId == 2">
              <v-col cols="12" sm="4">
                <base-autocomplete
                  label="Articulo"
                  item-text="name"
                  item-value="id"
                  v-model="model.productId"
                  :readonly="readMode"
                  endpoint="products"
                ></base-autocomplete>
              </v-col>
              <v-col cols="12" sm="2">
                <base-text-field
                  label="Código"
                  v-model="model.productCode"
                  :readonly="true"
                  type="number"
                ></base-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <base-autocomplete
                  label="Proveedor"
                  item-text="name"
                  item-value="id"
                  v-model="model.providerId"
                  :readonly="readMode"
                  :rules="[rules.required]"
                  endpoint="providers/common-providers"
                ></base-autocomplete>
              </v-col>
            </template>
            <v-col cols="12" sm="6">
              <base-currency-input
                :greaterThanZero="true"
                label="Monto del Crédito (Gs.)"
                v-model="model.creditAmount"
                :required="true"
              >
              </base-currency-input>
            </v-col>
            <v-col cols="12" sm="6">
              <base-currency-input
                :greaterThanZero="true"
                label="Monto de cuota (Gs.)"
                v-model="model.feeAmount"
                :required="true"
              >
              </base-currency-input>
            </v-col>
            <v-col cols="12" sm="6">
              <base-text-field
                label="Nro. de Cuotas"
                v-model="model.numberOfQuotas"
                :rules="[rules.required]"
                :readonly="readMode"
                type="number"
              >
              </base-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <base-autocomplete
                label="Frecuencia de Pago"
                item-text="name"
                item-value="id"
                v-model="model.paymentFrequencyId"
                :readonly="readMode"
                :rules="[rules.required]"
                endpoint="paymentFrecuencies"
              ></base-autocomplete>
            </v-col>
            <v-col cols="12" sm="6">
              <base-autocomplete
                v-if="model.paymentFrequencyId && model.paymentFrequencyId != 3"
                label="Día de vencimiento de la cuota"
                item-text="name"
                item-value="id"
                v-model="model.quotaExpirationDay"
                endpoint="creditRequest/weekdays?excludeDay=Domingo"
                :rules="[rules.required]"
              ></base-autocomplete>
              <base-text-field
                v-else
                label="Día de vencimiento de la cuota"
                v-model="model.quotaExpirationDay"
                :rules="[rules.required]"
                :readonly="readMode"
                :error-messages="modelErrors['quotaExpirationDay']"
                type="number"
              >
              </base-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <base-date-field
                label="Fecha de inicio para cobro"
                v-model="model.collectionDateStart"
                hint="dd/mm/aaaa"
                :readonly="readMode"
                :error-messages="modelErrors['collectionDateStart']"
              ></base-date-field>
            </v-col>
            <v-col cols="12" sm="6">
              <base-text-field
                label="Nro de crédito de la IF"
                v-model="model.ifCreditId"
                :readonly="readMode"
                type="number"
                :error-messages="modelErrors['IfCreditId']"
              >
              </base-text-field>
            </v-col>
            <v-col
              ol
              cols="12"
              md="12"
              v-if="model.creditStatusId === 2 || model.creditStatusId === 4"
            >
              <base-textarea
                solo
                v-model="model.description"
                name="input-7-4"
                label="comentarios"
                :rules="[rules.required]"
              ></base-textarea>
            </v-col>
          </v-row>
        </base-section-card>
      </v-form>
      <v-row>
        <v-col cols="12" sm="12">
          <file-uploader
            v-if="model.pointOfSaleId"
            title="Documentos"
            :endpoint="filesEndpoint"
            :read-mode="readMode"
            class="mb-4"
          ></file-uploader>
        </v-col>
      </v-row>
      <base-section-card
        title="Detalle de Cuotas"
        v-if="model.creditQuotas && model.creditQuotas.length > 0"
      >
        <v-data-table
          :headers="quotaTableHeader"
          :items="model.creditQuotas"
          fixed-header
          class="elevation-0"
          disable-sort
          disable-pagination
          hide-default-footer
          height="300px"
        >
          <template v-slot:item.amount="{ item }">
            {{ toCurrency(item.amount) }}
          </template>
          <template v-slot:item.expirationDate="{ item }">
            {{ toDateString(item.expirationDate) }}
          </template>
          <template v-slot:item.dueAmount="{ item }">
            {{ item.dueAmount == 0 ? "Pagado" : "Pendiente" }}
          </template>
        </v-data-table>
      </base-section-card>
    </base-form-card>
  </div>
</template>

<script>
import formMixin from "../mixins/formMixin";
import formatters from "../mixins/formatters";
import FileUploader from "../components/app/FileUploader";
import router from "../router";

export default {
  name: "CreditRequestForm",
  components: { FileUploader },
  mixins: [formMixin, formatters],

  data() {
    return {
      endpoint: "creditRequest",
      quotaTableHeader: [
        { text: "Nro. Cuota", value: "quotaNumber" },
        { text: "Monto", value: "amount" },
        { text: "Vencimiento", value: "expirationDate" },
        { text: "Estado", value: "dueAmount" },
      ],
    };
  },
  methods: {
    customValidation() {
      let isValid = true;
      this.modelErrors = {};

      if (
        this.model.quotaExpirationDay &&
        (+this.model.quotaExpirationDay < 1 ||
          +this.model.quotaExpirationDay > 30)
      ) {
        this.modelErrors["quotaExpirationDay"] = [
          `Debe ingresar un valor entre 1 y 30`,
        ];
        isValid = false;
      } else {
        this.modelErrors["quotaExpirationDay"] = [];
      }

      if (!this.model.collectionDateStart && this.model.creditStatusId == 5) {
        this.modelErrors["collectionDateStart"] = [`Este campo es requerido`];
        isValid = false;
      } else {
        this.modelErrors["collectionDateStart"] = [];
      }

      if (!this.model.ifCreditId && this.model.creditStatusId == 5) {
        this.modelErrors["IfCreditId"] = [`Este campo es requerido`];
        isValid = false;
      } else {
        this.modelErrors["IfCreditId"] = [];
      }

      return isValid;
    },
    afterSave() {
      if (
        this.model.creditStatusId == 5 &&
        this.model.creditQuotas.length == 0
      ) {
        this.error = false;
        this.loading = true;
        this.$http
          .get(`${this.endpoint}/${this.id}`)
          .then((resp) => {
            this.model = resp.data;
            this.isEditable = false;
            this.$toast.success("Cuotas generadas con éxito");
          })
          .catch((error) => {
            window.console.error(error);
            if (!error.response) {
              this.error = true;
            }
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        router.push(this.parentPath);
        this.$toast.success("Registro actualizado con éxito");
      }
    },
  },
  computed: {
    filesEndpoint() {
      if (this.model && this.model.pointOfSaleId)
        return `pointOfSale/${this.model.pointOfSaleId}/documents`;
      else return undefined;
    },
    isEditable() {
      var HasRole =
        this.userHasRole("Administrador") ||
        this.userHasRole("VC") ||
        this.userHasRole("Gestor_Administrativo");

      if (this.model.creditStatusId == 5 || this.model.creditStatusId == 6)
        return false;

      return HasRole;
    },
  },
  watch: {
    "model.productId"() {
      if (this.model && this.model.productId) {
        this.$http
          .get(`products/${this.model.productId}`)
          .then((resp) => {
            this.model.providerId = resp.data.providerId;
            this.model.productCode = resp.data.productCode;
          })
          .catch((error) => {
            this.error = true;
            window.console.error(error);
            this.$emit("error");
          })
          .finally(() => {});
      } else {
        this.model.providerId = null;
        this.model.productCode = null;
      }
    },
    "model.paymentFrequencyId"(newVal, oldVal) {
      if (oldVal) this.model.quotaExpirationDay = null;
    },
    "creditRequest.creditTypeId"() {
      this.model.productId = null;
      this.model.productCode = null;
      this.model.providerId = null;
    },
  },
};
</script>

<style scoped>
</style>