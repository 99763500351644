<template>
  <section>
    <v-card outlined v-bind="$attrs" v-on="$listeners">
      <div class="pa-4">
        <v-card-title class="outlined">
          {{ title }}
          <v-divider vertical inset class="mx-4"></v-divider>
          <slot name="actions"></slot>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <slot></slot>
        </v-card-text>
      </div>
    </v-card>
  </section>
</template>

<script>
export default {
  props: ["title"],
};
</script>

<style>
</style>