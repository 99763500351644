var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('base-pagination-table',{attrs:{"filter":_vm.filters.filter,"headers":_vm.headers,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.totalItems,"loading":_vm.loading,"error":_vm.error,"items-per-page":_vm.itemsPerPage,"downloading":_vm.downloading,"filter-count":_vm.filterCount,"disable-filter":true},on:{"update:filter":[function($event){return _vm.$set(_vm.filters, "filter", $event)},function($event){return _vm.timeOut()}],"update:options":function($event){_vm.options=$event},"refresh":function($event){return _vm.getItems()},"clear":function($event){return _vm.clear()}},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"ma-2",attrs:{"text":"","outlined":"","icon":""},on:{"click":function($event){return _vm.downloadReport(item.id)}}},[_c('v-icon',[_vm._v("mdi-download")])],1)]}},{key:"item.walletDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.toDateTimeString(item.walletDate))+" ")]}},{key:"item.walletClosedDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.toDateTimeString(item.walletClosedDate))+" ")]}},{key:"item.totalAmountCharged",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.toCurrency(item.totalAmountCharged))+" ")]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }