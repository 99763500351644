import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import axios from 'axios';
import moment from "moment";
import BaseDialog from "./components/base/BaseDialog.vue"
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import BasePaginationTable from './components/base/BasePaginationTable.vue'
import BaseCard from './components/base/BaseCard.vue'
import BaseFormCard from './components/base/BaseFormCard.vue'
import BaseTextField from './components/base/BaseTextField.vue'
import BaseAutoComplete from './components/base/BaseAutoComplete.vue'
import BaseDateField from './components/base/BaseDateField.vue'
import BaseSectionCard from './components/base/BaseSectionCard.vue'
import BaseImageField from './components/base/BaseImageField.vue'
import BaseSelect from './components/base/BaseSelect.vue'
import BaseTextArea from './components/base/BaseTextArea.vue'
import BaseCurrencyInput from "./components/base/BaseCurrencyInput";
import BaseCombobox from "./components/base/BaseCombobox.vue";

Vue.config.productionTip = false
Vue.prototype.$http = axios;
Vue.prototype.$moment = moment;
Vue.prototype.$moment.locale('es');
axios.defaults.baseURL = process.env.VUE_APP_ROOT_API;
Vue.use(Toast, {});

// componentes globales
Vue.component('base-dialog', BaseDialog);
Vue.component('base-pagination-table', BasePaginationTable);
Vue.component('base-card', BaseCard);
Vue.component('base-form-card', BaseFormCard);
Vue.component('base-text-field', BaseTextField);
Vue.component('base-autocomplete', BaseAutoComplete);
Vue.component('base-date-field', BaseDateField);
Vue.component('base-section-card', BaseSectionCard);
Vue.component('base-image-field', BaseImageField);
Vue.component('base-select', BaseSelect);
Vue.component('base-textarea', BaseTextArea);
Vue.component('base-currency-input', BaseCurrencyInput);
Vue.component('base-combobox', BaseCombobox);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
