<template>
  <base-card
      outlined
      :loading="loading"
      :disabled="loading"
  >
    <v-card-title>Cambio de contraseña</v-card-title>
    <v-container>
      <v-alert text v-if="showRequirements">
        Recuerda que tu contraseña debe contener al menos:
        <ul>
          <li>Seis caracteres.</li>
          <li>Una letra mayúscula.</li>
          <li>Un número.</li>
          <li>Un caracter especial. Ej: <code>.,#?!@$-</code></li>
        </ul>
      </v-alert>
      <v-alert v-if="alert.show" text :type="alert.type" class="mt-4">
        {{ alert.message }}
      </v-alert>
      <v-form
          @submit.prevent="submit"
          id="form"
          ref="form"
          v-model="validForm"
      >
        <v-row>
          <v-col cols="12">
            <base-text-field
                label="Contraseña Actual"
                hint="Ingresa tu contraseña actual"
                v-model="model.currentPassword"
                :rules="[rules.required]"
                prepend-inner-icon="mdi-lock"
                :append-icon="showPass ? 'mdi-eye-off' : 'mdi-eye'"
                @click:append="showPass = !showPass"
                :type="showPass ? 'text' : 'password'"
                :error-messages="modelErrors['CurrentPassword']"
            />
          </v-col>
          <v-col cols="12">
            <base-text-field
                label="Nueva Contraseña"
                hint="Ingresa una contraseña segura"
                v-model="model.newPassword"
                :rules="[rules.required, rules.password.atLeastOneLowerCase, rules.password.atLestOneUpperCase,
                  rules.password.atLeastOneDigit, rules.password.atLeastOneSpecialChar, rules.minLength(6)]"
                prepend-inner-icon="mdi-lock"
                :append-icon="showPass1 ? 'mdi-eye-off' : 'mdi-eye'"
                @click:append="showPass1 = !showPass1"
                :type="showPass1 ? 'text' : 'password'"
                :error-messages="modelErrors['NewPassword']"
            />
          </v-col>
          <v-col cols="12">
            <base-text-field
                label="Confirmar Contraseña"
                hint="Confirma tu nueva contraseña"
                v-model="model.confirmPassword"
                :rules="[rules.required,() => model.newPassword === model.confirmPassword ||'No coincide con la nueva contraseña']"
                prepend-inner-icon="mdi-lock"
                :append-icon="showPass2 ? 'mdi-eye-off' : 'mdi-eye'"
                @click:append="showPass2 = !showPass2"
                :type="showPass2 ? 'text' : 'password'"
            />
          </v-col>
        </v-row>
      </v-form>
    </v-container>
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-btn class="primary" type="submit" form="form">
            Confirmar
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </base-card>
</template>

<script>
import rules from "../mixins/rules";
import authMixin from "../mixins/authMixin";

export default {
  mixins: [rules, authMixin],
  data() {
    return {
      validForm: false,
      loading: false,
      showPass: false,
      showPass1: false,
      showPass2: false,
      showRequirements: true,
      modelErrors: [],
      model: {
        currentPassword: null,
        newPassword: null,
        confirmPassword: null,
      },
      alert: {
        show: false,
        type: null,
        message: null,
      },
    };
  },
  methods: {
    submit() {
      if (!this.$refs.form.validate()) return;
      this.loading = true;
      this.alert.show = false;
      const userId = this.getUserData().nameid;
      this.$http
          .post(`account/${userId}/change-password`, this.model)
          .then((resp) => {
            this.$toast.success(`Tu contraseña ha sido modificada`);
            // guardamos el nuevo login
            this.$store.dispatch("login", resp.data);
            this.$router.push({name: "home"});
          })
          .catch((error) => {
            window.console.error(error);
            if (error.response) {
              const status = error.response.status;
              if (status == 400) {
                this.modelErrors = error.response.data.modelErrors;
                this.$toast.error("El formulario contiene errores");
              } else {
                this.$toast.error("Ocurrió un error inesperado");
              }
            } else {
              this.$toast.error("Verifique su conexión a internet");
            }
          })
          .finally(() => (this.loading = false));
    },
  },
};
</script>

<style>
</style>
