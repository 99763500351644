<template>
  <div>
    <base-form-card
      :loading="loading"
      :readMode="readMode"
      :editable="editable"
      :error="error"
      :alert="alert"
      :submit-btn-enabled="false"
      @onEdit="readMode = false"
      @onDelete="remove()"
      @onRefresh="getDataFromApi()"
    >
      <v-stepper v-model="step">
        <v-stepper-header>
          <v-stepper-step :complete="step > 1" step="1">
            Datos del PDV
          </v-stepper-step>
          <v-divider></v-divider>
          <template v-if="visit.interestOnCredit">
            <v-stepper-step :complete="step > 2" step="2">
              Adjuntar Documentos
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step :complete="step > 3" step="3">
              Solicitar Crédito
            </v-stepper-step>
            <v-divider></v-divider>
          </template>
          <v-stepper-step :complete="step > 4" :step="quotaPaymentStep">
            Registrar Pago
          </v-stepper-step>
        </v-stepper-header>
        <v-stepper-items>
          <v-stepper-content step="1">
            <v-form
              ref="form1"
              v-model="isValidForm"
              id="myForm"
              @submit.prevent="savePdv()"
            >
              <base-section-card title="Datos del PDV" class="mb-4">
                <v-row>
                  <v-col cols="12" sm="6">
                    <base-text-field
                      label="Nombre del local"
                      v-model="pointOfSale.localName"
                      :readonly="true"
                    >
                    </base-text-field>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <base-text-field
                      label="Nombre del Propietario"
                      v-model="pointOfSale.ownerName"
                      :readonly="readMode"
                    >
                    </base-text-field>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <base-text-field
                      label="Antigüedad (años)"
                      v-model="pointOfSale.age"
                      :required="true"
                    >
                    </base-text-field>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <base-text-field
                      label="Teléfono"
                      v-model="pointOfSale.phoneNumber"
                      prepend-inner-icon="mdi-cellphone"
                      :rules="[rules.required, rules.phoneNumber]"
                      :readonly="readMode"
                      :append-icon="validWhatsappNumber ? 'mdi-whatsapp' : ''"
                      @click:append="sendWhatsapp()"
                    ></base-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <base-text-field
                      label="Email"
                      type="email"
                      v-model="pointOfSale.email"
                      prepend-inner-icon="mdi-email"
                      :rules="[rules.email]"
                      :readonly="readMode"
                    >
                    </base-text-field>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <base-select
                      label="Tipo de PDV"
                      item-text="name"
                      item-value="id"
                      v-model="pointOfSale.pointOfSaleTypeId"
                      :readonly="readMode"
                      endpoint="pointOfSaleTypes"
                    ></base-select>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <base-select
                      label="Tamaño de PDV"
                      item-text="name"
                      item-value="id"
                      v-model="pointOfSale.pointOfSaleSizeId"
                      :readonly="readMode"
                      endpoint="pointSaleSizes"
                    ></base-select>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <base-select
                      label="Red de cobro"
                      item-text="name"
                      item-value="id"
                      v-model="pointOfSale.paymentNetworkId"
                      :readonly="readMode"
                      endpoint="paymentNetworks"
                    ></base-select>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <base-text-field
                      label="Dirección"
                      v-model="pointOfSale.address"
                      :readonly="readMode"
                    >
                    </base-text-field>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <base-text-field
                      label="Referencia"
                      v-model="pointOfSale.addressReference"
                      :readonly="readMode"
                    >
                    </base-text-field>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <base-autocomplete
                      label="Ciudad"
                      item-text="name"
                      item-value="id"
                      v-model="pointOfSale.cityId"
                      :readonly="readMode"
                      endpoint="departments/city"
                      :rules="[rules.required]"
                    ></base-autocomplete>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <base-text-field
                      label="Barrio"
                      v-model="pointOfSale.neighborhood"
                      :readonly="readMode"
                    >
                    </base-text-field>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <equipments-table
                      v-model="pointOfSale.equipments"
                    ></equipments-table>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <base-text-field
                      label="Cantidad de góndolas"
                      v-model="pointOfSale.numberOfGondolas"
                      :required="true"
                    >
                    </base-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-col cols="12" sm="12">
                      <v-checkbox
                        v-model="pointOfSale.hasWifi"
                        label="¿El local cuanta con wifi?"
                        :readonly="readMode"
                        outlined
                        validate-on-blur
                        dense
                        hide-details="auto"
                      ></v-checkbox>
                    </v-col>

                    <v-col cols="12" sm="12">
                      <v-checkbox
                        v-model="pointOfSale.recharges"
                        label="¿Realizan recargas de saldo?"
                        :readonly="readMode"
                        outlined
                        validate-on-blur
                        dense
                        hide-details="auto"
                      ></v-checkbox>
                    </v-col>

                    <v-col cols="12" sm="12">
                      <v-checkbox
                        v-model="pointOfSale.computer"
                        label="¿PC / Notebook / Tablet?"
                        :readonly="readMode"
                        outlined
                        validate-on-blur
                        dense
                        hide-details="auto"
                      ></v-checkbox>
                    </v-col>

                    <v-col cols="12" sm="12">
                      <v-checkbox
                        v-model="pointOfSale.posTC"
                        label="¿El local cuenta con POS?"
                        :readonly="readMode"
                        outlined
                        validate-on-blur
                        dense
                        hide-details="auto"
                      ></v-checkbox>
                    </v-col>

                    <v-col cols="12" sm="12">
                      <v-checkbox
                        v-model="pointOfSale.smartDevices"
                        label="¿El local cuenta con teléfono inteligente?"
                        :readonly="readMode"
                        outlined
                        validate-on-blur
                        dense
                        hide-details="auto"
                      ></v-checkbox>
                    </v-col>

                    <v-col cols="12" sm="12">
                      <v-checkbox
                        v-model="pointOfSale.purchaseFinancedBalance"
                        label="¿Realizan compra de saldo financiado?"
                        :readonly="readMode"
                        outlined
                        validate-on-blur
                        dense
                        hide-details="auto"
                      ></v-checkbox>
                    </v-col>
                  </v-col>

                  <template v-if="!invalidCity">
                    <v-col cols="12" sm="12">
                      <v-checkbox
                        v-model="visit.interestOnCredit"
                        label="¿Está interesado en solicitar un crédito?"
                        :readonly="readMode"
                        outlined
                        validate-on-blur
                        dense
                        hide-details="auto"
                      ></v-checkbox>
                    </v-col>

                    <v-col cols="12" sm="12" v-if="!visit.interestOnCredit">
                      <base-text-field
                        label="Motivo por el cual no le interesa el crédito"
                        :rules="[rules.required]"
                        v-model="visit.description"
                      >
                      </base-text-field>
                    </v-col>
                  </template>
                </v-row>
              </base-section-card>
              <v-card-actions class="mt-4">
                <v-btn color="primary" @click="savePdv()">Siguiente</v-btn>
              </v-card-actions>
            </v-form>
          </v-stepper-content>
          <template v-if="visit.interestOnCredit">
            <v-stepper-content step="2">
              <file-uploader
                v-if="pointOfSale.id"
                :endpoint="filesEndpoint"
                title="Documentos"
              ></file-uploader>
              <v-card-actions class="mt-4">
                <v-btn color="primary" @click="step = 3"> Siguiente </v-btn>
              </v-card-actions>
            </v-stepper-content>
            <v-stepper-content step="3">
              <v-form
                ref="form2"
                v-model="isValidForm"
                id="myForm2"
                @submit.prevent="beforeSaveCreditRequest()"
              >
                <base-section-card title="Solicitud de Crédito" class="mb-4">
                  <v-row>
                    <v-col cols="12" sm="6">
                      <base-autocomplete
                        label="Tipo de Solicitud"
                        item-text="name"
                        item-value="id"
                        v-model="creditRequest.creditTypeId"
                        :readonly="readMode"
                        :rules="[rules.required]"
                        endpoint="creditRequest/TypesCredits"
                      ></base-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row>
                    <template
                      v-if="
                        creditRequest.creditTypeId &&
                        creditRequest.creditTypeId == 2
                      "
                    >
                      <v-col cols="12" sm="4">
                        <base-autocomplete
                          label="Articulo"
                          item-text="name"
                          item-value="id"
                          v-model="creditRequest.productId"
                          :readonly="readMode"
                          endpoint="products"
                        ></base-autocomplete>
                      </v-col>
                      <v-col cols="12" sm="2">
                        <base-text-field
                          label="Código"
                          v-model="creditRequest.productCode"
                          :readonly="true"
                          type="number"
                        ></base-text-field>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <base-autocomplete
                          label="Proveedor"
                          item-text="name"
                          item-value="id"
                          v-model="creditRequest.providerId"
                          :readonly="readMode"
                          :rules="[rules.required]"
                          endpoint="providers/common-providers"
                        ></base-autocomplete>
                      </v-col>
                    </template>
                    <v-col cols="12" sm="6">
                      <base-currency-input
                        :greaterThanZero="true"
                        label="Monto del Crédito (Gs.)"
                        v-model="creditRequest.creditAmount"
                        :required="true"
                      >
                      </base-currency-input>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <base-currency-input
                        :greaterThanZero="true"
                        label="Monto de cuota (Gs.)"
                        v-model="creditRequest.feeAmount"
                        :required="true"
                      >
                      </base-currency-input>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <base-text-field
                        label="Nro. de Cuotas"
                        v-model="creditRequest.numberOfQuotas"
                        :rules="[rules.required]"
                        :readonly="readMode"
                        type="number"
                      >
                      </base-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <base-autocomplete
                        label="Frecuencia de Pago"
                        item-text="name"
                        item-value="id"
                        v-model="creditRequest.paymentFrequencyId"
                        :readonly="readMode"
                        :rules="[rules.required]"
                        endpoint="paymentFrecuencies"
                      ></base-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <base-autocomplete
                        v-if="
                          creditRequest.paymentFrequencyId &&
                          creditRequest.paymentFrequencyId != 3
                        "
                        label="Día de vencimiento de la cuota"
                        item-text="name"
                        item-value="id"
                        v-model="creditRequest.quotaExpirationDay"
                        endpoint="creditRequest/weekdays?excludeDay=Domingo"
                        :rules="[rules.required]"
                      ></base-autocomplete>
                      <base-text-field
                        v-else
                        label="Día de vencimiento de la cuota"
                        v-model="creditRequest.quotaExpirationDay"
                        :rules="[rules.required]"
                        :readonly="readMode"
                        :error-messages="modelErrors['quotaExpirationDay']"
                        type="number"
                      >
                      </base-text-field>
                    </v-col>
                  </v-row>
                </base-section-card>
                <v-card-actions class="mt-4">
                  <v-btn color="primary" @click="beforeSaveCreditRequest()">
                    Siguiente
                  </v-btn>
                </v-card-actions>
              </v-form>
            </v-stepper-content>
          </template>
          <v-stepper-content :step="quotaPaymentStep">
            <base-section-card title="Detalle del Cobro" class="text-justify">
              <v-form
                ref="form4"
                v-model="isValidForm"
                id="myForm4"
                @submit.prevent="beforeSaveQuotaPayment()"
              >
                <v-row>
                  <v-col cols="12">
                    <v-btn outlined text @click="showQuotaPending = true">
                      <v-icon class="mr-1">mdi-archive-search</v-icon>
                      Cuotas pendientes
                    </v-btn>
                  </v-col>
                  <v-col cols="12">
                    <base-text-field
                      label="Nro. de Cuotas a Cobrar"
                      type="number"
                      v-model="quotaPaymentCount"
                      :error-messages="modelErrors['quotaPaymentCount']"
                      :rules="[rules.required]"
                    />
                  </v-col>
                  <v-col cols="12">
                    <base-currency-input
                      :greaterThanZero="true"
                      :readonly="true"
                      label="Total a Cobrar"
                      v-model="quotaPaymentTotal"
                    />
                  </v-col>
                </v-row>
                <v-data-table
                  :headers="quotasPendingHeader"
                  :items="quotaPayments"
                  fixed-header
                  class="elevation-0"
                  disable-sort
                  disable-pagination
                  hide-default-footer
                >
                  <template v-slot:item.amount="{ item }">
                    {{ toCurrency(item.amount) }}
                  </template>
                  <template v-slot:item.expirationDate="{ item }">
                    {{ toDateString(item.expirationDate) }}
                  </template>
                  <template v-slot:item.paymentAmount="{ item, index }">
                    <base-currency-input
                      :required="true"
                      :greaterThanZero="true"
                      :id="item.id.toString()"
                      v-model="item.paymentAmount"
                      :error-messages="modelErrors[`quotaPayments[${index}]`]"
                    />
                  </template>
                </v-data-table>
                <v-card-actions class="mt-4">
                  <v-btn color="primary" @click="beforeSaveQuotaPayment()"
                    >Confirmar</v-btn
                  >
                </v-card-actions>
              </v-form>
            </base-section-card>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </base-form-card>
    <v-dialog v-model="showOtpDialog" persistent width="450">
      <base-card :loading="sendingOtp" :disabled="sendingOtp">
        <v-card-title> Confirmar Solicitud </v-card-title>
        <v-form
          ref="form3"
          v-model="isValidForm"
          id="myForm3"
          @submit.prevent="validateOtpCode()"
        >
          <v-card-text>
            Resumen de la solicitud:
            <ul>
              <li>
                Monto del Crédito:
                <b>Gs. {{ currencyFormatter(creditRequest.creditAmount) }}</b>
              </li>
              <li>
                Cantidad de Cuotas: <b>{{ creditRequest.numberOfQuotas }}</b>
              </li>
            </ul>
            <br />
            Ingresa el PIN que hemos enviado a
            {{ this.pointOfSale.phoneNumber }}
            <v-row dense>
              <v-col cols="6">
                <base-text-field
                  type="number"
                  v-model="otpCode"
                  label="PIN"
                  :rules="[rules.required]"
                ></base-text-field>
              </v-col>
              <v-col cols="6">
                <v-btn color="primary" @click="validateOtpCode"
                  >Confirmar</v-btn
                >
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions>
            <v-btn outlined @click="reSendOtp">Reenviar PIN</v-btn>
            <v-btn outlined @click="showOtpDialog = false">Cancelar</v-btn>
          </v-card-actions>
        </v-form>
      </base-card>
    </v-dialog>
    <v-dialog v-model="showOtpDialog2" persistent width="450">
      <base-card :loading="sendingOtp2" :disabled="sendingOtp2">
        <v-card-title> Confirmar Pago </v-card-title>
        <v-form
          ref="form5"
          v-model="isValidForm"
          id="myForm5"
          @submit.prevent="validateOtpCodeForCollection()"
        >
          <v-card-text>
            Resumen del Pago:
            <ul>
              <li>
                Cuotas a Cobrar: <b>{{ quotaPaymentCount }}</b>
              </li>
              <li>
                Monto a Cobrar:
                <b>Gs. {{ currencyFormatter(this.quotaPaymentTotal) }}</b>
              </li>
            </ul>
            <br />
            Ingresa el PIN que hemos enviado a
            {{ this.pointOfSale.phoneNumber }}
            <v-row dense>
              <v-col cols="6">
                <base-text-field
                  type="number"
                  v-model="otpCode"
                  label="PIN"
                  :rules="[rules.required]"
                ></base-text-field>
              </v-col>
              <v-col cols="6">
                <v-btn color="primary" @click="validateOtpCodeForCollection"
                  >Confirmar</v-btn
                >
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions>
            <v-btn outlined @click="reSendOtpForCollection">Reenviar PIN</v-btn>
            <v-btn outlined @click="showOtpDialog2 = false">Cancelar</v-btn>
          </v-card-actions>
        </v-form>
      </base-card>
    </v-dialog>
    <v-dialog v-model="showQuotaPending" persistent width="450">
      <base-section-card title="Cuotas Pendientes">
        <v-data-table
          :headers="quotasPendingHeader"
          :items="quotasPending"
          fixed-header
          class="elevation-0"
          disable-sort
          disable-pagination
          hide-default-footer
          height="200px"
          dense
        >
          <template v-slot:item.amount="{ item }">
            {{ toCurrency(item.amount) }}
          </template>
          <template v-slot:item.expirationDate="{ item }">
            {{ toDateString(item.expirationDate) }}
          </template>
          <template v-slot:item.dueAmount="{ item }">
            {{ item.dueAmount == 0 ? "Pagado" : "Pendiente" }}
          </template>
        </v-data-table>
        <v-card-actions>
          <v-btn text outlined @click="showQuotaPending = false">Cerrar</v-btn>
        </v-card-actions>
      </base-section-card>
    </v-dialog>
  </div>
</template>

<script>
import formMixin from "../mixins/formMixin";
import FileUploader from "../components/app/FileUploader";
import EquipmentsTable from "../components/app/EquipmentsTable";
import authMixin from "../mixins/authMixin";

export default {
  name: "QuotaPaymentRegisterForm",
  props: {
    scheduleVisitId: {
      type: Number,
      required: false,
    },
  },
  components: { EquipmentsTable, FileUploader },
  mixins: [formMixin, authMixin],
  data() {
    return {
      endpoint: "",
      step: 1,
      scheduleVisit: {},
      pointOfSale: {},
      selectedPointOfSale: null,
      pointOfSales: [],
      quotasPending: [],
      quotaPayments: [],
      quotaPaymentCount: null,
      quotasPendingHeader: [
        { text: "Nro. Cuota", value: "quotaNumber" },
        { text: "Monto", value: "amount" },
        { text: "Vencimiento", value: "expirationDate" },
        { text: "Importe", value: "paymentAmount" },
      ],
      visit: { interestOnCredit: false },
      creditRequest: {
        providerId: null,
        productCode: null,
        productId: null,
        quotaExpirationDay: null,
        paymentFrequencyId: null,
        feeAmount: null,
        numberOfQuotas: null,
        creditTypeId: null,
        offerVisitId: null,
      },
      showOtpDialog: false,
      showOtpDialog2: false,
      showQuotaPending: false,
      otpCode: null,
      otpId: null,
      sendingOtp: false,
      sendingOtp2: false,
      invalidCity: false,
    };
  },
  computed: {
    filesEndpoint() {
      if (this.pointOfSale && this.pointOfSale.id)
        return `pointOfSale/${this.pointOfSale.id}/documents`;
      else return undefined;
    },
    quotaPaymentStep() {
      return this.visit.interestOnCredit ? 4 : 2;
    },
    quotaPaymentTotal() {
      return this.quotaPayments.reduce(
        (accumulator, item) => accumulator + item.paymentAmount,
        0
      );
    },
  },
  async mounted() {
    this.getLocation();
    this.validateLimit();
    if (this.scheduleVisitId) {
      await this.getScheduleVisit();
      await this.getPointOfSaleId();
      await this.getPointOfSale();
      await this.getPendingQuotas();
    }
  },
  methods: {
    validateLimit() {
      this.$http
        .get(`Wallets/CheckLimit`)
        .then(() => {})
        .catch((error) => {
          this.$router.push(this.parentPath);
          window.console.error(error);
          if (!error.response) {
            this.error = true;
            this.$toast.error("Por favor revisa tu conexión");
          } else {
            this.$toast.error(error.response.data);
          }
        })
        .finally(() => {});
    },
    savePdv() {
      if (this.$refs.form1.validate() && this.customValidation()) {
        this.loading = true;
        this.error = false;
        if (!this.pointOfSale.latitude && !this.pointOfSale.longitude) {
          this.pointOfSale.latitude = this.visit.latitude;
          this.pointOfSale.longitude = this.visit.longitude;
        }

        const promise = this.pointOfSale.id
          ? this.$http.put(
              `pointOfSale/${this.pointOfSale.id}`,
              this.pointOfSale
            )
          : this.$http.post("pointOfSale", this.pointOfSale);

        promise
          .then((resp) => {
            if (resp.data && resp.data.id) this.pointOfSale.id = resp.data.id;
            this.visit.pointOfSaleId = this.pointOfSale.id;
            this.saveVisit();
          })
          .catch((error) => {
            window.console.error(error);

            if (!error.response) {
              this.error = true;
              this.$toast.error("Por favor revisa tu conexión");
              return;
            }

            const { status } = error.response;
            if (status === 400) {
              this.modelErrors = error.response.data.modelErrors;
              this.$toast.error("El formulario contiene errores");
            }
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.$toast.error("El formulario contiene errores");
      }
    },
    saveVisit() {
      this.loading = true;
      this.error = false;
      this.visit.responsibleId = this.getUserData().nameid;
      this.visit.dateOfVisit = new Date();
      this.$http
        .post("OfferVisits", this.visit)
        .then(() => {
          this.step = 2;
        })
        .catch((error) => {
          window.console.error(error);
          if (!error.response) {
            this.error = true;
            this.$toast.error("Por favor revisa tu conexión");
          }
        })
        .finally(() => (this.loading = false));
    },
    beforeSaveCreditRequest() {
      if (this.$refs.form2.validate() && this.validateExpirationDay()) {
        this.sendOtp();
      }
    },
    async beforeSaveQuotaPayment() {
      if (
        this.$refs.form4.validate() &&
        this.validateQuotaPaymentCount() &&
        this.validateQuotaPaymentAmounts() &&
        await this.validateCollectionLimit()
      ) {
          this.sendOtpForCollection();
      }
    },
    saveCreditRequest() {
      this.loading = true;
      this.error = false;
      this.$http
        .post("creditRequest", this.creditRequest)
        .then(() => {
          this.$toast.success("Solicitud de Crédito generada con éxito");
          this.step = 4;
        })
        .catch((error) => {
          window.console.error(error);

          if (!error.response) {
            this.error = true;
            this.$toast.error("Por favor revisa tu conexión");
            return;
          }

          const { status } = error.response;
          if (status === 400) {
            this.modelErrors = error.response.data.modelErrors;
            this.$toast.error("El formulario contiene errores");
          }
          this.loading = false;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    validateExpirationDay() {
      if (
        this.creditRequest.quotaExpirationDay &&
        (+this.creditRequest.quotaExpirationDay < 1 ||
          +this.creditRequest.quotaExpirationDay > 30)
      ) {
        this.modelErrors = {
          quotaExpirationDay: [`Debe ingresar un valor entre 1 y 30`],
        };
        return false;
      } else {
        this.modelErrors = {};
        return true;
      }
    },
    validateQuotaPaymentCount() {
      if (
        this.quotaPaymentCount &&
        (+this.quotaPaymentCount < 1 ||
          +this.quotaPaymentCount > this.quotasPending.length)
      ) {
        this.modelErrors = {
          quotaPaymentCount: [
            `Debe ingresar un valor entre 1 y ${this.quotasPending.length}`,
          ],
        };
        return false;
      } else {
        this.modelErrors = {};
        return true;
      }
    },
    validateQuotaPaymentAmounts() {
      let isValid = true;
      if (this.quotaPayments && this.quotaPayments.length > 0) {
        for (let i = 0; i < this.quotaPayments.length; i++) {
          let index = `quotaPayments[${i}]`;
          if (
            +this.quotaPayments[i].paymentAmount > +this.quotaPayments[i].amount
          ) {
            this.modelErrors[index] = [
              `Debe ingresar un valor entre 1 y ${this.quotaPayments[i].amount}`,
            ];
            isValid = false;
          }
        }
      }
      return isValid;
    },
    getLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          this.visit.latitude = position.coords.latitude.toString();
          this.visit.longitude = position.coords.longitude.toString();
        });
      }
    },
    sendOtp() {
      this.loading = true;
      let otpModel = {};
      otpModel.offerVisitId = this.creditRequest.offerVisitId;
      otpModel.phone = this.pointOfSale.phoneNumber;
      this.$http
        .post("OTPCode", otpModel)
        .then((resp) => {
          this.$toast.success("Hemos enviado el PIN con éxito");
          this.showOtpDialog = true;
          this.otpId = resp.data.id;
        })
        .catch((error) => {
          window.console.error(error);
          if (!error.response) {
            this.error = true;
            this.$toast.error("Por favor revisa tu conexión");
          } else {
            this.$toast.error("No hemos podido enviar el PIN");
          }
        })
        .finally(() => (this.loading = false));
    },
    sendOtpForCollection() {
      this.loading = true;
      let otpModel = {};
      otpModel.scheduledVisitId = this.scheduleVisitId;
      otpModel.phone = this.pointOfSale.phoneNumber;
      this.$http
        .post("CollectionOTP", otpModel)
        .then((resp) => {
          this.$toast.success("Hemos enviado el PIN con éxito");
          this.showOtpDialog2 = true;
          this.otpId = resp.data.id;
        })
        .catch((error) => {
          window.console.error(error);
          if (!error.response) {
            this.error = true;
            this.$toast.error("Por favor revisa tu conexión");
          } else {
            this.$toast.error("No hemos podido enviar el PIN");
          }
        })
        .finally(() => (this.loading = false));
    },
    reSendOtp() {
      this.sendingOtp = true;
      let otpModel = {};
      otpModel.offerVisitId = this.creditRequest.offerVisitId;
      otpModel.phone = this.pointOfSale.phoneNumber;
      this.$http
        .put(`OTPCode/${this.otpId}`, otpModel)
        .then((resp) => {
          this.$toast.success("Hemos re enviado el PIN con éxito");
          this.showOtpDialog = true;
          this.otpId = resp.data.id;
        })
        .catch((error) => {
          window.console.error(error);
          if (!error.response) {
            this.error = true;
            this.$toast.error("Por favor revisa tu conexión");
          } else {
            this.$toast.error("No hemos podido enviar el PIN");
          }
        })
        .finally(() => (this.sendingOtp = false));
    },
    reSendOtpForCollection() {
      this.sendingOtp2 = true;
      let otpModel = {};
      otpModel.scheduledVisitId = this.scheduleVisitId;
      otpModel.phone = this.pointOfSale.phoneNumber;
      this.$http
        .put(`CollectionOTP/${this.otpId}`, otpModel)
        .then((resp) => {
          this.$toast.success("Hemos re enviado el PIN con éxito");
          this.showOtpDialog2 = true;
          this.otpId = resp.data.id;
        })
        .catch((error) => {
          window.console.error(error);
          if (!error.response) {
            this.error = true;
            this.$toast.error("Por favor revisa tu conexión");
          } else {
            this.$toast.error("No hemos podido enviar el PIN");
          }
        })
        .finally(() => (this.sendingOtp2 = false));
    },
    validateOtpCode() {
      if (this.$refs.form3.validate()) {
        this.sendingOtp = true;
        this.$http
          .get(`OTPCode/${this.creditRequest.offerVisitId}/otp/${this.otpCode}`)
          .then(() => {
            this.showOtpDialog = false;
            this.saveCreditRequest();
          })
          .catch((error) => {
            window.console.error(error);
            if (!error.response) {
              this.error = true;
              this.$toast.error("Por favor revisa tu conexión");
            } else {
              this.$toast.error("El PIN ingresado no es válido o ha expirado");
            }
          })
          .finally(() => (this.sendingOtp = false));
      }
    },
    async validateCollectionLimit() {
      let isValid = false;
      await this.$http
        .get(`Wallets/CheckLimit/${this.quotaPaymentTotal}`)
        .then(() => {
          isValid = true;
        })
        .catch((error) => {
          window.console.error(error);
          if (!error.response) {
            this.error = true;
            this.$toast.error("Por favor revisa tu conexión");
          } else {
            this.$toast.error(error.response.data);
          }
        })
        .finally(() => {});

      return isValid;
    },
    validateOtpCodeForCollection() {
      if (this.$refs.form5.validate()) {
        this.sendingOtp2 = true;
        this.$http
          .get(`CollectionOTP/${this.scheduleVisitId}/otp/${this.otpCode}`)
          .then(() => {
            this.showOtpDialog2 = false;
            this.saveScheduledVisit();
          })
          .catch((error) => {
            window.console.error(error);
            if (!error.response) {
              this.error = true;
              this.$toast.error("Por favor revisa tu conexión");
            } else {
              this.$toast.error("El PIN ingresado no es válido o ha expirado");
            }
          })
          .finally(() => (this.sendingOtp2 = false));
      }
    },
    currencyFormatter(val, n, x) {
      if (!val) return null;
      const re =
        "(\\d)(?=(\\d{" + (x || 3) + "})+" + (n > 0 ? "\\." : "$") + ")";
      return val.toFixed(Math.max(0, ~~n)).replace(new RegExp(re, "g"), "$1.");
    },
    saveScheduledVisit() {
      this.scheduleVisit.paymentOfQuotas = [];
      for (let i = 0; i < this.quotaPayments.length; i++) {
        this.scheduleVisit.paymentOfQuotas.push({
          quotaId: this.quotaPayments[i].id,
          paymentDate: new Date(),
          amount: this.quotaPayments[i].paymentAmount,
          scheduledVisitId: this.scheduleVisitId,
          quotaNumber: this.quotaPayments[i].quotaNumber,
        });
      }

      this.loading = true;
      this.error = false;
      this.$http
        .put(`QuotaCollection/${this.scheduleVisitId}`, this.scheduleVisit)
        .then(() => {
          this.$router.push(this.parentPath);
          this.$toast.success("Cobro registrado con éxito");
        })
        .catch((error) => {
          window.console.error(error);

          if (!error.response) {
            this.error = true;
            this.$toast.error("Por favor revisa tu conexión");
            return;
          }

          const { status } = error.response;
          if (status === 400) {
            this.modelErrors = error.response.data.modelErrors;
            this.$toast.error("El formulario contiene errores");
          }
          this.loading = false;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async getScheduleVisit() {
      this.loading = true;
      this.error = false;
      await this.$http
        .get(`ScheduledVisit/${this.scheduleVisitId}`)
        .then((resp) => {
          this.scheduleVisit = resp.data;
        })
        .catch((error) => {
          window.console.error(error);
          if (!error.response) {
            this.error = true;
            return;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async getPointOfSale() {
      this.loading = true;
      this.error = false;
      await this.$http
        .get(`pointOfSale/${this.model.pointOfSaleId}`)
        .then((resp) => {
          this.pointOfSale = resp.data;
        })
        .catch((error) => {
          window.console.error(error);
          if (!error.response) {
            this.error = true;
            return;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async getPointOfSaleId() {
      this.loading = true;
      this.error = false;
      await this.$http
        .get(`creditRequest/${this.scheduleVisit.creditRequestId}`)
        .then((resp) => {
          this.model.pointOfSaleId = resp.data.pointOfSaleId;
        })
        .catch((error) => {
          window.console.error(error);
          if (!error.response) {
            this.error = true;
            return;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async getPendingQuotas() {
      this.loading = true;
      this.error = false;
      let params = new URLSearchParams();
      params.append("pageNumber", 1);
      params.append("pageSize", -1);
      params.append("orderBy", "Id");
      await this.$http
        .get(
          `QuotaCollection/PendingQuotas/${this.scheduleVisit.creditRequestId}`,
          { params: params }
        )
        .then((resp) => {
          this.quotasPending = resp.data;
        })
        .catch((error) => {
          window.console.error(error);
          if (!error.response) {
            this.error = true;
            return;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  watch: {
    selectedPointOfSale() {
      if (this.selectedPointOfSale && this.selectedPointOfSale.id) {
        this.loading = true;
        this.$http
          .get(`pointOfSale/${this.selectedPointOfSale.id}`)
          .then((resp) => {
            this.pointOfSale = resp.data;
          })
          .catch((error) => {
            this.error = true;
            window.console.error(error);
            this.$emit("error");
          })
          .finally(() => {
            this.loading = false;
          });
      } else if (this.selectedPointOfSale) {
        this.pointOfSale.localName = this.selectedPointOfSale;
      } else {
        this.pointOfSale = {};
      }
    },
    "creditRequest.productId"() {
      if (this.creditRequest && this.creditRequest.productId) {
        this.$http
          .get(`products/${this.creditRequest.productId}`)
          .then((resp) => {
            this.creditRequest.providerId = resp.data.providerId;
            this.creditRequest.productCode = resp.data.productCode;
          })
          .catch((error) => {
            this.error = true;
            window.console.error(error);
            this.$emit("error");
          })
          .finally(() => {});
      } else {
        this.creditRequest.providerId = null;
        this.creditRequest.productCode = null;
      }
    },
    "creditRequest.paymentFrequencyId"() {
      this.creditRequest.quotaExpirationDay = null;
    },
    "creditRequest.creditTypeId"() {
      this.creditRequest.productId = null;
      this.creditRequest.productCode = null;
      this.creditRequest.providerId = null;
    },
    "pointOfSale.cityId"() {
      if (this.pointOfSale && this.pointOfSale.cityId) {
        this.$http
          .get(`creditRequest/checkif/${this.pointOfSale.cityId}`)
          .then(() => {
            this.invalidCity = false;
          })
          .catch((error) => {
            window.console.error(error);
            this.invalidCity = true;
            this.visit.interestOnCredit = false;
            this.$toast.warning(error.response.data);
          })
          .finally(() => {});
      }
    },
    quotaPaymentCount() {
      if (this.quotaPaymentCount && this.quotaPaymentCount > 0) {
        this.quotaPayments = this.quotasPending.slice(
          0,
          this.quotaPaymentCount
        );
      } else {
        this.quotaPayments = [];
      }
    },
  },
};
</script>

<style scoped>
</style>