<template>
  <v-app id="inspire">
    <nav-bar v-if="isLoggedIn()"></nav-bar>
    <v-main>
      <breadcrumbs flat></breadcrumbs>
      <v-container>
        <router-view :key="$route.fullPath"></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>


<script>
import authMixin from "./mixins/authMixin";
import NavBar from "./components/app/NavBar";
import Breadcrumbs from "./components/app/Breadcrumbs.vue";
export default {
  name: "App",
  mixins: [authMixin],
  components: {
    NavBar,
    Breadcrumbs,
  },

  created() {
    // check if user is logged
    this.$store.dispatch("autologin");

    // check if the user is authenticated
    this.$http.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response.status === 401) {
          this.$store.dispatch("logout");
          this.$toast.warning("Sesión expirada");
        }
        return Promise.reject(error);
      }
    );
  },
};
</script>

<style>
.v-application {
  font-family: "Montserrat", "Roboto", sans-serif !important;
}
#inspire {
  background: #f7f7f8;
}
#inspire.theme--dark.v-application{
  background: #121212;
}
</style>
