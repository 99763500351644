<template>
  <div>
    <base-pagination-table
      :filter.sync="filters.filter"
      :headers="headers"
      :items="items"
      :options.sync="options"
      :server-items-length="totalItems"
      :loading="loading"
      :error="error"
      :items-per-page="itemsPerPage"
      :downloading="downloading"
      :filter-count="filterCount"
      :exportable="isExportable"
      @export="download($event)"
      @refresh="getItems()"
      @update:filter="timeOut()"
      @rowClick="onRowClick($event)"
      @clear="clear()"
    >
      <template v-slot:item.interestOnCredit="{ item }">
        {{ item.interestOnCredit ? "Sí" : "No" }}
      </template>
    </base-pagination-table>
    <v-btn
      v-if="!error && !loading"
      fab
      bottom
      fixed
      right
      dark
      color="primary"
      title="Nuevo"
      @click="$router.push({ path: 'create', append: true })"
    >
      <v-icon dark>mdi-plus</v-icon>
    </v-btn>
  </div>
</template>

<script>
import listMixin from "../mixins/listMixin";
import authMixin from "../mixins/authMixin";

export default {
  name: "VisitList",
  mixins: [listMixin, authMixin],
  data() {
    return {
      title: "Visitas",
      endpoint: "OfferVisits",
      headers: [
        { text: "#", value: "id" },
        { text: "Fecha", value: "dateOfVisit" },
        { text: "Hora", value: "visitingTime" },
        { text: "Punto de Venta", value: "pointOfSale" },
        { text: "Interés en Crédito", value: "interestOnCredit" },
      ],
    };
  },
  computed: {
    isExportable() {
        var CanExport = this.userHasRole("Administrador") || this.userHasRole("Gestor_Administrativo");
        console.log("export " + CanExport);
        return CanExport;
    },
  },
  mounted() {
    if (this.userHasRole("VC"))
      this.endpoint += "?&userId=" + this.getUserData().nameid;
  },
};
</script>

<style scoped>
</style>