<template>
  <div>
    <base-section-card title="Cuentas Bancarias" class="mb-4">
      <template slot="actions">
        <v-btn outlined text color="secondary" @click="add" v-if="!readMode"
          >Agregar</v-btn
        >
      </template>
      <v-data-table
        :headers="headers"
        :items="items"
        sort-by="id"
        class="elevation-0"
        disable-sort
        disable-pagination
        hide-default-footer
      >
        <template v-slot:[`item.actions`]="{ item }" v-if="!readMode">
          <v-btn @click="editItem(item)" text outlined class="ma-2" icon>
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn @click="deleteItem(item)" text outlined class="ma-2" icon>
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>

      <base-dialog
        width="500"
        v-model="dialogDelete"
        title="Eliminar registro"
        text="Estás seguro que deseas eliminar el registro?"
        actionText="Eliminar"
        @onCancel="closeDelete()"
        @onConfirm="deleteItemConfirm()"
      ></base-dialog>

      <v-dialog v-model="dialogForm" max-width="500px">
        <v-card>
          <v-card-title>
            <span class="text-h5">{{ formTitle }}</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-form
                ref="form"
                v-model="isValidForm"
                id="myForm"
                @submit.prevent="save()"
              >
                <v-row>
                  <v-col cols="12">
                    <base-text-field
                      label="Banco"
                      v-model="editedItem.bankName"
                      hint="Ej: Banco Itaú"
                      :rules="[rules.required]"
                    >
                    </base-text-field>
                  </v-col>
                  <v-col cols="12">
                    <base-text-field
                      label="Nro. de Cuenta"
                      v-model="editedItem.accountNumber"
                      :rules="[rules.required]"
                    >
                    </base-text-field>
                  </v-col>
                  <v-col cols="12">
                    <base-text-field
                      label="Titular de Cuenta"
                      v-model="editedItem.accountHolder"
                      :rules="[rules.required]"
                    >
                    </base-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-select
                      :items="typesOfDocuments"
                      label="Tipo de documento"
                      v-model="editedItem.documentType"
                      :rules="[rules.required]"
                      dense
                      outlined
                    ></v-select>
                  </v-col>
                  <v-col cols="12">
                    <base-text-field
                      label="Nro. de documento"
                      v-model="editedItem.documentNumber"
                      :rules="[rules.required]"
                    >
                    </base-text-field>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="secondary" outlined text @click="close"
              >Cancelar</v-btn
            >
            <v-btn color="secondary" outlined text @click="save">Guardar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </base-section-card>
  </div>
</template>

<script>
import rules from "../../mixins/rules";
import formatters from "../../mixins/formatters";

export default {
  name: "BankAccountsTable",
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    readMode: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [rules, formatters],
  data: () => ({
    dialogForm: false,
    dialogDelete: false,
    isValidForm: false,
    modelErrors: [],
    headers: [
      { text: "Banco", value: "bankName" },
      { text: "Nro. Cuenta", value: "accountNumber" },
      { text: "Actions", value: "actions", align: "center" },
    ],
    items: [],
    typesOfDocuments: ["CI", "RUC"],
    editedIndex: -1,
    editedItem: {
      bankName: null,
      accountNumber: null,
    },
    defaultItem: {
      bankName: null,
      accountNumber: null,
    },
  }),

  mounted() {
    /*if (!this.readMode) {
      this.headers = this.headers.filter((x) => x.value != "actions");
    }*/
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Registrar Cuenta Bancaria"
        : "Editar Cuenta Bancaria";
    },
  },

  watch: {
    readMode() {
      if (this.readMode) {
        this.headers = this.headers.filter((x) => x.value != "actions");
      } else {
        if (this.headers.indexOf((x) => x.value == "actions") == -1) {
          this.headers.push({ text: "", value: "actions", align: "center" });
        }
      }
    },
    dialogForm(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    items(newval) {
      this.$emit("input", newval);
    },
    value() {
      this.items = this.value;
    },
  },

  methods: {
    editItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogForm = true;
      if (this.$refs.form) this.$refs.form.resetValidation();
      this.modelErrors = [];
    },

    deleteItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.items.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      if (this.$refs.form) this.$refs.form.resetValidation();
      this.modelErrors = [];
      this.dialogForm = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    add() {
      this.dialogForm = true;
      if (this.$refs.form) this.$refs.form.resetValidation();
      this.modelErrors = [];
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.$refs.form.validate()) {
        if (this.editedIndex > -1) {
          Object.assign(this.items[this.editedIndex], this.editedItem);
        } else {
          this.items.push(this.editedItem);
        }
        this.close();
      }
    },
  },
};
</script>

<style scoped>
</style>