<template>
  <div>
    <base-section-card title="Límites de Cobranzas" class="mb-4">
      <template slot="actions">
        <v-btn outlined text color="secondary" @click="addLimit">Agregar</v-btn>
      </template>
      <v-data-table
          :headers="headers"
          :items="items"
          sort-by="weekDay.id"
          class="elevation-0"
          disable-sort
          disable-pagination
          hide-default-footer
      >
        <template v-slot:item.amount="{ item }">
          {{ toCurrency(item.amount) }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn @click="editItem(item)" text outlined class="ma-2" icon>
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn @click="deleteItem(item)" text outlined class="ma-2" icon>
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </base-section-card>

    <base-dialog
        width="500"
        v-model="dialogDelete"
        title="Eliminar registro"
        text="Estás seguro que deseas eliminar el registro?"
        actionText="Eliminar"
        @onCancel="closeDelete()"
        @onConfirm="deleteItemConfirm()"
    ></base-dialog>

    <v-dialog v-model="dialogForm" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ formTitle }}</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-form
                ref="form"
                v-model="isValidForm"
                id="myForm"
                @submit.prevent="save()"
            >
              <v-row>
                <v-col cols="12">
                  <base-autocomplete
                      label="Día"
                      item-text="name"
                      item-value="name"
                      v-model="editedItem.weekDay"
                      return-object
                      :endpoint="dayOfWeekEndpoint"
                      :rules="[rules.required]"
                      :error-messages="modelErrors['weekDay']"
                  ></base-autocomplete>
                </v-col>
                <v-col cols="12">
                  <base-currency-input
                      :greaterThanZero="true"
                      label="Monto"
                      v-model="editedItem.amount"
                      :required="true">
                  </base-currency-input>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" outlined text @click="close">Cancelar</v-btn>
          <v-btn color="secondary" outlined text @click="save">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>
<script>
import rules from '../../mixins/rules'
import formatters from '../../mixins/formatters'

export default {
  props: {
    value: {
      type: Array,
      default: () => []
    },
    readMode: {
      type: Boolean,
      default: false
    },
  },
  mixins: [rules, formatters],
  data: () => ({
    dialogForm: false,
    dialogDelete: false,
    isValidForm: false,
    modelErrors: [],
    excludeDays: [],
    dayOfWeekEndpoint: 'collectionLimits/days',
    headers: [
      {text: 'Día de la semana', value: 'weekDay.name'},
      {text: 'Monto', value: 'amount'},
      {text: 'Actions', value: 'actions', align: 'center'},
    ],
    items: [],
    editedIndex: -1,
    editedItem: {
      id: null,
      weekDay: {},
      amount: null,
    },
    defaultItem: {
      id: null,
      weekDay: {},
      amount: null,
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'Nuevo límite' : 'Editar límite'
    },
  },

  watch: {
    dialogForm(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
    items(newval) {
      this.$emit('input', newval);
    },
    value() {
      this.items = this.value;
    }
  },

  methods: {
    editItem(item) {
      this.dayOfWeekEndpoint = "collectionLimits/days"
      this.editedIndex = this.items.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogForm = true
      if (this.$refs.form) this.$refs.form.resetValidation()
      this.modelErrors = []
    },

    deleteItem(item) {
      this.editedIndex = this.items.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItemConfirm() {
      this.items.splice(this.editedIndex, 1)
      this.closeDelete()
    },

    close() {
      if (this.$refs.form) this.$refs.form.resetValidation()
      this.modelErrors = []
      this.dialogForm = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    addLimit() {
      this.dialogForm = true
      this.updateExcludeDays();
      if (this.$refs.form) this.$refs.form.resetValidation()
      this.modelErrors = []
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    save() {
      if (this.$refs.form.validate() && this.validateWeekDay()) {
        if (this.editedIndex > -1) {
          Object.assign(this.items[this.editedIndex], this.editedItem)
        } else {
          this.items.push(this.editedItem)
        }
        this.close()
      }
    },

    validateWeekDay() {
      const filter = this.items.filter(x => x.weekDay.id == this.editedItem.weekDay.id && x.id != this.editedItem.id);
      if (filter.length > 0 && this.editedIndex != -1) {
        this.modelErrors = {"weekDay": [`Ya existe un registro para el día ${this.editedItem.weekDay.name}`]}
        return false;
      }
      return true;
    },

    updateExcludeDays() {
      let excludeDays = ''
      for (let i = 0; i < this.items.length; i++) {
        if (i > 0) excludeDays += "&";
        excludeDays += `excludeDay=${this.items[i].weekDay.name}`
      }

      this.dayOfWeekEndpoint = `collectionLimits/days?${excludeDays}`
    }
  },
}
</script>


<style scoped>

</style>
