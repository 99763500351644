<template>
  <div>
    <base-pagination-table
        :filter.sync="filters.filter"
        :headers="headers"
        :items="items"
        :options.sync="options"
        :server-items-length="totalItems"
        :loading="loading"
        :error="error"
        :items-per-page="itemsPerPage"
        :downloading="downloading"
        :filter-count="filterCount"
        @refresh="getItems()"
        @update:filter="timeOut()"
        @clear="clear()"
        :disable-filter="true"
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn @click="downloadReport(item.id)" text outlined class="ma-2" icon>
          <v-icon>mdi-download</v-icon>
        </v-btn>
      </template>
      <template v-slot:[`item.walletDate`]="{ item }">
        {{ toDateTimeString(item.walletDate) }}
      </template>
      <template v-slot:[`item.walletClosedDate`]="{ item }">
        {{ toDateTimeString(item.walletClosedDate) }}
      </template>
      <template v-slot:[`item.totalAmountCharged`]="{ item }">
        {{ toCurrency(item.totalAmountCharged) }}
      </template>
    </base-pagination-table>

  </div>
</template>
<script>
import listMixin from "../mixins/listMixin";
import formatters from "../mixins/formatters";

export default {
  mixins: [listMixin, formatters],
  data() {
    return {
      title: "Rendiciones",
      endpoint: "wallets?isActive=false",
      headers: [
        {text: "Fecha de Apertura", value: "walletDate"},
        {text: "Fecha de Rendición", value: "walletClosedDate"},
        {text: "Total Rendición", value: "totalAmountCharged"},
        {text: "", value: "actions"},
      ],
    };
  },
  methods: {
    downloadReport(walletId) {
      this.downloading = true;
      this.$http
          .get(`Wallets/${walletId}/pdf`, {responseType: "blob",})
          .then((response) => {
            const blob = new Blob([response.data]);
            const fileURL = window.URL.createObjectURL(blob);
            const fileLink = document.createElement("a");
            let fileName = response.headers["content-disposition"]
                .split("filename=")[1]
                .split(";")[0]
                .split('"')
                .join("");

            fileLink.href = fileURL;
            fileLink.setAttribute("download", fileName);
            document.body.appendChild(fileLink);
            fileLink.click();
          })
          .catch((error) => {
            window.console.error(error);
            this.$toast.error("No se pudo descargar el reporte de rendición");
          })
          .finally(() => {
            this.downloading = false;
          });
    }
  },
};
</script>

<style lang="scss" scoped></style>
