<template>
  <v-autocomplete
      v-bind="$attrs"
      v-on="$listeners"
      outlined
      validate-on-blur
      dense
      no-data-text="La búsqueda no produjo resultados"
      autocomplete="off"
      hide-details="auto"
      :clearable="!$attrs.readonly"
      :items="items"
      :search-input.sync="filter"
      :hide-no-data="!noResult"
      :no-filter="lazy"
      :loading="cargando"
      @update:list-index="onItemHover($event)"
  >
    <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
      <slot :name="slot" v-bind="scope"/>
    </template>
  </v-autocomplete>
</template>

<script>
export default {
  props: {
    // si es lazy obtinene los items unicamente al ingresar un filtro,
    // sino obtiene todos los items al renderizar
    lazy: {
      type: Boolean,
      default: false,
    },
    endpoint: {
      type: String,
      required: false,
    },
    filterProp: {
      type: String,
      default: null,
    },
    initialItemId: {
      type: Number,
      default: undefined,
    },
  },
  data() {
    return {
      items: [],
      error: false,
      filter: undefined,
      timer: null,
      noResult: false,
      cargando: false
    };
  },
  mounted() {
    if (!this.lazy && this.endpoint) this.getItems();
    if (this.initialItemId) this.getItem();
  },
  methods: {
    getItems() {
      if (this.lazy && !this.filter) return;

      this.cargando = true;
      this.error = false;
      this.noResult = false;

      // traer los resultados en una sola pagina
      let params = new URLSearchParams();
      params.append("pageNumber", 1);
      params.append("pageSize", -1);

      if (this.filter)
        params.append("filter", this.filter);

      this.$http
          .get(this.endpoint, {params: params})
          .then((resp) => {
            this.items = resp.data;
            if (this.items.length === 0) this.noResult = true;
          })
          .catch((error) => {
            this.error = true;
            window.console.error(error);
            this.$emit("error");
          })
          .finally(() => {
            this.cargando = false;
          });
    },
    getItem() {
      this.cargando = true;
      this.error = false;
      this.$http
          .get(`${this.endpoint}/${this.initialItemId}`)
          .then((resp) => {
            this.items.push(resp.data);
            this.$emit("input", resp.data);
          })
          .catch((error) => {
            this.error = true;
            window.console.error(error);
            this.$emit("error");
          })
          .finally(() => {
            this.cargando = false;
          });
    },
    onItemHover(index) {
      this.$emit("item:hover", this.items[index]);
    },
  },
  watch: {
    initialItemId(val) {
      if (val) {
        // la consulta se realiza solo si el valor de busqueda no se encuentra en la lista
        const inList = this.items.some((i) => i === val || i.id === val);
        if (!inList) this.getItem();
      }
    },
    filter(val) {
      // el filtro desde el backend solo se hace si lazy == true
      if (!this.lazy) return;

      let valInList;
      if (this.filterProp)
        valInList = this.items.some((i) => i[this.filterProp] === val);
      else
        valInList = this.items.some((i) => i[this.$attrs["item-text"]] === val);

      // consultar API solo si el valor de busqueda no se encuentra en la lista
      if (val && !valInList) {
        if (this.timer) {
          clearTimeout(this.timer);
          this.timer = null;
        }
        this.timer = setTimeout(() => {
          this.getItems();
        }, 800);
      }
      if (!val) this.$emit("remove");
    },
    endpoint(val) {
      if (val) this.getItems();
      else this.items = [];
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
