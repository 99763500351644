<template>
  <v-card v-bind="$attrs" v-on="$listeners" outlined class="mx-auto">
    <div class="pa-4">
      <slot></slot>
    </div>
  </v-card>
</template>

<script>
export default {
  name: "base-card",
};
</script>

<style lang="scss" scoped>
</style>
