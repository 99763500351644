<template>
  <v-toolbar v-bind="$attrs">
    <v-toolbar-title v-if="!isMobile">
      <!--      <v-btn v-if="showGoBackBtn" icon @click="$router.push(currentRoute.parent)" type="button">-->
      <v-btn v-if="showGoBackBtn" icon @click="$router.go(-1)" type="button">
         <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      {{ $router.currentRoute.meta.title }}
    </v-toolbar-title>
    <v-spacer></v-spacer>
    <v-breadcrumbs :items="breadcrumbs">
      <template v-slot:item="{ item }">
        <v-icon v-if="item.icon" class="mr-1"></v-icon>
       <!--<router-link v-if="!item.disabled" :to="item.href">{{
            item.text
          }}
        </router-link>
        <span v-else class="text--disabled">{{ item.text }}</span>-->
      </template>
       <!--<template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>-->
    </v-breadcrumbs>
  </v-toolbar>
</template>

<script>
export default {
  props: ["title", "showBreadcrumbs"],
  data() {
    return {
      breadcrumbs: [],
      currentRoute: null,
    };
  },
  mounted() {
    this.getBreadcrumbs();
  },
  methods: {
    getBreadcrumbs() {
      this.breadcrumbs = [
        {
          text: "Principal",
          disabled: false,
          href: "/",
          icon: "mdi-home",
        },
      ];
      this.$router.currentRoute.matched
          .filter((x) => !x.meta.ignoreBreadcrumb && x.name != "home")
          .forEach((x) => {
            this.breadcrumbs.push({
              text: x.meta.title
                  ? x.meta.title.toLowerCase()
                  : x.path.split("/").pop(),
              href: x.path,
              disabled: false,
              parent: x.parent,
            });
          });
      this.currentRoute = this.breadcrumbs.slice(-1)[0];
      this.currentRoute.disabled = true;
    },
  },
  watch: {
    "$route.path"() {
      this.getBreadcrumbs();
    },
  },
  computed: {
    showGoBackBtn() {
      return this.currentRoute && !!this.currentRoute.parent;
    },
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly;
    },
  },
};
</script>

<style lang="scss" scoped></style>
