<template>
  <div>
    <base-form-card
      :loading="loading"
      :readMode="readMode"
      :editable="editable"
      :error="error"
      :alert="alert"
      :submit-btn-enabled="false"
      @onEdit="readMode = false"
      @onDelete="remove()"
      @onRefresh="getDataFromApi()"
    >
      <v-stepper v-model="step">
        <v-stepper-header v-if="visit.interestOnCredit">
          <v-stepper-step :complete="step > 1" step="1">
            Datos del PDV
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :complete="step > 2" step="2">
            Adjuntar Documentos
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :complete="step > 3" step="3">
            Solicitar Crédito
          </v-stepper-step>
        </v-stepper-header>
        <v-stepper-items>
          <v-stepper-content step="1">
            <v-form
              ref="form1"
              v-model="isValidForm"
              id="myForm"
              @submit.prevent="savePdv()"
            >
              <base-section-card title="Datos del PDV" class="mb-4">
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                    v-if="pointOfSale.id && !pointOfSale.username"
                  >
                    <base-autocomplete
                      label="Usuario"
                      item-text="username"
                      item-value="id"
                      v-model="pointOfSale.userId"
                      endpoint="users/role/4"
                      :readonly="readMode"
                    ></base-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <base-autocomplete
                      label="Código de PDV"
                      item-text="id"
                      item-value="id"
                      v-model="selectedPointOfSale"
                      return-object
                      endpoint="pointOfSale/ForVCs"
                    ></base-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <base-combobox
                      v-model="selectedPointOfSale"
                      item-text="localName"
                      item-value="id"
                      label="Nombre del local"
                      endpoint="pointOfSale/ForVCs"
                      :rules="[rules.required]"
                    ></base-combobox>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <base-text-field
                      label="Nombre del Propietario"
                      v-model="pointOfSale.ownerName"
                      :readonly="readMode"
                    >
                    </base-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" v-if="!pointOfSale.id">
                    <base-text-field
                      label="Apellido"
                      v-model="pointOfSale.lastName"
                      :rules="[rules.required]"
                      :readonly="readMode"
                      :error-messages="modelErrors['lastName']"
                    >
                    </base-text-field>
                  </v-col>

                  <v-col cols="12" sm="6" v-if="!pointOfSale.id">
                    <base-text-field
                      label="Username"
                      v-model="pointOfSale.username"
                      prepend-inner-icon="mdi-account"
                      :rules="[rules.required]"
                      :readonly="readMode"
                      :error-messages="modelErrors['username']"
                    >
                    </base-text-field>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <base-text-field
                      label="Antigüedad (años)"
                      v-model="pointOfSale.age"
                      :required="true"
                    >
                    </base-text-field>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <base-text-field
                      label="Teléfono"
                      v-model="pointOfSale.phoneNumber"
                      prepend-inner-icon="mdi-cellphone"
                      :rules="[rules.required, rules.phoneNumber]"
                      :readonly="readMode"
                      :append-icon="validWhatsappNumber ? 'mdi-whatsapp' : ''"
                      @click:append="sendWhatsapp()"
                    ></base-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <base-text-field
                      label="Email"
                      type="email"
                      v-model="pointOfSale.email"
                      prepend-inner-icon="mdi-email"
                      :rules="[rules.required, rules.email]"
                      :readonly="readMode"
                      :error-messages="modelErrors['email']"
                    >
                    </base-text-field>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <base-select
                      label="Tipo de PDV"
                      item-text="name"
                      item-value="id"
                      v-model="pointOfSale.pointOfSaleTypeId"
                      :readonly="readMode"
                      endpoint="pointOfSaleTypes"
                    ></base-select>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <base-select
                      label="Tamaño de PDV"
                      item-text="name"
                      item-value="id"
                      v-model="pointOfSale.pointOfSaleSizeId"
                      :readonly="readMode"
                      endpoint="pointSaleSizes"
                    ></base-select>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <base-select
                      label="Red de cobro"
                      item-text="name"
                      item-value="id"
                      v-model="pointOfSale.paymentNetworkId"
                      :readonly="readMode"
                      endpoint="paymentNetworks"
                    ></base-select>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <base-text-field
                      label="Dirección"
                      v-model="pointOfSale.address"
                      :readonly="readMode"
                    >
                    </base-text-field>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <base-text-field
                      label="Referencia"
                      v-model="pointOfSale.addressReference"
                      :readonly="readMode"
                    >
                    </base-text-field>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <base-autocomplete
                      label="Ciudad"
                      item-text="name"
                      item-value="id"
                      v-model="pointOfSale.cityId"
                      :readonly="readMode"
                      endpoint="departments/city"
                      :rules="[rules.required]"
                    ></base-autocomplete>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <base-text-field
                      label="Barrio"
                      v-model="pointOfSale.neighborhood"
                      :readonly="readMode"
                    >
                    </base-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <base-text-field
                      label="Cantidad de góndolas"
                      v-model="pointOfSale.numberOfGondolas"
                      :required="true"
                    >
                    </base-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <base-select
                      label="Día de visita"
                      item-text="name"
                      item-value="id"
                      v-model="pointOfSale.visitDayId"
                      :readonly="readMode"
                      :rules="[rules.required]"
                      endpoint="collectionLimits/days"
                    ></base-select>
                  </v-col>
                  <v-col cols="12" sm="6" v-if="showFieldVC">
                    <base-text-field
                      label="VC"
                      v-model="pointOfSale.seller"
                      :readonly="true"
                    >
                    </base-text-field>
                  </v-col>
                  <v-col cols="12" sm="12">
                    <equipments-table
                      v-model="pointOfSale.equipments"
                    ></equipments-table>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-col cols="12" sm="12">
                      <v-checkbox
                        v-model="pointOfSale.keepVisiting"
                        label="¿Seguir visitando?"
                        :readonly="readMode"
                        outlined
                        validate-on-blur
                        dense
                        hide-details="auto"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="12" sm="12">
                      <v-checkbox
                        v-model="pointOfSale.hasWifi"
                        label="¿El local cuanta con wifi?"
                        :readonly="readMode"
                        outlined
                        validate-on-blur
                        dense
                        hide-details="auto"
                      ></v-checkbox>
                    </v-col>

                    <v-col cols="12" sm="12">
                      <v-checkbox
                        v-model="pointOfSale.recharges"
                        label="¿Realizan recargas de saldo?"
                        :readonly="readMode"
                        outlined
                        validate-on-blur
                        dense
                        hide-details="auto"
                      ></v-checkbox>
                    </v-col>

                    <v-col cols="12" sm="12">
                      <v-checkbox
                        v-model="pointOfSale.computer"
                        label="¿PC / Notebook / Tablet?"
                        :readonly="readMode"
                        outlined
                        validate-on-blur
                        dense
                        hide-details="auto"
                      ></v-checkbox>
                    </v-col>

                    <v-col cols="12" sm="12">
                      <v-checkbox
                        v-model="pointOfSale.posTC"
                        label="¿El local cuenta con POS?"
                        :readonly="readMode"
                        outlined
                        validate-on-blur
                        dense
                        hide-details="auto"
                      ></v-checkbox>
                    </v-col>

                    <v-col cols="12" sm="12">
                      <v-checkbox
                        v-model="pointOfSale.smartDevices"
                        label="¿El local cuenta con teléfono inteligente?"
                        :readonly="readMode"
                        outlined
                        validate-on-blur
                        dense
                        hide-details="auto"
                      ></v-checkbox>
                    </v-col>

                    <v-col cols="12" sm="12">
                      <v-checkbox
                        v-model="pointOfSale.purchaseFinancedBalance"
                        label="¿Realizan compra de saldo financiado?"
                        :readonly="readMode"
                        outlined
                        validate-on-blur
                        dense
                        hide-details="auto"
                      ></v-checkbox>
                    </v-col>
                  </v-col>

                  <template v-if="!invalidCity">
                    <v-col cols="12" sm="12">
                      <v-checkbox
                        v-model="visit.interestOnCredit"
                        label="¿Está interesado en solicitar un crédito?"
                        :readonly="readMode"
                        outlined
                        validate-on-blur
                        dense
                        hide-details="auto"
                      ></v-checkbox>
                    </v-col>

                    <v-col cols="12" sm="12" v-if="!visit.interestOnCredit">
                      <base-text-field
                        label="Motivo por el cual no le interesa el crédito"
                        :rules="[rules.required]"
                        v-model="visit.description"
                      >
                      </base-text-field>
                    </v-col>
                  </template>
                </v-row>
              </base-section-card>
              <v-card-actions class="mt-4">
                <v-btn color="primary" @click="savePdv()">
                  {{ !visit.interestOnCredit ? "Finalizar" : "Siguiente" }}
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-stepper-content>
          <v-stepper-content step="2">
            <file-uploader
              v-if="pointOfSale.id"
              :endpoint="filesEndpoint"
              title="Documentos"
            ></file-uploader>
            <v-card-actions class="mt-4">
              <v-btn color="primary" @click="step = 3"> Siguiente </v-btn>
            </v-card-actions>
          </v-stepper-content>
          <v-stepper-content step="3">
            <v-form
              ref="form2"
              v-model="isValidForm"
              id="myForm2"
              @submit.prevent="beforeSaveCreditRequest()"
            >
              <base-section-card title="Solicitud de Crédito" class="mb-4">
                <v-row>
                  <v-col cols="12" sm="6">
                    <base-autocomplete
                      label="Tipo de Solicitud"
                      item-text="name"
                      item-value="id"
                      v-model="creditRequest.creditTypeId"
                      :readonly="readMode"
                      :rules="[rules.required]"
                      endpoint="creditRequest/TypesCredits"
                    ></base-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <template
                    v-if="
                      creditRequest.creditTypeId &&
                      creditRequest.creditTypeId == 2
                    "
                  >
                    <v-col cols="12" sm="4">
                      <base-autocomplete
                        label="Articulo"
                        item-text="name"
                        item-value="id"
                        v-model="creditRequest.productId"
                        :readonly="readMode"
                        endpoint="products"
                      ></base-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="2">
                      <base-text-field
                        label="Código"
                        v-model="creditRequest.productCode"
                        :readonly="true"
                        type="number"
                      ></base-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <base-autocomplete
                        label="Proveedor"
                        item-text="name"
                        item-value="id"
                        v-model="creditRequest.providerId"
                        :readonly="readMode"
                        :rules="[rules.required]"
                        endpoint="providers/common-providers"
                      ></base-autocomplete>
                    </v-col>
                  </template>
                  <v-col cols="12" sm="6">
                    <base-currency-input
                      :greaterThanZero="true"
                      label="Monto del Crédito (Gs.)"
                      v-model="creditRequest.creditAmount"
                      :required="true"
                    >
                    </base-currency-input>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <base-currency-input
                      :greaterThanZero="true"
                      label="Monto de cuota (Gs.)"
                      v-model="creditRequest.feeAmount"
                      :required="true"
                    >
                    </base-currency-input>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <base-text-field
                      label="Nro. de Cuotas"
                      v-model="creditRequest.numberOfQuotas"
                      :rules="[rules.required]"
                      :readonly="readMode"
                      type="number"
                    >
                    </base-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <base-autocomplete
                      label="Frecuencia de Pago"
                      item-text="name"
                      item-value="id"
                      v-model="creditRequest.paymentFrequencyId"
                      :readonly="readMode"
                      :rules="[rules.required]"
                      endpoint="paymentFrecuencies"
                    ></base-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <base-autocomplete
                      v-if="
                        creditRequest.paymentFrequencyId &&
                        creditRequest.paymentFrequencyId != 3
                      "
                      label="Día de vencimiento de la cuota"
                      item-text="name"
                      item-value="id"
                      v-model="creditRequest.quotaExpirationDay"
                      endpoint="creditRequest/weekdays?excludeDay=Domingo"
                      :rules="[rules.required]"
                    ></base-autocomplete>
                    <base-text-field
                      v-else
                      label="Día de vencimiento de la cuota"
                      v-model="creditRequest.quotaExpirationDay"
                      :rules="[rules.required]"
                      :readonly="readMode"
                      :error-messages="modelErrors['quotaExpirationDay']"
                      type="number"
                    >
                    </base-text-field>
                  </v-col>
                </v-row>
              </base-section-card>
              <v-card-actions class="mt-4">
                <v-btn color="primary" @click="beforeSaveCreditRequest()">
                  Confirmar
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </base-form-card>
    <v-dialog v-model="showOtpDialog" persistent width="450">
      <base-card :loading="sendingOtp" :disabled="sendingOtp">
        <v-card-title> Confirmar Solicitud </v-card-title>
        <v-form
          ref="form3"
          v-model="isValidForm"
          id="myForm3"
          @submit.prevent="validateOtpCode()"
        >
          <v-card-text>
            Resumen de la solicitud:
            <ul>
              <li>
                Monto del Crédito:
                <b>Gs. {{ currencyFormatter(creditRequest.creditAmount) }}</b>
              </li>
              <li>
                Cantidad de Cuotas: <b>{{ creditRequest.numberOfQuotas }}</b>
              </li>
            </ul>
            <br />
            Ingresa el PIN que hemos enviado a
            {{ this.pointOfSale.phoneNumber }}
            <v-row dense>
              <v-col cols="6">
                <base-text-field
                  type="number"
                  v-model="otpCode"
                  label="PIN"
                  :rules="[rules.required]"
                ></base-text-field>
              </v-col>
              <v-col cols="6">
                <v-btn color="primary" @click="validateOtpCode"
                  >Confirmar</v-btn
                >
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions>
            <v-btn outlined @click="reSendOtp">Reenviar PIN</v-btn>
            <v-btn outlined @click="showOtpDialog = false">Cancelar</v-btn>
          </v-card-actions>
        </v-form>
      </base-card>
    </v-dialog>
  </div>
</template>

<script>
import formMixin from "../mixins/formMixin";
import FileUploader from "../components/app/FileUploader";
import EquipmentsTable from "../components/app/EquipmentsTable";
import authMixin from "../mixins/authMixin";

export default {
  name: "VisitRegisterForm",
  components: { EquipmentsTable, FileUploader },
  mixins: [formMixin, authMixin],
  data() {
    return {
      endpoint: "",
      step: 1,
      pointOfSale: {},
      selectedPointOfSale: null,
      pointOfSales: [],
      visit: { interestOnCredit: false },
      creditRequest: {
        providerId: null,
        productCode: null,
        productId: null,
        quotaExpirationDay: null,
        paymentFrequencyId: null,
        feeAmount: null,
        numberOfQuotas: null,
        creditTypeId: null,
        offerVisitId: null,
      },
      showOtpDialog: false,
      otpCode: null,
      otpId: null,
      sendingOtp: false,
      invalidCity: false,
      userRegistration: {
        roleId: 4,
      },
    };
  },
  computed: {
    filesEndpoint() {
      if (this.pointOfSale && this.pointOfSale.id)
        return `pointOfSale/${this.pointOfSale.id}/documents`;
      else return undefined;
    },
    showFieldVC() {
      var show = !(this.userHasRole("VC") && !this.pointOfSale.id);
      return show;
    },
  },
  mounted() {
    this.getLocation();
    this.validateLimit();
    this.pointOfSale.keepVisiting = true;
  },
  methods: {
    validateLimit() {
      this.$http
        .get(`Wallets/CheckLimit`)
        .then(() => {})
        .catch((error) => {
          this.$router.push(this.parentPath);
          window.console.error(error);
          if (!error.response) {
            this.error = true;
            this.$toast.error("Por favor revisa tu conexión");
          } else {
            this.$toast.error(error.response.data);
          }
        })
        .finally(() => {});
    },
    savePdv() {
      if (this.$refs.form1.validate() && this.customValidation()) {
        this.loading = true;
        this.error = false;
        if (!this.pointOfSale.latitude && !this.pointOfSale.longitude) {
          this.pointOfSale.latitude = this.visit.latitude;
          this.pointOfSale.longitude = this.visit.longitude;
        }

        if (!this.pointOfSale.id) {
          if (this.pointOfSale.username != "")
            this.userRegistration.username = this.pointOfSale.username;

          this.loading = true;
          this.error = false;
          this.$http
            .post("users", this.userRegistration)
            .then((resp) => {
              //console.log("deberia crear pdv");
              this.pointOfSale.userId = resp.data.id;
              this.createorUpdatePDV();
            })
            .catch((error) => {
              window.console.error(error);

              if (!error.response) {
                this.error = true;
                this.$toast.error("Por favor revisa tu conexión");
                return;
              }

              const { status } = error.response;
              if (status === 400) {
                this.modelErrors = error.response.data.modelErrors;
                this.$toast.error("El formulario contiene errores");
              }
              this.loading = false;
            })
            .finally(() => {
              this.loading = false;
            });
        }

        if (this.pointOfSale.id) {
          this.createorUpdatePDV();
        }
      } else {
        this.$toast.error("El formulario contiene errores");
      }
    },
    createorUpdatePDV() {
      this.loading = true;
      this.error = false;
      const promise = this.pointOfSale.id
        ? this.$http.put(`pointOfSale/${this.pointOfSale.id}`, this.pointOfSale)
        : this.$http.post("pointOfSale", this.pointOfSale);
      promise
        .then((resp) => {
          if (resp.data && resp.data.id) this.pointOfSale.id = resp.data.id;
          this.visit.pointOfSaleId = this.pointOfSale.id;
          this.saveVisit();
        })
        .catch((error) => {
          window.console.error(error);

          if (!error.response) {
            this.error = true;
            this.$toast.error("Por favor revisa tu conexión");
            return;
          }

          const { status } = error.response;
          if (status === 400) {
            this.modelErrors = error.response.data.modelErrors;
            this.$toast.error("El formulario contiene errores");
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    saveVisit() {
      this.loading = true;
      this.error = false;
      this.visit.responsibleId = this.getUserData().nameid;
      this.visit.dateOfVisit = new Date();
      this.$http
        .post("OfferVisits", this.visit)
        .then((resp) => {
          if (this.visit.interestOnCredit) {
            this.creditRequest.pointOfSaleId = this.visit.pointOfSaleId;
            this.creditRequest.offerVisitId = resp.data.id;
            this.step = 2;
          } else {
            this.$router.push(this.parentPath);
            this.$toast.success("Registro creado con éxito");
          }
        })
        .catch((error) => {
          window.console.error(error);
          if (!error.response) {
            this.error = true;
            this.$toast.error("Por favor revisa tu conexión");
          }
        })
        .finally(() => (this.loading = false));
    },
    beforeSaveCreditRequest() {
      if (this.$refs.form2.validate() && this.validateExpirationDay()) {
        this.sendOtp();
      }
    },
    saveCreditRequest() {
      this.loading = true;
      this.error = false;
      this.$http
        .post("creditRequest", this.creditRequest)
        .then(() => {
          this.$toast.success("Registro creado con éxito");
          this.$router.push(this.parentPath);
        })
        .catch((error) => {
          window.console.error(error);

          if (!error.response) {
            this.error = true;
            this.$toast.error("Por favor revisa tu conexión");
            return;
          }

          const { status } = error.response;
          if (status === 400) {
            this.modelErrors = error.response.data.modelErrors;
            this.$toast.error("El formulario contiene errores");
          }
          this.loading = false;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    validateExpirationDay() {
      if (
        this.creditRequest.quotaExpirationDay &&
        (+this.creditRequest.quotaExpirationDay < 1 ||
          +this.creditRequest.quotaExpirationDay > 30)
      ) {
        this.modelErrors = {
          quotaExpirationDay: [`Debe ingresar un valor entre 1 y 30`],
        };
        return false;
      } else {
        this.modelErrors = {};
        return true;
      }
    },
    getLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          this.visit.latitude = position.coords.latitude.toString();
          this.visit.longitude = position.coords.longitude.toString();
        });
      }
    },
    sendOtp() {
      this.loading = true;
      let otpModel = {};
      otpModel.offerVisitId = this.creditRequest.offerVisitId;
      otpModel.phone = this.pointOfSale.phoneNumber;
      this.$http
        .post("OTPCode", otpModel)
        .then((resp) => {
          this.$toast.success("Hemos enviado el PIN con éxito");
          this.showOtpDialog = true;
          this.otpId = resp.data.id;
        })
        .catch((error) => {
          window.console.error(error);
          if (!error.response) {
            this.error = true;
            this.$toast.error("Por favor revisa tu conexión");
          } else {
            this.$toast.error("No hemos podido enviar el PIN");
          }
        })
        .finally(() => (this.loading = false));
    },
    reSendOtp() {
      this.sendingOtp = true;
      let otpModel = {};
      otpModel.offerVisitId = this.creditRequest.offerVisitId;
      otpModel.phone = this.pointOfSale.phoneNumber;
      this.$http
        .put(`OTPCode/${this.otpId}`, otpModel)
        .then((resp) => {
          this.$toast.success("Hemos re enviado el PIN con éxito");
          this.showOtpDialog = true;
          this.otpId = resp.data.id;
        })
        .catch((error) => {
          window.console.error(error);
          if (!error.response) {
            this.error = true;
            this.$toast.error("Por favor revisa tu conexión");
          } else {
            this.$toast.error("No hemos podido enviar el PIN");
          }
        })
        .finally(() => (this.sendingOtp = false));
    },
    validateOtpCode() {
      if (this.$refs.form3.validate()) {
        this.sendingOtp = true;
        this.$http
          .get(`OTPCode/${this.creditRequest.offerVisitId}/otp/${this.otpCode}`)
          .then(() => {
            this.showOtpDialog = false;
            this.saveCreditRequest();
          })
          .catch((error) => {
            window.console.error(error);
            if (!error.response) {
              this.error = true;
              this.$toast.error("Por favor revisa tu conexión");
            } else {
              this.$toast.error("El PIN ingresado no es válido o ha expirado");
            }
          })
          .finally(() => (this.sendingOtp = false));
      }
    },
    currencyFormatter(val, n, x) {
      if (!val) return null;
      const re =
        "(\\d)(?=(\\d{" + (x || 3) + "})+" + (n > 0 ? "\\." : "$") + ")";
      return val.toFixed(Math.max(0, ~~n)).replace(new RegExp(re, "g"), "$1.");
    },
  },
  watch: {
    selectedPointOfSale() {
      if (this.selectedPointOfSale && this.selectedPointOfSale.id) {
        this.loading = true;
        this.$http
          .get(`pointOfSale/${this.selectedPointOfSale.id}`)
          .then((resp) => {
            this.pointOfSale = resp.data;
          })
          .catch((error) => {
            this.error = true;
            window.console.error(error);
            this.$emit("error");
          })
          .finally(() => {
            this.loading = false;
          });
      } else if (this.selectedPointOfSale) {
        this.pointOfSale.localName = this.selectedPointOfSale;
      } else {
        this.pointOfSale = {};
      }
    },
    "creditRequest.productId"() {
      if (this.creditRequest && this.creditRequest.productId) {
        this.$http
          .get(`products/${this.creditRequest.productId}`)
          .then((resp) => {
            this.creditRequest.providerId = resp.data.providerId;
            this.creditRequest.productCode = resp.data.productCode;
          })
          .catch((error) => {
            this.error = true;
            window.console.error(error);
            this.$emit("error");
          })
          .finally(() => {});
      } else {
        this.creditRequest.providerId = null;
        this.creditRequest.productCode = null;
      }
    },
    "creditRequest.paymentFrequencyId"() {
      this.creditRequest.quotaExpirationDay = null;
    },
    "creditRequest.creditTypeId"() {
      this.creditRequest.productId = null;
      this.creditRequest.productCode = null;
      this.creditRequest.providerId = null;
    },
    "pointOfSale.cityId"() {
      if (this.pointOfSale && this.pointOfSale.cityId) {
        this.$http
          .get(`creditRequest/checkif/${this.pointOfSale.cityId}`)
          .then(() => {
            this.invalidCity = false;
          })
          .catch((error) => {
            window.console.error(error);
            this.invalidCity = true;
            this.visit.interestOnCredit = false;
            this.$toast.warning(error.response.data);
          })
          .finally(() => {});
      }
    },
    "pointOfSale.ownerName"(newValue) {
      if (newValue) {
        this.userRegistration.firstName = newValue;
      }
    },
    "pointOfSale.lastName"(newValue) {
      if (newValue) {
        this.userRegistration.lastName = newValue;
      }
    },
    "pointOfSale.email"(newValue) {
      if (newValue) {
        this.userRegistration.email = newValue;
      }
    },
    "pointOfSale.phoneNumber"(newValue) {
      if (newValue) {
        this.userRegistration.phoneNumber = newValue;
      }
    },
  },
};
</script>

<style scoped>
</style>