import formatters from "./formatters";
export default {
  mixins: [formatters],
  data() {
    return {
      rules: {
        email: (v) =>
          !v || // para que acepte null
          !!v.match(/[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/) ||
          "Debe ingresar una dirección de correo válida",
        ruc: (v) =>
          !v || // para que acepte null
          !!v.match(/\d+-\d/) ||
          "No es un Nro. de Ruc válido",
        required: (v) =>
          (Array.isArray(v)
            ? v.length > 0
            : typeof v == "object" && v != null
              ? Object.keys(v).length > 0
              : !!v) || "Este campo es requerido",
        minLength: (len) => (v) =>
          !v || // para que acepte null
          (v || "").length >= len ||
          `Debe ingresar al menos ${len} caracteres`,
        greaterThanZero: (v) =>
          !v || // para que acepte null
          +(v.toString().split(".").join("")) > 0 ||
          "Debe ingresar un número mayor que cero",
        positive: (v) =>
          !v || // para que acepte null
          +(v.toString().split(".").join("")) >= 0 ||
          "Debe ingresar un número positivo",
        number: (v) =>
          !v || // para que acepte null
          !isNaN(this.toNumber(v)) ||
          "Debe ingresar un número",
        phoneNumber: (v) =>
          !v || // para que acepte null
          !!v.match(/^[\\+]?[(]?[0-9]{4}[)]?[-\s\\.]?[0-9]{3}[-\s\\.]?[0-9]{3,6}$/) ||
          "Debe ingresar un número de teléfono válido",
        password: {
          atLestOneUpperCase: (v) =>
            !v || // para que acepte null
            !!v.match(/(?=.*?[A-Z])/) ||
            "Debe contener al menos una letra mayúscula",
          atLeastOneLowerCase:  (v) =>
            !v || // para que acepte null
            !!v.match(/(?=.*?[a-z])/) ||
            "Debe contener al menos una letra minúscula",
          atLeastOneDigit:  (v) =>
            !v || // para que acepte null
            !!v.match(/(?=.*?[0-9])/) ||
            "Debe contener al menos un número",
          atLeastOneSpecialChar:  (v) =>
            !v || // para que acepte null
            !!v.match(/(?=.*?[.,#?!@$ %^&*-])/) ||
            "Debe contener al menos un caracter especial. Ej. (.,#?!@$-)",
        }
      },
    };
  },
};
