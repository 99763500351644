<template>
  <v-text-field
      v-bind="$attrs"
      v-on="$listeners"
      dense
      outlined
      validate-on-blur
      :value="formated"
      :rules="currencyRules"
      :clearable="!$attrs.readonly"
      @input="formatValue($event)"
      autocomplete="off"
      hide-details="auto"
  ></v-text-field>
</template>
<script>
import formatters from "../../mixins/formatters";
import rulesMixin from "../../mixins/rules";

export default {
  props: ["value", "required", "greaterThanZero"],
  data() {
    return {
      formated: null,
    };
  },
  mixins: [formatters, rulesMixin],
  mounted() {
    if (this.value || this.value === 0) this.formatValue(this.value);
  },
  methods: {
    formatValue(val) {
      if ((val && !isNaN(this.toNumber(val))) || val === 0) {
        val = this.toNumber(val);
        this.formated = this.toCurrency(val);
      } else {
        this.formated = val ? val : null;
      }
    },
  },
  watch: {
    value(val) {
      this.formatValue(val);
    },
    formated(val) {
      if (val && !isNaN(this.toNumber(val))) {
        this.$emit("input", this.toNumber(val));
      }
    },
  },
  computed: {
    currencyRules() {
      // if (this.$attrs.readonly) return [];

      let rules
      if (this.required)
        rules = [this.rules.required, this.rules.number, this.rules.positive];
      else
        rules = [this.rules.positive, this.rules.number];

      if (this.greaterThanZero) rules.push(this.rules.greaterThanZero)
      return rules;
    },
  },
};
</script>
