<template>
  <div>
    <template v-if="notFound">
      <v-alert
        text
        type="info"
        border="left"
        class="d-flex justify-center align-left"
        >Cuando registres tu primer cobro, aquí podrás realizar depósitos.
      </v-alert>
    </template>
    <template v-else>
      <base-form-card
        :loading="loading"
        :readMode="readMode"
        :editable="isEditable"
        :error="error"
        :alert="alert"
        @onEdit="readMode = false"
        @onDelete="remove()"
        @onRefresh="getDataFromApi()"
      >
        <v-form
          ref="form"
          v-model="isValidForm"
          id="myForm"
          @submit.prevent="save()"
        >
          <base-section-card title="Datos de depósito" class="mb-4">
            <v-row>
              <v-col cols="12" sm="6">
                <base-text-field
                  label="N° de boleta"
                  v-model="model.ballotNumber"
                  :rules="[rules.required]"
                  :readonly="readMode"
                  :error-messages="modelErrors['BallotNumber']"
                >
                </base-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <base-autocomplete
                  label="Ciudad"
                  item-text="name"
                  item-value="id"
                  v-model="model.cityId"
                  :readonly="readMode"
                  :error-messages="modelErrors['cityId']"
                  :rules="[rules.required]"
                  endpoint="departments/city"
                ></base-autocomplete>
              </v-col>
              <v-col cols="12" sm="6">
                <base-text-field
                  label="Financiera"
                  v-model="model.providerName"
                  :rules="[rules.required]"
                  :readonly="true"
                  :error-messages="modelErrors['providerName']"
                >
                </base-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-select
                  :items="bankAccounts"
                  item-text="bankName"
                  item-value="id"
                  label="Banco"
                  v-model="model.bankAccountId"
                  :rules="[rules.required]"
                  :readonly="readMode"
                  dense
                  outlined
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6">
                <base-text-field
                  label="N° de cuenta"
                  v-model="model.accountNumber"
                  :rules="[rules.required]"
                  :readonly="true"
                  :error-messages="modelErrors['AccountNumber']"
                >
                </base-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <base-text-field
                  label="Tipo de documento"
                  v-model="model.documentType"
                  :rules="[rules.required]"
                  :readonly="true"
                  :error-messages="modelErrors['DocumentType']"
                >
                </base-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <base-text-field
                  label="N° de documento"
                  v-model="model.documentNumber"
                  :rules="[rules.required]"
                  :readonly="true"
                  :error-messages="modelErrors['DocumentNumber']"
                >
                </base-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <base-currency-input
                  :greaterThanZero="true"
                  label="Monto depositado"
                  v-model="model.depositedAmount"
                  :required="true"
                  :error-messages="modelErrors['DepositedAmount']"
                >
                </base-currency-input>
              </v-col>
              <v-col cols="12" sm="6">
                <base-date-field
                  label="Fecha de depósito"
                  v-model="model.depositDate"
                  hint="dd/mm/aaaa"
                  :readonly="readMode"
                  :rules="[rules.required]"
                ></base-date-field>
              </v-col>
            </v-row>
          </base-section-card>
        </v-form>
        <file-uploader
          v-if="depositId"
          :endpoint="filesEndpoint"
          title="Boleta"
          :read-mode="readMode"
        ></file-uploader>
      </base-form-card>
    </template>
  </div>
</template>

<script>
import formMixin from "../mixins/formMixin";
import FileUploader from "../components/app/FileUploader";
export default {
  mixins: [formMixin],
  components: { FileUploader },
  data() {
    return {
      endpoint: "deposits",
      bankAccounts: [],
      wallet: {},
      notFound: false,
      depositId: undefined,
    };
  },
  methods: {
    getCurrentWallet() {
      const userId = this.getUserData().nameid;
      this.notFound = false;
      this.$http
        .get(`Wallets/Deposit/${userId}`)
        .then((resp) => {
          this.wallet = resp.data;
          this.model.walletId = this.wallet.id;
        })
        .catch((error) => {
          if (error.response.status == 404) this.notFound = true;
          else {
            if (error.response.status == 400) {
              window.console.error(error.response.data);
              this.$toast.error(error.response.data);
            } else {
              this.error = true;
              window.console.error(error);
              this.$toast.error("Por favor revisa tu conexión");
            }
            this.$router.push(this.parentPath);
          }
        })
        .finally(() => {});
    },
  },
  watch: {
    "model.cityId"() {
      if (this.model.cityId) {
        this.$http
          .get(`Deposits/checkif/${this.model.cityId}`)
          .then((resp) => {
            this.bankAccounts = resp.data;
            console.log(this.bankAccounts);
            if (this.bankAccounts.length > 0) {
              this.model.providerName = this.bankAccounts[0].providerName;
              this.model.providerId = this.bankAccounts[0].providerId;
            }
          })
          .catch((error) => {
            window.console.error(error);
            //this.$toast.warning(error.response.data);
          })
          .finally(() => {});
      }
    },
    "model.bankAccountId"() {
      if (this.model.bankAccountId) {
        console.log(this.model.bankAccountId);
        const resultado = this.bankAccounts.find(
          (ba) => ba.id == this.model.bankAccountId
        );
        //console.log(resultado);
        this.model.bankName = resultado.bankName;
        this.model.accountNumber = resultado.accountNumber;
        this.model.documentType = resultado.documentType;
        this.model.documentNumber = resultado.documentNumber;
      }
    },
    "model.depositedAmount"() {
      if (
        this.model.depositedAmount &&
        this.wallet != null &&
        this.wallet.currentTotalAmount &&
        !this.id
      ) {
        if (this.model.depositedAmount > this.wallet.currentTotalAmount)
          this.modelErrors = {
            DepositedAmount: [`Debe ser menor al saldo para depósito`],
          };
        else this.modelErrors = {};
      }
    },
  },
  computed: {
    filesEndpoint() {
      if (this.depositId) return `deposits/${this.depositId}/documents`;
      else return undefined;
    },
    isEditable() {
      var HasRole =
        this.userHasRole("Administrador") ||
        this.userHasRole("VC") ||
        this.userHasRole("Gestor_Administrativo");
      return HasRole;
    },
  },
  mounted() {
    var formatted_date = new Date().toJSON().slice(0, 10).replace(/-/g, "/");
    this.model.depositDate = formatted_date;
    //console.log("id " + this.id);
    if (!this.id) this.getCurrentWallet();
    else {
      this.depositId = this.id;
    }
  },
};
</script>

<style>
</style>