<template>
  <div>
    <base-form-card
        :loading="loading"
        :readMode="readMode"
        :editable="false"
        :error="error"
        :alert="alert"
        :submit-btn-enabled="false"
        @onEdit="readMode = false"
        @onDelete="remove()"
        @onRefresh="getDataFromApi()"
    >

      <base-section-card title="Datos de la Visita" class="mb-4">
        <v-row>
          <v-col cols="12" sm="6">
            <base-text-field
                label="Nombre del local"
                v-model="pointOfSale.localName"
                :readonly="readMode"
            >
            </base-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <base-text-field
                label="Nombre del Propietario"
                v-model="pointOfSale.ownerName"
                :readonly="readMode"
            >
            </base-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <base-text-field
                label="Teléfono"
                v-model="pointOfSale.phoneNumber"
                prepend-inner-icon="mdi-cellphone"
                :readonly="readMode"
                :append-icon="validWhatsappNumber ? 'mdi-whatsapp' : ''"
                @click:append="sendWhatsapp()"
            ></base-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <base-text-field
                label="Email"
                type="email"
                v-model="pointOfSale.email"
                prepend-inner-icon="mdi-email"
                :rules="[rules.email]"
                :readonly="readMode"
                :error-messages="modelErrors['email']"
            >
            </base-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <base-text-field
                label="Dirección"
                v-model="pointOfSale.address"
                :readonly="readMode"
            >
            </base-text-field>
          </v-col>

          <v-col cols="12" sm="6">
            <base-text-field
                label="Referencia"
                v-model="pointOfSale.addressReference"
                :readonly="readMode"
            >
            </base-text-field>
          </v-col>

          <v-col cols="12" sm="6">
            <base-autocomplete
                label="Ciudad"
                item-text="name"
                item-value="id"
                v-model="pointOfSale.cityId"
                :readonly="readMode"
                endpoint="departments/city"
            ></base-autocomplete>
          </v-col>

          <v-col cols="12" sm="6">
            <base-text-field
                label="Barrio"
                v-model="pointOfSale.neighborhood"
                :readonly="readMode"
            >
            </base-text-field>
          </v-col>
        </v-row>

      </base-section-card>
      <base-section-card title="Detalles de la Solicitud" class="mb-4" v-if="model.interestOnCredit">
        <v-row>
          <v-col cols="12" sm="6">
            <base-autocomplete
                label="Entidad Financiera"
                item-text="name"
                item-value="id"
                v-model="creditRequest.financialId"
                :readonly="true"
                endpoint="providers"
            ></base-autocomplete>
          </v-col>
          <v-col cols="12" sm="6">
            <base-autocomplete
                label="Estado de la Solicitud"
                item-text="name"
                item-value="id"
                v-model="creditRequest.creditStatusId"
                :readonly="readMode"
                :rules="[rules.required]"
                endpoint="creditRequest/CreditStatus"
            ></base-autocomplete>
          </v-col>

          <v-col cols="12" sm="6">
            <base-autocomplete
                label="Tipo de Solicitud"
                item-text="name"
                item-value="id"
                v-model="creditRequest.creditTypeId"
                :readonly="readMode"
                :rules="[rules.required]"
                endpoint="creditRequest/TypesCredits"
            ></base-autocomplete>
          </v-col>
          <template v-if="creditRequest.creditTypeId && creditRequest.creditTypeId == 2">
            <v-col cols="12" sm="4">
              <base-autocomplete
                  label="Articulo"
                  item-text="name"
                  item-value="id"
                  v-model="creditRequest.productId"
                  :readonly="readMode"
                  endpoint="products"
              ></base-autocomplete>
            </v-col>
            <v-col cols="12" sm="2">
              <base-text-field
                  label="Código"
                  v-model="creditRequest.productCode"
                  :readonly="true"
                  type="number"
              ></base-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <base-autocomplete
                  label="Proveedor"
                  item-text="name"
                  item-value="id"
                  v-model="creditRequest.providerId"
                  :readonly="readMode"
                  :rules="[rules.required]"
                  endpoint="providers/common-providers"
              ></base-autocomplete>
            </v-col>
          </template>
          <v-col cols="12" sm="6">
            <base-currency-input
                label="Monto del Crédito (Gs.)"
                v-model="creditRequest.creditAmount"
                :required="true"
                :readonly="readMode">
            </base-currency-input>
          </v-col>
          <v-col cols="12" sm="6">
            <base-currency-input
                :greaterThanZero="true"
                label="Monto de cuota (Gs.)"
                v-model="creditRequest.feeAmount"
                :required="true"
                :readonly="readMode">
            </base-currency-input>
          </v-col>
          <v-col cols="12" sm="6">
            <base-text-field
                label="Nro. de Cuotas"
                v-model="creditRequest.numberOfQuotas"
                :rules="[rules.required]"
                :readonly="readMode"
                type="number"
            >
            </base-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <base-autocomplete
                label="Frecuencia de Pago"
                item-text="name"
                item-value="id"
                v-model="creditRequest.paymentFrequencyId"
                :readonly="readMode"
                :rules="[rules.required]"
                endpoint="paymentFrecuencies"
            ></base-autocomplete>
          </v-col>
          <v-col cols="12" sm="6">
            <base-autocomplete
                v-if="creditRequest.paymentFrequencyId && creditRequest.paymentFrequencyId != 3"
                label="Día de vencimiento de la cuota"
                item-text="name"
                item-value="id"
                v-model="model.quotaExpirationDay"
                endpoint="creditRequest/weekdays?excludeDay=Domingo"
                :rules="[rules.required]"
                :readonly="readMode"
            ></base-autocomplete>
            <base-text-field
                v-else
                label="Día de vencimiento de la cuota"
                v-model="creditRequest.quotaExpirationDay"
                :rules="[rules.required]"
                :readonly="readMode"
                :error-messages="modelErrors['quotaExpirationDay']"
                type="number"
            >
            </base-text-field>
          </v-col>
        </v-row>
      </base-section-card>

      <v-row>
        <v-col cols="12" sm="12" v-if="model.longitude && model.latitude">
          <iframe
              width="100%"
              height="350"
              :src="`https://www.openstreetmap.org/export/embed.html?bbox=${model.longitude}%2C${model.latitude}%2C;layer=mapnik&amp;marker=${model.latitude}%2C${model.longitude}`">
          </iframe>
        </v-col>
      </v-row>
    </base-form-card>
  </div>
</template>

<script>
import formMixin from "../mixins/formMixin";
import router from "../router";

export default {
  name: "VisitDetailForm",
  mixins: [formMixin],
  data() {
    return {
      endpoint: 'offerVisits',
      readMode: true,
      pointOfSale: {},
      creditRequest: {},
    }
  },
  methods: {
    getPointOfSale() {
      this.loading = true;
      this.error = false;
      this.$http
          .get(`pointOfSale/${this.model.pointOfSaleId}`)
          .then((resp) => {
            this.pointOfSale = resp.data;
          })
          .catch((error) => {
            window.console.error(error);
            if (!error.response) {
              this.error = true;
              return;
            }
            if (error.response.status === 404) router.push(".");
          })
          .finally(() => {
            this.loading = false;
          });
    },
    getCreditRequest() {
      this.loading = true;
      this.error = false;
      this.$http
          .get(`creditRequest/visit/${this.id}`)
          .then((resp) => {
            this.creditRequest = resp.data;
          })
          .catch((error) => {
            window.console.error(error);
            if (!error.response) {
              this.error = true;
              return;
            }
          })
          .finally(() => {
            this.loading = false;
          });
    },
  },
  watch: {
    'model.id'() {
      if (this.model && this.model.id) {
        this.getPointOfSale();
        if (this.model.interestOnCredit) {
          this.getCreditRequest();
        }
      }
    }
  },
}
</script>

<style scoped>

</style>