<template>
  <v-text-field
    v-bind="$attrs"
    v-on="$listeners"
    :clearable="!$attrs.readonly"
    validate-on-blur
    aria-autocomplete="off"
    autocomplete="off"
    outlined
    dense
    hide-details="auto"
  ></v-text-field>
</template>

<script>
export default {
  props: {}
  // mas ejemplos de wrapping components:
  // https://www.smashingmagazine.com/2017/08/creating-custom-inputs-vue-js/
};
</script>

<style lang="scss" scoped>
</style>
