export default {
  methods: {
    toCurrency(val, n, x) {
      if (!val) return val;
      var re = "(\\d)(?=(\\d{" + (x || 3) + "})+" + (n > 0 ? "\\." : "$") + ")";
      return val.toFixed(Math.max(0, ~~n)).replace(new RegExp(re, "g"), "$1.");
    },
    toNumber(val) {
      return typeof val === "number"
        ? val
        : +val.split(".").join("");
    },
    toDateString(date) {
      if (!date) return null;
      date = date.substring(0, 10)
      if (this.type === "month") {
        const [year, month] = date.split('-');
        return `${month}/${year}`;
      }
      const [year, month, day] = date.split('-');
      return `${day}/${month}/${year}`;
    },
    toDateTimeString(date) {
      if (!date) return null;
      date = new Date(date);
      return date.toLocaleString('es-PY', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
      })
    }
  },
};
