import Vue from 'vue';
import Vuex from 'vuex';
import { LOGIN } from '../constants';
import axios from 'axios';
import router from "../router";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: null,
    items: [],
  },
  mutations: {
    setLogin(state, user) {
      state.user = user;
      localStorage.setItem(LOGIN, JSON.stringify(user));
      axios.defaults.headers.common['Authorization'] = `Bearer ${user.token}`;
    },
    removeLogin(state) {
      state.user = null;
      localStorage.removeItem(LOGIN);
      router.push({ name: 'login' });
      // window.location.reload();
    },
    setItem(state, item) {
      let [exitsItem] = state.items.filter(x => x.key == item.key);
      let index = 0;
      if (exitsItem) {
        index = state.items.indexOf(exitsItem);
        state.items.splice(index, 1, item);
      } else {
        state.items.splice(index, 0, item);
      }
    },
  },
  actions: {
    login({ commit }, user) {
      commit('setLogin', user);
    },
    logout({ commit }) {
      commit('removeLogin', null);
    },
    autologin({ commit }) {
      let user = JSON.parse(localStorage.getItem(LOGIN));
      if (user) {
        commit('setLogin', user);
      }
    }
  },
  modules: {}
});
