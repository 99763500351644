<template>
  <div>
    <base-section-card title="Mi billetera" :loading="loading">
      <template v-slot:actions v-if="wallet && wallet.currentTotalAmount == 0">
        <v-btn class="primary" @click="dialog = true">Generar Rendición</v-btn>
      </template>
      <template v-if="error">
        <v-alert
            type="error"
            border="left"
            class="d-flex justify-center align-left"
        >Verifique su conexión e intente nuevamente.
        </v-alert>
        <v-btn color="info" @click="getCurrentWallet()">Reintentar
          <v-icon right>mdi-refresh</v-icon>
        </v-btn>
      </template>
      <template v-else-if="notFound">
        <v-alert
            text
            type="info"
            border="left"
            class="d-flex justify-center align-left"
        >Cuando registres tu primer cobro, aquí veras el resumen de tu billetera.
        </v-alert>
      </template>
      <template v-else>
        <base-section-card title="Resumen" class="mb-4">
          <v-row>
            <v-col cols="12" sm="6">
              <h3>Fecha de Apertura</h3>
              {{ toDateTimeString(wallet.walletDate) }}hs.
            </v-col>
            <v-col cols="12" sm="6">
              <h3>Límite de Cobranzas</h3>
              Gs. {{ toCurrency(wallet.limitAmount) }}
            </v-col>
            <v-col cols="12" sm="6">
              <h3>Total Cobrado</h3>
              Gs. {{ toCurrency(wallet.totalAmountCharged) }}
            </v-col>
            <v-col cols="12" sm="6">
              <h3>Monto sin depositar</h3>
              Gs. {{ toCurrency(wallet.currentTotalAmount) }}
            </v-col>
          </v-row>
        </base-section-card>
        <base-section-card title="Cobranzas" class="mb-4">
          <v-data-table
              disable-sort
              disable-pagination
              hide-default-footer
              :headers="quotaPaymentHeaders"
              :items="wallet.quotaPayments"
              :loading="loading"
          >
            <template v-slot:item.amount="{ item }">
              {{ toCurrency(item.amount) }}
            </template>
          </v-data-table>
        </base-section-card>
        <base-section-card title="Depósitos">
          <v-data-table
              disable-sort
              disable-pagination
              hide-default-footer
              :headers="depositHeaders"
              :items="wallet.bankDeposits"
              :loading="loading"
          >
            <template v-slot:item.depositedAmount="{ item }">
              {{ toCurrency(item.depositedAmount) }}
            </template>
          </v-data-table>
        </base-section-card>
      </template>
    </base-section-card>

    <base-dialog
        width="500"
        v-model="dialog"
        title="Generar rendición"
        text="Declaro bajo fe de juramento que los datos consignados en esta aplicación están correctos y verificados"
        actionText="Confirmar"
        @onCancel="dialog = false"
        @onConfirm="closeWallet()"
    ></base-dialog>

    <v-overlay :value="downloading">
      <v-dialog v-model="downloading" hide-overlay persistent width="300">
        <v-card color="primary" dark>
          <v-card-text class="pt-2">
            Descargando Rendición
            <v-progress-linear indeterminate color="white"></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-overlay>

  </div>
</template>

<script>
import authMixin from "../mixins/authMixin";
import formatters from "../mixins/formatters";

export default {
  name: "WalletSummary",
  mixins: [authMixin, formatters],
  props: {
    id: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      wallet: {},
      loading: false,
      error: false,
      notFound: false,
      dialog: false,
      downloading: false,
      quotaPaymentHeaders: [
        {text: "Fecha", value: "dateOfPaymentOfQuota"},
        {text: "Hora", value: "paymentTime"},
        {text: "Proveedor", value: "pointOfSale"},
        {text: "Nro. Cuota", value: "quotaNumber"},
        {text: "Monto Cobrado", value: "amount"},
      ],
      depositHeaders: [
        {text: "Fecha", value: "onlyDepositDate"},
        {text: "Hora", value: "depositTime"},
        {text: "Banco", value: "bankName"},
        {text: "Nro. Cuenta", value: "accountNumber"},
        {text: "Monto Depositado", value: "depositedAmount"},
      ],
    }
  },
  mounted() {
    this.getCurrentWallet();
  },
  methods: {
    closeWallet() {
      this.downloading = true;
      this.dialog = false;
      this.$http
          .put(`Wallets/close/${this.wallet.id}`)
          .then(() => {
            this.notFound = true;
            this.downloadReport(this.wallet.id);
            this.wallet = {};
          })
          .catch((error) => {
            window.console.error(error);
            this.$toast.error("No se pudo descargar el reporte de rendición");
          })
          .finally(() => {
            this.downloading = false;
          });
    },
    downloadReport(walletId) {
      this.downloading = true;
      this.$http
          .get(`Wallets/${walletId}/pdf`, {responseType: "blob",})
          .then((response) => {
            const blob = new Blob([response.data]);
            const fileURL = window.URL.createObjectURL(blob);
            const fileLink = document.createElement("a");
            let fileName = response.headers["content-disposition"]
                .split("filename=")[1]
                .split(";")[0]
                .split('"')
                .join("");

            fileLink.href = fileURL;
            fileLink.setAttribute("download", fileName);
            document.body.appendChild(fileLink);
            fileLink.click();
          })
          .catch((error) => {
            window.console.error(error);
            this.$toast.error("No se pudo descargar el reporte de rendición");
          })
          .finally(() => {
            this.downloading = false;
          });
    },
    getCurrentWallet() {
      this.loading = true;
      this.error = false;
      this.notFound = false;
      const userId = this.getUserData().nameid;
      this.$http
          .get(`Wallets/User/${userId}`)
          .then((resp) => {
            this.wallet = resp.data
          })
          .catch((error) => {
            if (error.response.status == 404)
              this.notFound = true;
            else {
              this.error = true;
              window.console.error(error);
            }
          })
          .finally(() => {
            this.loading = false;
          });
    }
  },
}
</script>

<style scoped>

</style>