<template>
  <div>
    <base-card>
      <v-card-text class="mx-center">
        <v-row justify="space-between" dense>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="search"
              label="Buscar"
              flat
              dense
              filled
              single-line
              hide-details
              prepend-inner-icon="mdi-magnify"
              autocomplete="off"
            ></v-text-field>
          </v-col>
          <v-col sm="3">
            <v-select
              label="Filtros"
              prepend-icon="mdi-filter-menu"
              :items="filters"
              item-text="name"
              item-value="value"
              outlined
              dense
              hide-details="auto"
              v-model="filter"
            ></v-select>
          </v-col>
        </v-row>

        <v-data-table
          loading-text="Cargando..."
          disable-sort
          :items="items"
          :headers="headers"
          :loading="loading"
          :search="search"
          :items-per-page="itemsPerPage"
        >
          <template v-slot:item.actions="{ item }">
            <v-btn
              @click="downloadFile(item.id, item.providerId)"
              text
              outlined
              class="ma-2"
              :disabled="loading"
              icon
            >
              <v-icon>mdi-download</v-icon>
            </v-btn>
          </template>
          <template v-slot:no-data>
            <v-container>
              <template v-if="error">
                <v-alert type="error">
                  Verifique su conexión e intente nuevamente.
                </v-alert>
                <v-btn color="info" @click="getItems()">
                  Reintentar
                  <v-icon right>mdi-refresh</v-icon>
                </v-btn>
              </template>
              <template v-else>No se encontraron datos</template>
            </v-container>
          </template>
        </v-data-table>
      </v-card-text>
    </base-card>
  </div>
</template>

<script>
import { DEFAULT_PAGE_SIZE } from "../constants";
import authMixin from "../mixins/authMixin";

export default {
  name: "CatalogList",
  mixins: [authMixin],
  data() {
    return {
      title: "Catálogos",
      loading: false,
      error: false,
      items: [],
      search: "",
      filter: "",
      endpoint: "providers/catalogs",
      headers: [
        { text: "Proveedor", value: "providerName" },
        { text: "Catálogo", value: "catalogName" },
        { text: "", value: "actions" },
      ],
      filters: [
        { name: "Entidades financieras", value: "1" },
        { name: "Otras entidades", value: "2" },
        { name: "Todos", value: "3" },
      ],
      itemsPerPage: DEFAULT_PAGE_SIZE,
    };
  },
  mounted() {
    if (this.userHasRole("VC"))
      this.endpoint += "?&vcId=" + this.getUserData().nameid;

    if (this.userHasRole("PDV"))
      this.endpoint += "?&pdvUserId=" + this.getUserData().nameid;

    this.getItems();
  },
  methods: {
    getItems() {
      this.loading = true;
      this.error = false;
      this.items = [];
      this.$http
        .get(this.endpoint)
        .then((resp) => {
          if (resp.status === 200) {
            this.items = resp.data;
          }
        })
        .catch((error) => {
          this.error = true;
          window.console.error(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async downloadFile(fileId, providerId) {
      this.loading = true;
      this.$http
        .get(`providers/${providerId}/catalogs/${fileId}`, {
          responseType: "blob",
        })
        .then((response) => {
          const blob = new Blob([response.data]);
          const fileURL = window.URL.createObjectURL(blob);
          const fileLink = document.createElement("a");
          let fileName = response.headers["content-disposition"]
            .split("filename=")[1]
            .split(";")[0]
            .split('"')
            .join("");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", fileName);
          document.body.appendChild(fileLink);
          fileLink.click();
          this.$toast.success("Descarga exitosa");
        })
        .catch((error) => {
          window.console.error(error);
          this.$toast.error("No se pudo descargar el archivo");
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  watch: {
    filter(newValue) {
      if (newValue) {
        //console.log("dasd " + newValue);
        if (newValue == 1) {
          
          if (this.userHasRole("VC"))
            this.endpoint = "providers/catalogs?financialEntityOnly=true&vcId=" + this.getUserData().nameid;
          else if (this.userHasRole("PDV"))
            this.endpoint ="providers/catalogs?financialEntityOnly=true&pdvUserId=" + this.getUserData().nameid;
          else 
            this.endpoint = "providers/catalogs?financialEntityOnly=true";

        } else if (newValue == 2) {
         
         if (this.userHasRole("VC"))
            this.endpoint = "providers/catalogs?nonFinancialEntities=true&vcId=" + this.getUserData().nameid;
          else if (this.userHasRole("PDV"))
            this.endpoint = "providers/catalogs?nonFinancialEntities=true&pdvUserId=" +this.getUserData().nameid;
          else 
            this.endpoint = "providers/catalogs?nonFinancialEntities=true";

        } else {
          
          if (this.userHasRole("VC"))
              this.endpoint = "providers/catalogs?&vcId=" + this.getUserData().nameid;
          else if (this.userHasRole("PDV"))
              this.endpoint = "providers/catalogs?&pdvUserId=" + this.getUserData().nameid;
          else 
              this.endpoint = "providers/catalogs";
        }
      } 
      else {
          if (this.userHasRole("VC"))
              this.endpoint = "providers/catalogs?&vcId=" + this.getUserData().nameid;
          else if (this.userHasRole("PDV"))
              this.endpoint = "providers/catalogs?&pdvUserId=" + this.getUserData().nameid;
          else 
              this.endpoint = "providers/catalogs";
      }
    },
    endpoint() {
      this.getItems();
    },
  },
};
</script>

<style scoped>
</style>