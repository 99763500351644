<template>
  <div>
    <base-form-card
      :loading="loading"
      :readMode="readMode"
      :editable="editable"
      :error="error"
      :alert="alert"
      @onEdit="readMode = false"
      @onDelete="remove()"
      @onRefresh="getDataFromApi()"
    >
      <v-form
        ref="form"
        v-model="isValidForm"
        id="myForm"
        @submit.prevent="save()"
      >
        <base-section-card title="Datos Básicos" class="mb-4">
          <v-row>
            <v-col cols="12" sm="6">
              <base-text-field
                label="Marca de bebida"
                v-model="model.name"
                :rules="[rules.required]"
                :readonly="readMode"
                :error-messages="modelErrors['Name']"
              >
              </base-text-field>
            </v-col>
          </v-row>
        </base-section-card>
      </v-form>
    </base-form-card>
  </div>
</template>

<script>
import formMixin from "../mixins/formMixin";

export default {
  mixins: [formMixin],
  data() {
    return {
      endpoint: "DrinkBrands",
    };
  },
  methods: {},
  watch: {},
  computed: {},
};
</script>

<style>
</style>