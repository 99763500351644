<template>
  <div>
    <base-form-card
        :loading="loading"
        :readMode="readMode"
        :editable="false"
        :error="error"
        :alert="alert"
        :submit-btn-enabled="false"
        @onEdit="readMode = false"
        @onDelete="remove()"
        @onRefresh="getDataFromApi()"
    >

      <base-section-card title="Datos del PDV" class="mb-4">
        <v-row>
          <v-col cols="12" sm="6">
            <base-text-field
                label="Nombre del local"
                v-model="pointOfSale.localName"
                :readonly="readMode"
            >
            </base-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <base-text-field
                label="Nombre del Propietario"
                v-model="pointOfSale.ownerName"
                :readonly="readMode"
            >
            </base-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <base-text-field
                label="Teléfono"
                v-model="pointOfSale.phoneNumber"
                prepend-inner-icon="mdi-cellphone"
                :readonly="readMode"
                :append-icon="validWhatsappNumber ? 'mdi-whatsapp' : ''"
                @click:append="sendWhatsapp()"
            ></base-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <base-text-field
                label="Email"
                type="email"
                v-model="pointOfSale.email"
                prepend-inner-icon="mdi-email"
                :rules="[rules.email]"
                :readonly="readMode"
                :error-messages="modelErrors['email']"
            >
            </base-text-field>
          </v-col>

        </v-row>

      </base-section-card>
      <base-section-card title="Datos del Crédito" class="mb-4">
        <v-row>
          <v-col cols="12" sm="6">
            <base-autocomplete
                label="Entidad Financiera"
                item-text="name"
                item-value="id"
                v-model="creditRequest.financialId"
                :readonly="true"
                endpoint="providers"
            ></base-autocomplete>
          </v-col>
          <v-col cols="12" sm="6">
            <base-autocomplete
                label="Tipo de Solicitud"
                item-text="name"
                item-value="id"
                v-model="creditRequest.creditTypeId"
                :readonly="readMode"
                :rules="[rules.required]"
                endpoint="creditRequest/TypesCredits"
            ></base-autocomplete>
          </v-col>
          <template v-if="creditRequest.creditTypeId && creditRequest.creditTypeId == 2">
            <v-col cols="12" sm="4">
              <base-autocomplete
                  label="Articulo"
                  item-text="name"
                  item-value="id"
                  v-model="creditRequest.productId"
                  :readonly="readMode"
                  endpoint="products"
              ></base-autocomplete>
            </v-col>
            <v-col cols="12" sm="2">
              <base-text-field
                  label="Código"
                  v-model="creditRequest.productCode"
                  :readonly="true"
                  type="number"
              ></base-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <base-autocomplete
                  label="Proveedor"
                  item-text="name"
                  item-value="id"
                  v-model="creditRequest.providerId"
                  :readonly="readMode"
                  :rules="[rules.required]"
                  endpoint="providers/common-providers"
              ></base-autocomplete>
            </v-col>
          </template>
          <v-col cols="12" sm="6">
            <base-currency-input
                label="Monto del Crédito (Gs.)"
                v-model="creditRequest.creditAmount"
                :required="true"
                :readonly="readMode">
            </base-currency-input>
          </v-col>
          <v-col cols="12" sm="6">
            <base-currency-input
                :greaterThanZero="true"
                label="Monto de cuota (Gs.)"
                v-model="creditRequest.feeAmount"
                :required="true"
                :readonly="readMode">
            </base-currency-input>
          </v-col>
        </v-row>
      </base-section-card>

      <base-section-card title="Detalle de la Cobranza">
        <v-data-table
            :headers="quotaTableHeader"
            :items="model.paymentOfQuotas"
            fixed-header
            class="elevation-0"
            disable-sort
            disable-pagination
            hide-default-footer
        >
          <template v-slot:item.amount="{ item }">
            {{ toCurrency(item.amount) }}
          </template>
          <template v-slot:item.paymentDate="{ item }">
            {{ toDateString(item.paymentDate) }}
          </template>

        </v-data-table>
      </base-section-card>
    </base-form-card>
  </div>
</template>

<script>
import formMixin from "../mixins/formMixin";

export default {
  name: "QuotaPaymentDetailForm",
  mixins: [formMixin],
  data() {
    return {
      endpoint: 'QuotaCollection',
      readMode: true,
      pointOfSale: {},
      creditRequest: {},
      scheduledVisit: {},
      quotaTableHeader: [
        {text: 'Nro. Cuota', value: 'quotaNumber'},
        {text: 'Fecha del Pago', value: 'paymentDate'},
        {text: 'Importe', value: 'amount'},
      ],
    }
  },
  methods: {
    async afterGetModel() {
      await this.getCreditRequest();
      await this.getPointOfSale();
    },
    async getPointOfSale() {
      this.loading = true;
      this.error = false;
      await this.$http
          .get(`pointOfSale/${this.creditRequest.pointOfSaleId}`)
          .then((resp) => {
            this.pointOfSale = resp.data;
          })
          .catch((error) => {
            window.console.error(error);
            if (!error.response) {
              this.error = true;
              return;
            }
          })
          .finally(() => {
            this.loading = false;
          });
    },
    async getCreditRequest() {
      this.loading = true;
      this.error = false;
      await this.$http
          .get(`creditRequest/${this.model.creditRequestId}`)
          .then((resp) => {
            this.creditRequest = resp.data;
          })
          .catch((error) => {
            window.console.error(error);
            if (!error.response) {
              this.error = true;
              return;
            }
          })
          .finally(() => {
            this.loading = false;
          });
    },
  },
}
</script>

<style scoped>

</style>