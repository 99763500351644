<template>
  <div>
    <v-row justify="center" align="center" class="text-center">
      <v-col cols="12">
        <v-card class="pa-5" outlined>
          <v-card-title class="d-flex justify-space-around">
            <v-row dense align="center" justify="center">
              <v-btn
                outlined
                class="mr-4"
                color="secondary"
                @click="value = today"
              >
                Hoy
              </v-btn>
              <v-col cols="8" sm="3" xl="2">
                <v-select
                  dense
                  outlined
                  class="shrink"
                  :items="types"
                  v-model="type"
                  hide-details
                >
                </v-select>
              </v-col>
              <v-btn icon class="ma-2" small @click="$refs.calendar.prev()"
                ><v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn icon class="ma-2" small @click="$refs.calendar.next()"
                ><v-icon>mdi-chevron-right</v-icon>
              </v-btn>
              {{ title }}
            </v-row>
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>
    <v-card :loading="cargando" outlined>
      <v-container>
        <v-sheet :height="type == 'month' ? 550 : null">
          <v-calendar
            ref="calendar"
            v-model="value"
            :events="events"
            :event-overlap-threshold="30"
            :type="type"
            color="primary"
            @click:more="showMore"
            @click:date="showMore"
            @click:event="showEvent"
          ></v-calendar>
        </v-sheet>
      </v-container>
    </v-card>
  </div>
</template>
<script>
import { MESES_STRING } from "../../constants";
import authMixin from "../../mixins/authMixin";

export default {
  name: "Calendar",
  mixins: [authMixin],
  data: () => ({
    type: "month",
    types: [
      { text: "Día", value: "day" },
      { text: "Semana", value: "week" },
      { text: "Mes", value: "month" },
    ],
    today: "",
    value: "",
    events: [],
    cargando: false,
    currentMonth: null,
    currentYear: null,
    calendarStateKey: "calendar",
    filterBySeller: "",
  }),
  mounted() {
    this.today = this.$refs.calendar.getNow().date;
    const [calendarState] = this.$store.state.items.filter(
      (x) => x.key == this.calendarStateKey
    );
    this.value = calendarState ? calendarState.value.value : this.today;
    this.type = calendarState ? calendarState.value.type : "month";
    this.updateCurrentValues();
    if (this.userHasRole("VC"))
      this.filterBySeller = "?&vcId=" + this.getUserData().nameid;
    this.getEvents();
  },
  methods: {
    getEvents() {
      this.cargando = true;
      this.$http
        .get(
          `ScheduledVisit/calendar/${this.value.split("-")[1]}/${
            this.value.split("-")[0]
          }` + this.filterBySeller
        )
        .then((resp) => {
          this.events = resp.data;
        })
        .catch((err) => {
          window.console.error(err);
          this.$toast.error("Por favor, revisa tu conexión");
        })
        .finally(() => {
          this.cargando = false;
        });
    },
    showEvent({ event }) {
      this.$router.push({
        path: `quotaPayments/create/${event.id.toString()}`,
      });
    },
    showMore(event) {
      this.value = event.date;
      this.type = "day";
    },
    updateCurrentValues() {
      if (this.value) {
        this.currentYear = this.value.split("-")[0];
        this.currentMonth = this.value.split("-")[1];
      } else {
        this.currentYear = this.today.split("-")[0];
        this.currentMonth = this.today.split("-")[1];
      }
    },
  },
  watch: {
    value(val) {
      if (val) {
        const [selectedYear, selectedMonth] = val.split("-");
        if (
          this.currentMonth != selectedMonth ||
          this.currentYear != selectedYear
        )
          this.getEvents();
        this.$store.commit("setItem", {
          key: "calendar",
          value: { value: this.value, type: this.type },
        });
        this.updateCurrentValues();
      }
    },
    type(val) {
      if (val) {
        const [selectedYear, selectedMonth] = val.split("-");
        if (
          this.currentMonth != selectedMonth ||
          this.currentYear != selectedYear
        )
          this.$store.commit("setItem", {
            key: "calendar",
            value: { value: this.value, type: this.type },
          });
      }
    },
  },
  computed: {
    title() {
      if (this.value) {
        const [year, month] = this.value.split("-");
        return `${MESES_STRING[month - 1]} ${year}`;
      } else return "";
    },
  },
};
</script>