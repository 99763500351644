<template>
  <div>
    <base-form-card
        :loading="loading"
        :readMode="readMode"
        :editable="false"
        :error="error"
        :alert="alert"
        @onEdit="true"
        @onRefresh="getDataFromApi()"
    >
      <v-form
          ref="form"
          v-model="isValidForm"
          id="myForm"
          @submit.prevent="save()"
      >
        <base-section-card title="Datos del Vendedor" class="mb-4">
          <v-row>
            <v-col cols="12" sm="4">
                <base-text-field
                    label="Nombre"
                    v-model="model.firstName"
                    :rules="[rules.required]"
                    :readonly="true"
                    :error-messages="modelErrors['FirstName']"
                >
                </base-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <base-text-field
                  label="Apellido"
                  v-model="model.lastName"
                  :rules="[rules.required]"
                  :readonly="true"
                  :error-messages="modelErrors['LastName']"
              >
              </base-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <base-text-field
                  label="Nro. Documento"
                  v-model="model.documentNumber"
                  :readonly="true"
              >
              </base-text-field>
            </v-col>
          </v-row>
        </base-section-card>
        <collection-limit-table v-model="model.collectionLimits"></collection-limit-table>
      </v-form>
    </base-form-card>
  </div>
</template>

<script>
import formMixin from "../mixins/formMixin";
import CollectionLimitTable from '../components/app/CollectionLimitTable.vue';

export default {
  components: { CollectionLimitTable },
  mixins: [formMixin],
  data() {
    return {
      endpoint: "users",
    };
  },
  mounted() {
    this.readMode = false;
  },
  watch: {

  },
  computed: {

  },
};
</script>

<style>
</style>