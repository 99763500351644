<template>
  <div>
    <base-form-card
      :loading="loading"
      :readMode="readMode"
      :editable="editable"
      :error="error"
      :alert="alert"
      @onEdit="readMode = false"
      @onDelete="remove()"
      @onRefresh="getDataFromApi()"
    >
      <v-stepper v-model="step">
        <v-stepper-header>
          <v-stepper-step :complete="step > 1" step="1">
            Datos del PDV
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :complete="step > 2" step="2">
            Adjuntar Documentos
          </v-stepper-step>
          <v-btn
            color="primary"
            @click="registerPdVUser()"
            class="ma-4"
            v-if="step == 1"
          >
            Siguiente
          </v-btn>
        </v-stepper-header>
        <v-stepper-items>
          <v-stepper-content step="1">
            <v-form
              ref="form"
              v-model="isValidForm"
              id="myForm"
              @submit.prevent="registerPdVUser()"
            >
              <base-section-card title="Datos del PDV" class="mb-4">
                <v-row>
                  <v-col cols="12" sm="6">
                    <base-text-field
                      label="Nombre del local"
                      v-model="model.localName"
                      :rules="[rules.required]"
                      :readonly="readMode"
                    >
                    </base-text-field>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <base-text-field
                      label="Nombre del Propietario"
                      v-model="model.ownerName"
                      :readonly="readMode"
                    >
                    </base-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <base-text-field
                      label="Apellido"
                      v-model="model.lastName"
                      :rules="[rules.required]"
                      :readonly="readMode"
                      :error-messages="modelErrors['lastName']"
                    >
                    </base-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <base-text-field
                      label="Username"
                      v-model="model.username"
                      prepend-inner-icon="mdi-account"
                      :rules="[rules.required]"
                      :readonly="readMode"
                      :error-messages="modelErrors['username']"
                    >
                    </base-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <base-text-field
                      label="Antigüedad (años)"
                      v-model="model.age"
                      :required="true"
                    >
                    </base-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <base-text-field
                      label="Teléfono"
                      v-model="model.phoneNumber"
                      prepend-inner-icon="mdi-cellphone"
                      :readonly="readMode"
                      :rules="[rules.required, rules.phoneNumber]"
                      :append-icon="validWhatsappNumber ? 'mdi-whatsapp' : ''"
                      @click:append="sendWhatsapp()"
                    ></base-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <base-text-field
                      label="Email"
                      type="email"
                      v-model="model.email"
                      prepend-inner-icon="mdi-email"
                      :rules="[rules.required, rules.email]"
                      :readonly="readMode"
                      :error-messages="modelErrors['email']"
                    >
                    </base-text-field>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <base-select
                      label="Tipo de local PDV"
                      item-text="name"
                      item-value="id"
                      v-model="model.pointOfSaleTypeId"
                      :readonly="readMode"
                      endpoint="pointOfSaleTypes"
                    ></base-select>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <base-select
                      label="Tamaño de PDV"
                      item-text="name"
                      item-value="id"
                      v-model="model.pointOfSaleSizeId"
                      :readonly="readMode"
                      endpoint="pointSaleSizes"
                    ></base-select>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <base-select
                      label="Red de cobro"
                      item-text="name"
                      item-value="id"
                      v-model="model.paymentNetworkId"
                      :readonly="readMode"
                      endpoint="paymentNetworks"
                    ></base-select>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <base-text-field
                      label="Dirección"
                      v-model="model.address"
                      :readonly="readMode"
                    >
                    </base-text-field>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <base-text-field
                      label="Referencia"
                      v-model="model.addressReference"
                      :readonly="readMode"
                    >
                    </base-text-field>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <base-autocomplete
                      label="Ciudad"
                      item-text="name"
                      item-value="id"
                      v-model="model.cityId"
                      :readonly="readMode"
                      endpoint="departments/city"
                      :rules="[rules.required]"
                    ></base-autocomplete>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <base-text-field
                      label="Barrio"
                      v-model="model.neighborhood"
                      :readonly="readMode"
                    >
                    </base-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <base-text-field
                      label="Cantidad de góndolas"
                      v-model="model.numberOfGondolas"
                      :required="true"
                    >
                    </base-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <base-select
                      label="Día de visita"
                      item-text="name"
                      item-value="id"
                      v-model="model.visitDayId"
                      :readonly="readMode"
                      :rules="[rules.required]"
                      endpoint="collectionLimits/days"
                    ></base-select>
                  </v-col>
                  <v-col cols="12" sm="6" v-if="showFieldVC">
                    <base-text-field
                      label="VC"
                      v-model="model.seller"
                      :readonly="true"
                    >
                    </base-text-field>
                  </v-col>
                  <v-col cols="12" sm="12">
                    <equipments-table
                      v-model="model.equipments"
                    ></equipments-table>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-col cols="12" sm="12">
                      <v-checkbox
                        v-model="model.keepVisiting"
                        label="¿Seguir visitando?"
                        :readonly="readMode"
                        outlined
                        validate-on-blur
                        dense
                        hide-details="auto"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="12" sm="12">
                      <v-checkbox
                        v-model="model.hasWifi"
                        label="¿El local cuenta con wifi?"
                        :readonly="readMode"
                        outlined
                        validate-on-blur
                        dense
                        hide-details="auto"
                      ></v-checkbox>
                    </v-col>

                    <v-col cols="12" sm="12">
                      <v-checkbox
                        v-model="model.recharges"
                        label="¿Realizan recargas de saldo?"
                        :readonly="readMode"
                        outlined
                        validate-on-blur
                        dense
                        hide-details="auto"
                      ></v-checkbox>
                    </v-col>

                    <v-col cols="12" sm="12">
                      <v-checkbox
                        v-model="model.purchaseFinancedBalance"
                        label="¿Realizan compra de saldo financiado?"
                        :readonly="readMode"
                        outlined
                        validate-on-blur
                        dense
                        hide-details="auto"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="12" sm="12">
                      <v-checkbox
                        v-model="model.computer"
                        label="¿PC / Notebook / Tablet?"
                        :readonly="readMode"
                        outlined
                        validate-on-blur
                        dense
                        hide-details="auto"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="12" sm="12">
                      <v-checkbox
                        v-model="model.posTC"
                        label="¿El local cuenta con POS?"
                        :readonly="readMode"
                        outlined
                        validate-on-blur
                        dense
                        hide-details="auto"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="12" sm="12">
                      <v-checkbox
                        v-model="model.smartDevices"
                        label="¿El local cuenta con teléfono inteligente?"
                        :readonly="readMode"
                        outlined
                        validate-on-blur
                        dense
                        hide-details="auto"
                      ></v-checkbox>
                    </v-col>
                  </v-col>
                </v-row>
              </base-section-card>
            </v-form>
          </v-stepper-content>
          <v-stepper-content step="2">
            <file-uploader
              v-if="model.id"
              :endpoint="filesEndpoint"
              title="Documentos"
            ></file-uploader>
            <v-card-actions class="mt-4">
              <v-btn color="primary" @click="$router.push(parentPath)">
                Finalizar
              </v-btn>
            </v-card-actions>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </base-form-card>
  </div>
</template>

<script>
import formMixin from "../mixins/formMixin";
import FileUploader from "../components/app/FileUploader";
import EquipmentsTable from "../components/app/EquipmentsTable";

export default {
  name: "PdvRegisterForm",
  components: { EquipmentsTable, FileUploader },
  mixins: [formMixin],
  data() {
    return {
      endpoint: "pointOfSale",
      step: 1,
      userRegistration: {
        roleId: 4,
      },
    };
  },
  watch: {
    "model.ownerName"(newValue) {
      if (newValue) {
        this.userRegistration.firstName = newValue;
      }
    },
    "model.lastName"(newValue) {
      if (newValue) {
        this.userRegistration.lastName = newValue;
      }
    },
    "model.email"(newValue) {
      if (newValue) {
        this.userRegistration.email = newValue;
      }
    },
    "model.phoneNumber"(newValue) {
      if (newValue) {
        this.userRegistration.phoneNumber = newValue;
      }
    },
  },
  computed: {
    filesEndpoint() {
      if (this.model && this.model.id)
        return `pointOfSale/${this.model.id}/documents`;
      else return undefined;
    },
    showFieldVC() {
      var show = !(this.userHasRole("VC") && !this.id);
      return show;
    },
  },
  mounted() {
    this.model.keepVisiting = true;
  },
  methods: {
    registerPdVUser() {
      if (this.$refs.form.validate()) {
        if (this.model.username != "")
          this.userRegistration.username = this.model.username;

        this.loading = true;
        this.error = false;
        this.$http
          .post("users", this.userRegistration)
          .then((resp) => {
            this.model.userId = resp.data.id;
            this.savePointOfSale();
          })
          .catch((error) => {
            window.console.error(error);

            if (!error.response) {
              this.error = true;
              this.$toast.error("Por favor revisa tu conexión");
              return;
            }

            const { status } = error.response;
            if (status === 400) {
              this.modelErrors = error.response.data.modelErrors;
              this.$toast.error("El formulario contiene errores");
            }
            this.loading = false;
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    savePointOfSale() {
      this.loading = true;
      this.error = false;
      this.$http
        .post("pointOfSale", this.model)
        .then((resp) => {
          this.model.id = resp.data.id;
          this.readMode = true;
          this.step = 2;
        })
        .catch((error) => {
          window.console.error(error);

          if (!error.response) {
            this.error = true;
            this.$toast.error("Por favor revisa tu conexión");
            return;
          }

          const { status } = error.response;
          if (status === 400) {
            this.modelErrors = error.response.data.modelErrors;
            this.$toast.error("El formulario contiene errores");
          }
          this.loading = false;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    /*afterSave(resp) {
      if (resp.id) {
        this.model.id = resp.id;
        this.readMode = true;
        this.step = 2;
      }
    },*/
  },
};
</script>

<style scoped>
</style>