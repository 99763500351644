<template>
  <div>
    <base-pagination-table
      :filter.sync="filters.filter"
      :headers="headers"
      :items="items"
      :options.sync="options"
      :server-items-length="totalItems"
      :loading="loading"
      :error="error"
      :items-per-page="itemsPerPage"
      :downloading="downloading"
      :filter-count="filterCount"
      :exportable="isExportable"
      @export="download($event)"
      @refresh="getItems()"
      @update:filter="timeOut()"
      @rowClick="onRowClick($event)"
      @clear="clear()"
    >
    </base-pagination-table>
    <!--    <v-btn-->
    <!--        v-if="!error && !loading"-->
    <!--        fab-->
    <!--        bottom-->
    <!--        fixed-->
    <!--        right-->
    <!--        dark-->
    <!--        color="primary"-->
    <!--        title="Nuevo"-->
    <!--        @click="$router.push({ path: 'create', append: true })"-->
    <!--    >-->
    <!--      <v-icon dark>mdi-plus</v-icon>-->
    <!--    </v-btn>-->
  </div>
</template>

<script>
import listMixin from "../mixins/listMixin";
import authMixin from "../mixins/authMixin";

export default {
  name: "CreditRequestForm",
  mixins: [listMixin, authMixin],
  data() {
    return {
      title: "Solicitudes de Créditos",
      endpoint: "creditRequest",
      headers: [
        { text: "#", value: "id" },
        { text: "Tipo", value: "creditType" },
        { text: "Nombre del PDV", value: "localName" },
        { text: "Estado", value: "creditStatus" },
      ],
    };
  },
  computed: {
    isExportable() {
      var CanExport = this.userHasRole("Administrador") || this.userHasRole("Gestor_Administrativo");
      console.log("export " + CanExport);
      return CanExport;
    },
  },
  mounted() {
    if (this.userHasRole("VC"))
      this.endpoint += "?&vcId=" + this.getUserData().nameid;

    if (this.userHasRole("PDV"))
      this.endpoint += "?&pdvUserId=" + this.getUserData().nameid;

    if (this.userHasRole("Institucion_Financiera"))
      this.endpoint += "?&financialId=" + this.getUserData().nameid;
  },
};
</script>

<style scoped>
</style>