<template>
  <div>
    <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      max-width="290px"
      min-width="290px"
    >
      <template v-slot:activator="{ on }">
        <v-text-field
          v-model="formatedDate"
          v-bind="$attrs"
          prepend-inner-icon="mdi-calendar"
          @blur="date = parseDate(formatedDate)"
          :rules="rules ? rules : []"
          v-on="!$attrs.readonly ? on : false"
          outlined
          dense
          autocomplete="off"
          readonly
          hide-details="auto"
        ></v-text-field>
      </template>
      <v-date-picker v-model="date" @input="menu = false" :type="type"></v-date-picker>
    </v-menu>
  </div>
</template>
<script>
  export default {
    props: {
      value: {
        type: String,
        default: ''
      },
      rules: {
        type: Array,
      },
      type: {
        type: String,
        default: 'date'
      },
    },
    data: () => ({
      date: null,
      formatedDate: null,
      menu: false
    }),
    mounted() {
      // cuando el componente se monta con un valor inical no se ejecuta el watch
      // por eso es necesario setear manualmente el date
      if (this.value) {
        if (this.type === "month")
          this.date = new Date(this.value).toISOString().substr(0, 7);
        else
          this.date = new Date(this.value).toISOString().substr(0, 10);
      }
    },
    watch: {
      date() {
        this.formatedDate = this.formatDate(this.date);
        this.$emit('input', this.parseDate(this.formatedDate));
      },
      value() {
        if (this.value) {
          if (this.type === "month")
            this.date = new Date(this.value).toISOString().substr(0, 7);
          else
            this.date = new Date(this.value).toISOString().substr(0, 10);
        } else {
          this.date = null;
          this.formatedDate = null;
        }
      }
    },

    methods: {
      formatDate(date) {
        if (!date) return null;
        if (this.type === "month") {
          const [year, month] = date.split('-');
          return `${month}/${year}`;
        }
        const [year, month, day] = date.split('-');
        return `${day}/${month}/${year}`;
      },
      parseDate(date) {
        if (!date) return null;
        if (this.type === "month") {
          const [month, year] = date.split('/');
          return `${year}-${month.padStart(2, '0')}`;
        }
        const [day, month, year] = date.split('/');
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
      }
    }
  };
</script>
