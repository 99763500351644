<template>
  <base-pagination-table
    :filter.sync="filters.filter"
    :headers="headers"
    :items="items"
    :options.sync="options"
    :server-items-length="totalItems"
    :loading="loading"
    :error="error"
    :items-per-page="itemsPerPage"
    :downloading="downloading"
    :filter-count="filterCount"
    :exportable="isExportable"
    @export="download($event)"
    @refresh="getItems()"
    @update:filter="timeOut()"
    @rowClick="onRowClick($event)"
    @clear="clear()"
  >
    <template v-slot:item.amountCharged="{ item }">
      {{ toCurrency(item.amountCharged) }}
    </template>
  </base-pagination-table>
</template>

<script>
import listMixin from "../mixins/listMixin";
import formatters from "../mixins/formatters";
import authMixin from "../mixins/authMixin";

export default {
  name: "QuotaPaymentList",
  mixins: [listMixin, formatters, authMixin],
  data() {
    return {
      title: "Cobranzas Realizadas",
      endpoint: "QuotaCollection",
      headers: [
        { text: "#", value: "id" },
        { text: "ID del Crédito", value: "creditRequestId" },
        { text: "PDV", value: "name" },
        { text: "Fecha", value: "dateOfVisit" },
        { text: "Monto", value: "amountCharged" },
      ],
    };
  },
  computed: {
    isExportable() {
        var CanExport = this.userHasRole("Administrador") || this.userHasRole("Gestor_Administrativo");
        console.log("export " + CanExport);
        return CanExport;
    },
  },
  mounted() {
    if (this.userHasRole("VC")) {
      console.log("vc");
      this.endpoint += "?&vcId=" + this.getUserData().nameid;
    }
  },
};
</script>

<style scoped>
</style>