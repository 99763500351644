<template>
    <div>
      <base-pagination-table
        :filter.sync="filters.filter"
        :headers="headers"
        :items="items"
        :options.sync="options"
        :server-items-length="totalItems"
        :loading="loading"
        :error="error"
        :items-per-page="itemsPerPage"
        :downloading="downloading"
        :filter-count="filterCount"
        @refresh="getItems()"
        @update:filter="timeOut()"
        @rowClick="onRowClick($event)"
        @clear="clear()"
      >
      </base-pagination-table>
      <v-btn
        v-if="!error && !loading"
        fab
        bottom
        fixed
        right
        dark
        color="primary"
        title="Nuevo"
        @click="$router.push({ path: 'create', append: true })"
      >
        <v-icon dark>mdi-plus</v-icon>
      </v-btn>
    </div>
  </template>
  <script>
  import listMixin from "../mixins/listMixin";
  export default {
    mixins: [listMixin],
    data() {
      return {
        title: "Marcas de bebidas",
        endpoint: "DrinkBrands",
        headers: [
          { text: "#", value: "id" },
          { text: "Marca", value: "name" },
        ],
      };
    },
  };
  </script>
  
  <style lang="scss" scoped></style>
  