<template>
  <div>
    <base-form-card
      :loading="loading"
      :readMode="readMode"
      :editable="editable"
      :error="error"
      :alert="alert"
      :submit-btn-enabled="false"
      @onEdit="readMode = false"
      @onDelete="remove()"
      @onRefresh="getDataFromApi()"
    >
      <v-stepper v-model="step">
        <v-stepper-header>
          <v-stepper-step :complete="step > 1" step="1">
            Ingresar datos básicos
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :complete="step > 2" step="2">
            Adjuntar archivos
          </v-stepper-step>
          <v-btn color="primary" @click="save()" class="ma-4" v-if="step == 1">
            Siguiente
          </v-btn>
        </v-stepper-header>
        <v-stepper-items>
          <v-stepper-content step="1">
            <v-form
              ref="form"
              v-model="isValidForm"
              id="myForm"
              @submit.prevent="save()"
            >
              <base-section-card title="Datos Básicos" class="mb-4">
                <v-row>
                  <v-col cols="12" sm="6">
                    <base-text-field
                      label="Nombre"
                      v-model="model.firstName"
                      :rules="[rules.required]"
                      :readonly="readMode"
                      :error-messages="modelErrors['firstName']"
                    >
                    </base-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <base-text-field
                      label="Apellido"
                      v-model="model.lastName"
                      :rules="[rules.required]"
                      :readonly="readMode"
                      :error-messages="modelErrors['lastName']"
                    >
                    </base-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <base-text-field
                      label="Username"
                      v-model="model.username"
                      prepend-inner-icon="mdi-account"
                      :rules="[rules.required]"
                      :readonly="readMode"
                      :error-messages="modelErrors['username']"
                    >
                    </base-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <base-text-field
                      label="Email"
                      type="email"
                      v-model="model.email"
                      prepend-inner-icon="mdi-email"
                      :rules="[rules.required, rules.email]"
                      :readonly="readMode"
                      :error-messages="modelErrors['email']"
                    >
                    </base-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <base-text-field
                      label="Nro. Documento"
                      v-model="model.documentNumber"
                      prepend-inner-icon="mdi-card-account-details"
                      :readonly="readMode"
                    >
                    </base-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <base-date-field
                      label="Fecha de Nacimiento"
                      v-model="model.birthday"
                      hint="dd/mm/aaaa"
                      :readonly="readMode"
                    ></base-date-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <base-text-field
                      label="Teléfono"
                      v-model="model.phoneNumber"
                      prepend-inner-icon="mdi-cellphone"
                      :readonly="readMode"
                      :append-icon="validWhatsappNumber ? 'mdi-whatsapp' : ''"
                      @click:append="sendWhatsapp()"
                    ></base-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <base-select
                      label="Rol"
                      item-text="name"
                      item-value="id"
                      v-model="model.roleId"
                      :rules="[rules.required]"
                      :readonly="readMode"
                      endpoint="roles"
                    ></base-select>
                  </v-col>
                </v-row>
              </base-section-card>

              <base-section-card title="Dirección" class="mb-4">
                <v-row>
                  <v-col cols="12" sm="3">
                    <base-autocomplete
                      label="Departamento"
                      item-text="name"
                      item-value="id"
                      v-model="model.departmentId"
                      endpoint="departments"
                      :rules="[rules.required]"
                      :readonly="readMode"
                    ></base-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="3">
                    <base-autocomplete
                      label="Ciudad"
                      item-text="name"
                      item-value="id"
                      v-model="model.cityId"
                      :readonly="readMode"
                      :rules="[rules.required]"
                      :endpoint="citiesByDepartmentEndpoint"
                    ></base-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <base-text-field
                      label="Dirección"
                      v-model="model.address"
                      :readonly="readMode"
                    >
                    </base-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <base-text-field
                      label="Barrio"
                      v-model="model.neighborhood"
                      :readonly="readMode"
                    >
                    </base-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <base-text-field
                      label="Referencia"
                      v-model="model.addressReference"
                      :readonly="readMode"
                    >
                    </base-text-field>
                  </v-col>
                </v-row>
              </base-section-card>
            </v-form>
          </v-stepper-content>
          <v-stepper-content step="2">
            <file-uploader
              v-if="model.id"
              :endpoint="filesEndpoint"
            ></file-uploader>
            <v-card-actions class="mt-4">
              <v-btn color="primary" @click="$router.push(parentPath)">
                Finalizar
              </v-btn>
            </v-card-actions>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </base-form-card>
  </div>
</template>

<script>
import formMixin from "../mixins/formMixin";
import FileUploader from "../components/app/FileUploader";

export default {
  name: "UserForm",
  components: { FileUploader },
  mixins: [formMixin],
  data() {
    return {
      endpoint: "users",
      step: 1,
    };
  },
  methods: {
    afterSave(resp) {
      if (resp.id) {
        this.model.id = resp.id;
        this.readMode = true;
        this.step = 2;
      }
    },
  },
  watch: {
    username(newValue) {
      if (newValue) this.model.username = newValue;
    },
    "model.departmentId"(newValue, oldValue) {
      if (newValue && oldValue) this.model.cityId = null;
    },
  },
  computed: {
    username() {
      if (!this.id && this.model.firstName && this.model.lastName)
        return `${this.model.firstName.charAt(0)}${
          this.model.lastName
        }`.toLowerCase();
      return this.model.username;
    },
    citiesByDepartmentEndpoint() {
      if (this.model.departmentId)
        return `departments/${this.model.departmentId}/cities`;
      else return undefined;
    },
    filesEndpoint() {
      if (this.model && this.model.id)
        return `users/${this.model.id}/documents`;
      else return undefined;
    },
  },
};
</script>

<style>
</style>