import { DEFAULT_PAGE_SIZE } from "../constants";

export default {
  data() {
    return {
      items: [],
      headers: [],
      endpoint: null,
      timer: null,
      options: {},
      filters: {},
      oderBy: {},
      totalItems: 0,
      filterCount: 0,
      loading: false,
      downloading: false,
      error: false,
      itemsPerPage: DEFAULT_PAGE_SIZE,
    };
  },
  mounted() {
    const [filterState] = this.$store.state.items.filter(x => x.key == this.filtersState);
    if (filterState) this.filters = filterState.value;

    const [paginationState] = this.$store.state.items.filter(x => x.key == this.paginationState);
    if (paginationState) {
      this.options.page = paginationState.value.pageNumber;
      this.options.itemsPerPage = paginationState.value.pageSize;
    }
  },
  watch: {
    options: {
      handler(val) {
        const pagination = { pageNumber: val.page, pageSize: val.itemsPerPage };
        this.$store.commit("setItem", { key: this.paginationState, value: pagination });
        this.getItems();
      },
    },
    filters: {
      handler() {
        this.$store.commit("setItem", { key: this.filtersState, value: this.filters });
      },
      deep: true,
    },
  },
  methods: {
    getItems() {
      this.loading = true;
      this.error = false;
      this.items = [];
      let params = this.getParams();
      this.$http
        .get(this.endpoint, { params: params })
        .then((resp) => {
          if (resp.status === 200) {
            this.items = resp.data;
            this.totalItems = JSON.parse(resp.headers['x-pagination']).totalCount;
            this.countFilters();
          }
        })
        .catch((error) => {
          this.error = true;
          window.console.error(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    timeOut() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.getItems();
      }, 800); // delay luego de cada tipeo
    },
    getParams() {
      let { sortBy, sortDesc, page, itemsPerPage } = this.options;
      let params = new URLSearchParams();

      params.append("pageNumber", page);
      params.append("pageSize", itemsPerPage);

      if (sortBy.length > 0) {
        sortBy.forEach((prop, index) => {
          let type = sortDesc[index] ? "desc" : "asc";
          params.append(
            "orderBy",
            this.oderBy[prop]
              ? `${this.oderBy[prop]}:${type}`
              : `${prop}:${type}`
          );
        });
      }

      for (var prop in this.filters) {
        if (
          this.filters[prop] !== "" &&
          this.filters[prop] !== null &&
          this.filters[prop] !== undefined
        ) {
          if (Array.isArray(this.filters[prop])) {
            for (var val in this.filters[prop]) {
              params.append(prop, this.filters[prop][val]);
            }
          } else params.append(prop, this.filters[prop]);
        }
      }

      return params;
    },
    clear() {
      this.filters = {};
      this.getItems();
      this.options.page = 1;
      this.options.itemsPerPage = DEFAULT_PAGE_SIZE;
    },
    download(formato) {
      //console.log(this.endpoint);
      // request: GET api/endpoint/formato
      this.downloading = true;
      const params = this.getParams();

      if(this.endpoint.includes("?")){
        let key = this.endpoint.split('?')[1].split('=')[0].replace("&", "");
        let keyvalue = this.endpoint.split('=')[1];
        params.append(key, keyvalue);
        this.endpoint = this.endpoint.split('?')[0] + `/${formato.toLowerCase()}`;
      }
      else
        this.endpoint = `${this.endpoint}/${formato.toLowerCase()}`;

      this.$http
        .get(this.endpoint, {
          params: params,
          responseType: "blob",
        })
        .then((response) => {
          const blob = new Blob([response.data]);
          const fileURL = window.URL.createObjectURL(blob);
          const fileLink = document.createElement("a");
          let fileName = response.headers["content-disposition"]
            .split("filename=")[1]
            .split(";")[0]
            .split('"')
            .join("");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", fileName);
          document.body.appendChild(fileLink);
          fileLink.click();
        })
        .catch((error) => {
          window.console.error(error);
          this.$toast.error("No se pudo descargar el archivo");
        })
        .finally(() => {
          this.downloading = false;
        });
    },
    onRowClick(item) {
      this.$router.push({ path: item.id.toString(), append: true });
    },
    countFilters() {
      this.filterCount = 0;
      for (var prop in this.filters) {
        const isArray = Array.isArray(this.filters[prop]);
        if (
          (!isArray &&
            this.filters[prop] != "" &&
            this.filters[prop] != null &&
            this.filters[prop] != undefined) ||
          (isArray && this.filters[prop].length > 0)
        )
          if (isArray) this.filterCount += this.filters[prop].length;
          else this.filterCount++;
      }
    },
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly;
    },
    filtersState() {
      return `filters-${this.endpoint}`;
    },
    paginationState() {
      return `pagination-${this.endpoint}`;
    },
  },
};
