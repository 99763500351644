<template>
  <div>
    <v-combobox
      v-bind="$attrs"
      v-on="$listeners"
      dense
      outlined
      validate-on-blur
      hide-details="auto"
      :items="items"
      :loading="cargando"
      :clearable="!$attrs.readonly"
    ></v-combobox>
  </div>
</template>

<script>
export default {
  props: {
    endpoint: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      cargando: false,
      error: false,
      items: [],
    };
  },
  mounted() {
    if (this.endpoint) this.getItems();
  },
  methods: {
    getItems() {
      this.cargando = true;
      this.error = false;

      // traer los resultados en una sola pagina
      let params = new URLSearchParams();
      params.append("pageNumber", 1);
      params.append("pageSize", -1);

      this.$http
        .get(this.endpoint, {params: params})
        .then((resp) => {
          this.items = resp.data;
        })
        .catch((error) => {
          this.error = true;
          window.console.error(error);
          this.$emit("error");
        })
        .finally(() => {
          this.cargando = false;
        });
    },
  },
  watch: {
    endpoint(val) {
      if (val) this.getItems();
      else this.items = [];
    },
  },
};
</script>

<style lang="scss" scoped>
</style>