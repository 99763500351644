<template>
  <div>
    <base-section-card title="Importar Archivo">
      <template v-slot:actions>
        <v-file-input
          truncate-length="20"
          hide-input
          validate-on-blur
          hide-details="auto"
          v-model="file"
          @change="onFileChange()"
          prepend-icon="mdi-cloud-upload"
          accept=".csv"
        ></v-file-input>
        <v-btn outlined text color="secondary" @click="downloadTemplate">
          Descargar Plantilla
          <v-icon class="ml-2">mdi-download</v-icon>
        </v-btn>
      </template>
      <v-row>
        <v-col cols="12">
          <v-data-table
            disable-sort
            disable-pagination
            hide-default-footer
            :headers="headers"
            :items="items"
            :loading="loading"
          >
            <template v-slot:no-data>
              Seleccione el archivo csv a importar
            </template>
            <template v-slot:item.monto_cuota="{ item }">
              {{ toCurrency(item.monto_cuota) }}
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <v-card-actions v-if="items && items.length > 0">
        <v-btn color="primary" @click="dialog = true">Actualizar Moras</v-btn>
      </v-card-actions>
    </base-section-card>
    <base-dialog
      width="500"
      v-model="dialog"
      title="Actualización de moras"
      text="Estás seguro que deseas actualizar el monto de las siguientes cuotas?"
      actionText="Confirmar"
      @onCancel="dialog = false"
      @onConfirm="updateDebt()"
    ></base-dialog>
    <v-overlay :value="downloading">
      <v-dialog v-model="downloading" hide-overlay persistent width="300">
        <v-card color="primary" dark>
          <v-card-text class="pt-2">
            Descargando
            <v-progress-linear indeterminate color="white"></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-overlay>
  </div>
</template>

<script>
import formatters from "../mixins/formatters";

export default {
  name: "DebtUpdateForm",
  mixins: [formatters],
  data() {
    return {
      file: null,
      items: [],
      dialog: false,
      loading: false,
      downloading: false,
      headers: [
        { text: "ID Crédito IF", value: "id_credito_if" },
        { text: "Nro. Cuota", value: "nro_cuota" },
        { text: "Monto Cuota", value: "monto_cuota" },
      ],
    };
  },
  methods: {
    onFileChange() {
      this.items = [];
      this.loading = true;
      const formData = new FormData();
      formData.append("file", this.file);
      this.$http
        .post("ArrearsQuotas/csv", formData)
        .then((resp) => {
          // this.$toast.success('Carga exitosa');
          this.items = resp.data;
        })
        .catch((error) => {
          window.console.error(error);
          if (!error.response) {
            this.$toast.error("Por favor revisa tu conexión");
          } else {
            let errorMessage = "Formato de archivo inválido";
            if (error.response.status === 400 && error.response.data)
              errorMessage = error.response.data;
            this.$toast.error(errorMessage);
          }
        })
        .finally(() => {
          this.loading = false;
          this.file = null;
        });
    },
    updateDebt() {
      this.loading = true;
      this.dialog = false;
      this.$http
        .post("ArrearsQuotas", this.items)
        .then(() => {
          this.$toast.success("Actualización exitosa");
        })
        .catch((error) => {
          window.console.error(error);
          if (!error.response) {
            this.$toast.error("Por favor revisa tu conexión");
          } else {
            this.$toast.error("Ocurrió un error inesperado");
          }
        })
        .finally(() => {
          this.loading = false;
          this.items = [];
        });
    },
    downloadTemplate() {
      this.downloading = true;
      //const params = this.getParams();
      const fileLink = document.createElement("a");
      fileLink.href = "https://agefipulbics3.s3.sa-east-1.amazonaws.com/plantilla_mora_cuotas.csv";
      fileLink.setAttribute("download", "plantilla_mora_cuotas");
      document.body.appendChild(fileLink);
      fileLink.click();
      this.downloading = false;
      /*this.$http
          .get(`ArrearsQuotas`, {
            params: params,
            responseType: "blob",
          })
          .then((response) => {
            const blob = new Blob([response.data]);
            const fileURL = window.URL.createObjectURL(blob);
            const fileLink = document.createElement("a");
            let fileName = response.headers["content-disposition"]
                .split("filename=")[1]
                .split(";")[0]
                .split('"')
                .join("");

            fileLink.href = fileURL;
            fileLink.setAttribute("download", fileName);
            document.body.appendChild(fileLink);
            fileLink.click();
          })
          .catch((error) => {
            window.console.error(error);
            this.$toast.error("No se pudo descargar el archivo");
          })
          .finally(() => {
            this.downloading = false;
          });*/
    },
  },
};
</script>

<style scoped>
</style>