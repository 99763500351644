<template>
  <div>
    <v-dialog v-bind="$attrs" v-on="$listeners">
      <v-card :loading="loading">
        <div class="pa-4">
          <v-card-title>
            <v-icon v-if="!!icon" class="mr-2">{{ icon }}</v-icon>
            {{ title }}
          </v-card-title>
          <v-card-text>{{ text }}</v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="secondary" depressed @click="$emit('onConfirm')">
              {{ actionText }}
            </v-btn>
            <v-btn color="secondary" outlined text @click="$emit('onCancel')">
              Cancelar
            </v-btn>
          </v-card-actions>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  name: "base-dialog",
  props: ["title", "text", "icon", "actionText", "loading"],
};
</script>
