<template>
  <base-card
      outlined
      :loading="loading"
      :disabled="loading"
      max-width="900"
      class="mx-auto"
  >
    <v-card-title>Reestablecer contraseña</v-card-title>
    <v-container>
      <v-alert text v-if="showRequirements">
        Recuerda que tu contraseña debe contener al menos:
        <ul>
          <li>Seis caracteres.</li>
          <li>Una letra mayúscula.</li>
          <li>Un número.</li>
          <li>Un caracter especial. Ej: <code>.,#?!@$-</code></li>
        </ul>
      </v-alert>
      <v-alert v-if="alert.show" text :type="alert.type" class="mt-4">
        {{ alert.message }}
      </v-alert>
      <v-form
          @submit.prevent="submit"
          id="form"
          ref="form"
          v-model="validForm"
      >
        <v-row>
          <v-col cols="12">
            <base-text-field
                label="Contraseña"
                hint="Tu nueva contraseña"
                v-model="model.newPassword"
                :rules="[rules.required, rules.password.atLeastOneLowerCase, rules.password.atLestOneUpperCase,
                  rules.password.atLeastOneDigit, rules.password.atLeastOneSpecialChar, rules.minLength(6)]"
                prepend-inner-icon="mdi-lock"
                :append-icon="showPass1 ? 'mdi-eye-off' : 'mdi-eye'"
                @click:append="showPass1 = !showPass1"
                :type="showPass1 ? 'text' : 'password'"
            />
          </v-col>
          <v-col cols="12">
            <base-text-field
                label="Confirmar Contraseña"
                v-model="model.confirmPassword"
                :rules="[rules.required,() => model.newPassword === model.confirmPassword ||'No coincide con el password ingresado']"
                prepend-inner-icon="mdi-lock"
                :append-icon="showPass2 ? 'mdi-eye-off' : 'mdi-eye'"
                @click:append="showPass2 = !showPass2"
                :type="showPass2 ? 'text' : 'password'"
            />
          </v-col>
        </v-row>
      </v-form>
    </v-container>
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-btn class="primary" type="submit" form="form" block>
            Confirmar
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </base-card>
</template>

<script>
import rules from "../mixins/rules";

export default {
  mixins: [rules],
  data() {
    return {
      validForm: false,
      loading: false,
      showPass1: false,
      showPass2: false,
      showRequirements: true,
      email: null,
      model: {
        newPassword: null,
        confirmPassword: null,
        token: null,
      },
      alert: {
        show: false,
        type: null,
        message: null,
      },
    };
  },
  created() {
    // obtenemos el token de reseteo y el email del usuario
    this.model.token = this.$route.query.token;
    this.email = this.$route.query.email;
  },
  methods: {
    submit() {
      if (!this.$refs.form.validate()) return;
      this.loading = true;
      this.alert.show = false;
      this.$http
          .post(`account/${this.email}/reset-password`, this.model)
          .then((resp) => {
            // limpiamos el login anterior
            this.$store.dispatch("logout");
            this.$toast.success(`Contraseña reestablecida`);
            // guardamos el nuevo login
            this.$store.dispatch("login", resp.data);
            this.$router.push({name: "home"});
          })
          .catch((error) => {
            window.console.error(error);
            if (error.response) {
              const status = error.response.status;
              if (status == 400) {
                error.response.data.errors.forEach((errorMessage) => {
                  this.$toast.error(errorMessage);
                });
              } else {
                this.$toast.error("Ocurrió un error inesperado");
              }
            } else {
              this.$toast.error("Verifique su conexión a internet");
            }
          })
          .finally(() => (this.loading = false));
    },
  },
};
</script>

<style>
</style>
